import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockTools from './blockTools';
import {
    filterCollectionByParent,
    // filterCollectionByParentSorted,
    getId,
    getParentId,
    getValue
} from "../../../../../utils/data";
import AnnotatedObject from '../../../../AnnotatedObject/AnnotatedObject';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FilesImportDialog from "../../ImportDnDModal";
import { addUploadTask, createFileName, readImage, saveImageToIndexedDB } from "../../../../../utils/image";
import { addNewElement, updateElement, deleteElement } from "../../../../../actions/data";

class Block8 extends Component {
    constructor (props) {
        super(props);
        this.state = {
            annotationsLocked: false
        };
    }

    getImageObject = () => {
        const blockId = getId(this.props.block);
        let imageObj = this.props.images.find(image => {
            const parentId = getParentId(image);
            return parentId === blockId;
        });
        if (!imageObj) {
            imageObj = this.props.addNewElement('measurementimage', blockId, {});
        }
        return imageObj;
    };

    _importFile = file => {
        createFileName(file.name, getId(this.props.block))
            .then(filename => {
                readImage(file)
                    .then(data => {
                        addUploadTask({ filename, data });
                    });
                saveImageToIndexedDB(file, filename)
                    .then(() => {
                        const imageObj = this.getImageObject();
                        this.props.updateElement('measurementimage', getId(imageObj),
                            {
                                name: file.name.replace(/\.[^/.]+$/, ""),
                                filename
                            });
                    });
            });
    }

    render () {
        if (this.props.locked) {
            let image = this.getImageObject();
            image = image ? getValue(image, 'filename') : null;
            let annotatedImage;
            console.log(this.props.annotations);
            if (image) {
                annotatedImage = (
                    <AnnotatedObject
                        image={image}
                        locked={this.state.annotationsLocked}
                        addNewElement={this.props.addNewElement}
                        deleteElement={this.props.deleteElement}
                        updateElement={this.props.updateElement}
                        nodeId={getId(this.getImageObject())}
                        dataStructure={this.props.dataStructure}
                        annotationTypes={['area']}
                        nodeType={'measurementimage'}
                        annotations={filterCollectionByParent(this.props.annotations, this.getImageObject())}
                    />
                );
            } else {
                const importPhotoButton = (
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        <FilesImportDialog
                            importFilesCallback={this._importFile}
                            multiple={false}
                            buttonText={'Import Image'}
                        />
                    </div>
                );
                annotatedImage = (
                    <div>
                        <div>There is no image in this block</div>
                        {importPhotoButton}
                    </div>
                );
            }
            return (
                <div className={'block8'}>
                    <div style={{ flex: 1 }}>
                        {annotatedImage}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={'block8'}>
                    <BlockTools id={getId(this.props.block)}/>
                    <div>annotated image block</div>
                </div>
            );
        }
    }
}

Block8.propTypes = {
    block: PropTypes.shape().isRequired,
    locked: PropTypes.bool.isRequired,
    images: PropTypes.array.isRequired,
    addNewElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    dataStructure: PropTypes.array.isRequired,
    annotations: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    images: state.data.measurementimages,
    annotations: state.data.measurementannotations,
    dataStructure: state.data.dataStructure
});

const mapActionsToDispatch = dispatch => bindActionCreators({
    addNewElement,
    updateElement,
    deleteElement
}, dispatch);

export default connect(mapStateToProps, mapActionsToDispatch)(Block8);
