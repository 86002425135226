/* eslint-disable no-unused-expressions */
/**
 * @var {{session:Object}} global
 */
import {
    DATA_LOAD_REQUESTED,
    DATA_LOAD_SUCCESS,
    DATA_LOAD_FAILURE,
    DATA_OBJECTS_UPDATE,
    DATA_STRUCTURE_LOAD_REQUESTED,
    DATA_STRUCTURE_LOAD_SUCCESS,
    DATA_STRUCTURE_LOAD_FAILURE,
    DATA_PARSED,
    DATA_IMAGE_PROCESSED,
    DATA_CONSULTANTS_REQUEST,
    DATA_CONSULTANTS_SUCCESS,
    DATA_CONSULTANTS_FAILURE,
    LOAD_ANNOTATION_TYPES_REQUESTED,
    LOAD_ANNOTATION_TYPES_SUCCESS,
    LOAD_ANNOTATION_TYPES_FAILURE,
    EXPORT_PROJECT_TO_WORD_REQUESTED,
    EXPORT_PROJECT_TO_WORD_SUCCESS,
    EXPORT_PROJECT_TO_WORD_FAILURE,
    DATA_CSVIMPORTMAPS_SUCCESS,
    DATA_CSVIMPORTMAPS_FAILURE,
    DATA_CSVIMPORTMAPS_REQUEST,
    LOCAL_IDS_UPDATE_STARTED, LOCAL_IDS_UPDATE_ENDED
} from '../reducers/data';
import { loggedOut } from './auth';
import {
    getValue,
    copyObject,
    generateTempId,
    getFieldsByTypeName,
    getFieldMetaIdByFieldNameAndTypeName,
    getMetaKeyByName,
    getTypeNameByMetaKey,
    getId,
    getFieldsByMetaTypeId,
    getRealMetaType,
    getParentId,
    getObjectById,
    filterCollectionByParent,
    getMaxFieldValue,
    getParent,
    getSequence,
    filterCollectionByParentSorted, getMetaTypeName, clearLoadedEntitiesFromUnnecessaryNumbers, openDB, getChildren
} from '../utils/data';
import { DecodingHelper } from 'kontraktor-client';
import { LOGGED_OUT } from "../reducers/auth";
import { saveImageToIndexedDB } from "../utils/image";
import axios from 'axios';
import { ELEMENT_VIEW_SELECTED } from "../reducers/view";

export const numericFields = [/*'job_date'*/]; // TODO: list all fields whose values must be sent as numbers
export const visualAssets = ['plan', 'map', 'photo', 'drawing', "visual_asset"];

const annotationTypesLoadFailure = error => dispatch => {
    dispatch({
        type: LOAD_ANNOTATION_TYPES_FAILURE,
        payload: {
            error
        }
    });
};

export const loadAnnotationTypes = () => dispatch => {
    dispatch({ type: LOAD_ANNOTATION_TYPES_REQUESTED });
    return new Promise((resolve, reject) => {
        if (global.session) {
            try {
                global.session.ask('getAnnotations')
                    .then((data, err) => {
                        if (err) {
                            // server.tell('clientError', err);
                            dispatch(annotationTypesLoadFailure("" + err));
                            reject();
                        } else if (data === null) {
                            err = "server returned empty annotations types data";
                            // server.tell('clientError', err);
                            dispatch(annotationTypesLoadFailure("" + err));
                            reject();
                        } else {
                            console.log("loaded data annotation types");
                            dispatch({
                                type: LOAD_ANNOTATION_TYPES_SUCCESS,
                                payload: {
                                    data: clearLoadedEntitiesFromUnnecessaryNumbers(data)
                                }
                            });
                            resolve();
                        }
                    });
            } catch (e) {
                // server.tell('clientError', err);
                dispatch(annotationTypesLoadFailure('' + e));
                dispatch(loggedOut());
                reject();
            }
        } else {
            dispatch(annotationTypesLoadFailure('No session with backend'));
            dispatch(loggedOut());
            reject();
        }
    });
};

const dataLoadFailure = error => dispatch => {
    console.log("" + error);
    dispatch({
        type: DATA_LOAD_FAILURE,
        payload: {
            error
        }
    });
    dispatch({
        type: LOGGED_OUT
    });
};

const dataLoadSuccess = data => dispatch => {
    dispatch({
        type: DATA_LOAD_SUCCESS,
        payload: {
            data
        }
    });
};

const dataStructureLoadFailure = error => dispatch => {
    console.log("" + error);
    dispatch({
        type: DATA_STRUCTURE_LOAD_FAILURE,
        payload: {
            error
        }
    });
    dispatch({
        type: LOGGED_OUT
    });
};

const dataStructureLoadSuccess = dataStructure => dispatch => {
    dispatch({
        type: DATA_STRUCTURE_LOAD_SUCCESS,
        payload: {
            dataStructure
        }
    });
};

export const updateDataFromLocalCache = () => dispatch => new Promise((resolve, reject) => {
    openDB()
        .then(db => {
            const tx = db.transaction('api');
            const tasks = {};
            tx.objectStore('api').iterateCursor(cursor => {
                if (!cursor) return;
                tasks[cursor.key] = cursor.value;
                cursor.continue();
            });
            tx.complete.then(() => {
                const promises = [];
                Object.values(tasks).forEach(task => {
                    promises.push(dispatch(processTaskLocal(task.taskType, task.parameters)));
                });
                Promise.all(promises).then(() => {
                    resolve();
                })
                    .catch(() => {
                        reject();
                    });
            });
        });
});

const loadDataStructure = () => dispatch => {
    dispatch({ type: DATA_STRUCTURE_LOAD_REQUESTED });
    if (global.session) {
        try {
            global.session.ask('getFrontEndMetaData')
                .then((data, err) => {
                    if (err) {
                        // server.tell('clientError', err);
                        dispatch(dataStructureLoadFailure("" + err));
                    } else if (data === null) {
                        err = "server returned empty meta data";
                        dispatch(dataStructureLoadFailure("" + err));
                    } else {
                        console.log("loaded data meta structure");
                        dispatch(dataStructureLoadSuccess(data));
                    }
                });
        } catch (e) {
            dispatch(dataStructureLoadFailure('' + e));
            dispatch(loggedOut());
        }
    } else {
        dispatch(dataStructureLoadFailure('No session with backend'));
        dispatch(loggedOut());
    }
};

export const updateAPI = ({ typeName, primaryKey, updatedFields = {}, time }) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        // updating to api
        // 1.translate columns names into meta ids
        const realTypeName = getRealMetaType(typeName);
        const translatedUpdatedFields = {};
        Object.entries(updatedFields).forEach(field => {
            const key = getFieldMetaIdByFieldNameAndTypeName(field[0], realTypeName, getState().data.dataStructure);
            translatedUpdatedFields[key.toString()] = numericFields.includes(field[0]) ?
                Number(field[1]) : field[1].toString(); // both keys and values are strings
        });
        console.log(translatedUpdatedFields);
        // 2. send request to api
        /**
         * @type {{jmap: function(object)}}
         */
        const coder = new DecodingHelper();
        const metaTableKey = getMetaKeyByName(realTypeName, getState().data.dataStructure);
        if (global.session) {
            global.session.ask('updateEntity', Number(primaryKey),
                Number(metaTableKey), coder.jmap(translatedUpdatedFields), time)
                .then((okMsg, err4) => {
                    if (okMsg) console.log(okMsg);
                    if (err4) console.log(err4);
                    resolve();
                });
        } else {
            dispatch({
                type: 'API_UPDATE_FAILURE',
                payload: 'No active session with backend'
            });
            dispatch(loggedOut());
            reject();
        }
    });
};

export const createAPI = ({ typeName, parentId, values, time }) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const serverTypeName = getRealMetaType(typeName);
        const primaryKey = values.id ? values.id : generateTempId();

        let columns = [
            { id: generateTempId(), name: 'id', value: primaryKey }
        ];
        if (typeName !== 'inlineMeasurement' && typeName !== 'measurementannotation') {
            columns.push({ id: generateTempId(), name: 'name', value: `New ${typeName}` });
        } else if (typeName === 'measurementannotation') {
            columns.push({ id: generateTempId(), name: 'name', value: `New measurement area` });
        } else {
            columns.push({ id: generateTempId(), name: 'type', value: 'grid' });
        }
        if (typeName !== 'project' && typeName !== 'csvimportmap') {
            columns.push({ id: generateTempId(), name: 'parentId', value: parentId });
        } else if (typeName === 'project') {
            columns.push(
                { id: generateTempId(), name: 'archived', value: parentId },
                { id: generateTempId(), name: 'fetched', value: "true" }
            );
        }
        if (typeName === 'layout') {
            columns.push({ id: generateTempId(), name: 'type', value: values.type ? values.type : 0 });
        }
        if (serverTypeName === 'visual_asset') {
            // adding assetType to visual assets
            columns.push({ id: generateTempId(), name: 'assetType', value: typeName });
            columns.push({ id: generateTempId(), name: 'ignore_export', value: 'false' });
        }
        const typeColumns = getFieldsByTypeName(serverTypeName, getState().data.dataStructure);
        // filling all the fields for a product type
        typeColumns.filter(column => !['id', 'name', 'parentId', 'archived', 'fetched', 'assetType', 'ignore_export']
            .includes(column))
            // .filter(column => serverTypeName !== 'data' || (serverTypeName === 'data' && column !== 'type'))
            .forEach(column => columns.push({
                id: generateTempId(),
                name: column,
                value: ''
            }));
        // setting initial values from arguments
        columns = columns.map(column => {
            if (Object.keys(values).includes(column.name)) {
                column.value = values[column.name];
            }
            return column;
        });

        // update to api
        const translatedUpdatedFields = {};
        columns.forEach(field => {
            const key = getFieldMetaIdByFieldNameAndTypeName(field.name, serverTypeName, getState().data.dataStructure);
            if (key) {
                // adding columns only if they are in data model
                translatedUpdatedFields[key.toString()] = numericFields.includes(field.name) ?
                    Number(field.value) : field.value.toString();
            }
        });
        console.log(translatedUpdatedFields);
        // send request to api
        const coder = new DecodingHelper();
        const metaTableKey = getMetaKeyByName(serverTypeName, getState().data.dataStructure);

        if (global.session) {
            global.session.ask('createEntity', Number(metaTableKey), coder.jmap(translatedUpdatedFields), time)
                .then((changedKeys, err1) => {
                    if (changedKeys) {
                        console.log(changedKeys);
                        resolve(changedKeys);
                    }
                    // todo: update all tasks in queue with new ids
                    if (err1) {
                        console.log(err1);
                        reject(err1);
                    }
                });
        } else {
            const error = 'No active session with backend';
            dispatch({
                type: 'API_CREATE_FAILURE',
                payload: error
            });
            dispatch(loggedOut());
            reject(error);
        }
    });
};

export const deleteAPI = ({ metaTableKey, primaryKey, time }) => dispatch => new Promise((resolve, reject) => {
    if (global.session) {
        global.session.ask('deleteCascadeEntity', Number(primaryKey), Number(metaTableKey), Number(time))
            .then((message, error) => {
                if (message) {
                    console.log(message);
                    resolve(message);
                }
                // todo: should we delete recursively from redux? elements are anyway inaccessible
                if (error) {
                    console.log(error);
                    reject(error);
                }
            });
    } else {
        const error = 'No active session with backend';
        dispatch({
            type: 'API_DELETE_FAILURE',
            payload: error
        });
        dispatch(loggedOut());
        reject(error);
    }
});

const processTaskLocal = (taskType, parameters) => dispatch => {
    // this is a task processor that should receive tasks from the queue
    console.log('Processing task ' + taskType + ' with params ' + JSON.stringify(parameters));
    switch (taskType) {
        case 'update':
            return dispatch(updateElementLocal(parameters));
        case 'create':
            return dispatch(addNewElementLocal(parameters));
        case 'delete':
            return dispatch(_deleteElementLocal(parameters));
    }
};

const addAPITask = (taskType, parameters) => (/*dispatch*/) => {
    // dispatch(processAPITask(taskType, parameters)); // instead of this call there will be queueing the task
    openDB().then(db => {
        const tx = db.transaction('api', 'readwrite');
        // noinspection JSIgnoredPromiseFromCall
        tx.objectStore('api').put({ taskType, parameters });
        return tx.complete;
    }).then(() => {
        console.log('Added api task to the idb: ', { taskType, parameters });
        global.registration?.active?.postMessage({
            action: 'sync'
        });
    });
};

export const addNewElementLocal = ({ typeName, parentId, values = {} }) => (dispatch, getState) => {
    typeName = typeName[0].toLowerCase() + typeName.slice(1); // no first capital letter
    // substituting type name to visual_asset for further actions
    const serverTypeName = getRealMetaType(typeName);
    const dataType = visualAssets.includes(typeName) ? 'visualAsset' : typeName;
    const primaryKey = values.id ? values.id : generateTempId();
    const newElement = {
        entity: {
            metaTableKey: getMetaKeyByName(serverTypeName, getState().data.dataStructure),
            primaryKey,
            record: []
        }
    };
    const columns = [
        { id: generateTempId(), name: 'id', value: primaryKey }
    ];
    if (typeName !== 'inlineMeasurement' && typeName !== 'measurementannotation') {
        columns.push({ id: generateTempId(), name: 'name', value: `New ${typeName}` });
    } else if (typeName === 'measurementannotation') {
        columns.push({ id: generateTempId(), name: 'name', value: `New measurement area` });
    } else {
        columns.push({ id: generateTempId(), name: 'type', value: 'grid' });
    }
    if (typeName !== 'project' && typeName !== 'csvimportmap') {
        columns.push({ id: generateTempId(), name: 'parentId', value: parentId });
    } else if (typeName === 'project') {
        columns.push(
            { id: generateTempId(), name: 'archived', value: parentId },
            { id: generateTempId(), name: 'fetched', value: "true" }
        );
    }
    if (typeName === 'layout') {
        columns.push({ id: generateTempId(), name: 'type', value: values.type ? values.type : 0 });
    }
    if (serverTypeName === 'visual_asset') {
        // adding assetType to visual assets
        columns.push({ id: generateTempId(), name: 'assetType', value: typeName });
        columns.push({ id: generateTempId(), name: 'ignore_export', value: 'false' });
    }
    const typeColumns = getFieldsByTypeName(serverTypeName, getState().data.dataStructure);
    // filling all the fields for a product type
    typeColumns.filter(column => !['id', 'name', 'parentId', 'archived', 'fetched', 'assetType', 'ignore_export']
        .includes(column))
        // .filter(column => serverTypeName !== 'data' || (serverTypeName === 'data' && column !== 'type'))
        .forEach(column => columns.push({
            id: generateTempId(),
            name: column,
            value: ''
        }));
    // setting initial values from arguments
    columns.map(column => {
        if (Object.keys(values).includes(column.name)) {
            column.value = values[column.name];
        }
        return column;
    });
    newElement.entity.record.push(...columns);
    //update to redux
    dispatch({
        type: DATA_OBJECTS_UPDATE,
        payload: {
            type: `${dataType}s`,
            elements: getState().data[dataType + 's'].concat([newElement])
        }
    });
    return newElement;
};

export const _addNewElement = (typeName, parentId, values = {}) => dispatch => {
    if (!values.id) values.id = generateTempId();
    const id = dispatch(addNewElementLocal({ typeName, parentId, values }));
    dispatch(addAPITask('create', { typeName, parentId, values, time: Date.now() }));
    return id;
};

export const updateElementLocal = ({ typeName, primaryKey, updatedFields = {} }) => (dispatch, getState) => {
    typeName = visualAssets.includes(typeName) ? 'visualAsset' : typeName;
    typeName = typeName === 'observationimage' ? 'image' : typeName;
    const updatedObject = copyObject(getState().data[`${typeName}s`].find(el =>
        getId(el).toString() === primaryKey.toString()));
    if (updatedObject) {
        Object.entries(updatedFields).forEach(field => {
            updatedObject.entity.record = updatedObject.entity.record.map(objField => {
                if (objField.name === field[0]) {
                    // noinspection JSUndefinedPropertyAssignment
                    objField.value = field[1];
                }
                return objField;
            });
        });
        const elements = getState().data[`${typeName}s`].map(
            el => getId(el).toString() === primaryKey.toString() ? updatedObject : el
        );
        dispatch({
            type: DATA_OBJECTS_UPDATE,
            payload: {
                type: `${typeName}s`,
                elements
            }
        });
    }
    return updatedObject;
};

export const updateElement = (typeName, primaryKey, updatedFields = {}) => dispatch => {
    dispatch(updateElementLocal({ typeName, primaryKey, updatedFields }));
    // updating to api
    dispatch(addAPITask('update', { typeName, primaryKey, updatedFields, time: Date.now() }));
};

export const _deleteElementLocal = ({ typeName, primaryKey }) => (dispatch, getState) => {
    // update redux state
    const elements = getState().data[`${typeName}s`].filter(el =>
        el.entity.primaryKey.toString() !== primaryKey.toString());
    dispatch({
        type: DATA_OBJECTS_UPDATE,
        payload: {
            type: `${typeName}s`,
            elements
        }
    });
};

export const _deleteElement = (typeName, primaryKey) => dispatch => {
    //local update
    dispatch(_deleteElementLocal({ typeName, primaryKey }));
    // update to api
    dispatch(addAPITask(
        'delete',
        {
            primaryKey,
            typeName,
            time: Date.now()
        }
    ));
};

// location actions
export const getNextLocationNumber = parentId => (dispatch, getState) => {
    const state = getState();
    const parentVisualAsset = getObjectById(parentId);
    // const visualAssetType = getValue(parentVisualAsset, 'assetType');
    const parentSiteId = getParentId(parentVisualAsset);
    // const sites = state.data.projects.reduce((prev, cur) => prev.concat(filterCollectionByParent(state.data.sites, cur))
    //     , []);
    const projectSites = filterCollectionByParentSorted(
        state.data.sites, getParentId(getObjectById(parentSiteId)));
    const parentSiteIndex = projectSites.findIndex(site => getId(site) === parentSiteId); // todo: sort by no
    const sitesToParse = projectSites.slice(0, parentSiteIndex + 1).reverse();
    // 1 is added to parentSiteIndex above as slice doesn't include end
    let prevNumber = 0;
    sitesToParse.forEach(site => {
        if (!prevNumber) {
            const siteId = getId(site);
            const assets = filterCollectionByParentSorted(state.data.visualAssets, siteId);
            let assetsStopIndex = assets.length;
            if (siteId === parentSiteId) {
                assetsStopIndex = assets.findIndex(asset => getId(asset) === parentId);
            }
            const assetsToParse = assets.slice(0, assetsStopIndex + 1);
            assetsToParse.reverse().forEach(asset => {
                if (!prevNumber) {
                    const locations = filterCollectionByParentSorted(state.data.locations, getId(asset));
                    prevNumber = getMaxFieldValue(locations, 'sequence');
                }
            });
        }
    });
    return (prevNumber + 1).toString();
};

export const getNextImageNumber = parentObservationId => (dispatch, getState) => {
    const state = getState();
    const parentObservation = getObjectById(parentObservationId);
    const imagesInParentObservation = filterCollectionByParentSorted(state.data.images, parentObservationId);
    if (imagesInParentObservation.length) {
        return getMaxFieldValue(imagesInParentObservation, 'sequence') + 1;
    }
    // if current observation doesn't have images, we have to build an array of all observations before current
    const parentLocation = getParent(parentObservation);
    const parentLocationId = getId(parentLocation);
    const parentVisualAsset = getParent(parentLocation);
    const parentVisualAssetId = getId(parentVisualAsset);
    // const visualAssetType = getValue(parentVisualAsset, 'assetType');
    const parentSite = getParent(parentVisualAsset);
    const parentSiteId = getId(parentSite);
    const parentProject = getParent(parentSite);
    let prevNumber = 0;
    const projectSites = filterCollectionByParentSorted(
        state.data.sites, parentProject);
    const parentSiteIndex = projectSites.findIndex(site => getId(site) === parentSiteId);
    const sitesToParse = projectSites.slice(0, parentSiteIndex + 1).reverse();
    // 1 is added to parentSiteIndex above as slice doesn't include end
    sitesToParse.forEach(site => {
        if (!prevNumber) {
            const siteId = getId(site);
            const assets = filterCollectionByParent(state.data.visualAssets, siteId);
            let assetsStopIndex = assets.length;
            if (siteId === parentSiteId) {
                assetsStopIndex = assets.findIndex(asset => getId(asset) === parentVisualAssetId);
            }
            const assetsToParse = assets.slice(0, assetsStopIndex + 1).reverse();
            assetsToParse.forEach(asset => {
                if (!prevNumber) {
                    const locations = filterCollectionByParent(state.data.locations, getId(asset));
                    let locationsStopIndex = locations.length;
                    if (getId(asset) === parentVisualAssetId) {
                        locationsStopIndex = locations.findIndex(loc => getId(loc) === parentLocationId);
                    }
                    const locationsToParse = locations.slice(0, locationsStopIndex + 1);
                    locationsToParse.reverse().forEach(loc => {
                        const observations = filterCollectionByParentSorted(state.data.observations, loc);
                        let observationStopIndex = observations.length;
                        if (getId(loc) === parentLocationId) {
                            observationStopIndex = observations.findIndex(
                                // eslint-disable-next-line max-nested-callbacks
                                ob => getId(ob) === parentObservationId);
                        }
                        const observationsToParse = observations.slice(0, observationStopIndex);
                        // eslint-disable-next-line max-nested-callbacks
                        observationsToParse.reverse().forEach(obs => {
                            const images = filterCollectionByParentSorted(state.data.images, obs);
                            if (!prevNumber) prevNumber = getMaxFieldValue(images, 'sequence');
                        });
                    });
                    // prevNumber = getMaxFieldValue(locations, 'sequence');
                }
            });
        }
    });
    return prevNumber + 1;
};

const sortObjectsWithEqualSequence = (obj1, obj2, changedObjectId) => {
    if (getSequence(obj1) === getSequence(obj2)) {
        if (getId(obj1) === changedObjectId) {
            return -1;
        } else if (getId(obj2) === changedObjectId) {
            return 1;
        } else {
            return 0;
        }
    }
    return 0;
};

const rebuildSequence = sortedCollection => dispatch => {
    sortedCollection.forEach((obj, index) => {
        const oldSequence = getSequence(obj);
        const newSequence = (index + 1).toString();
        if (oldSequence !== newSequence) {
            dispatch(updateElement(getMetaTypeName(obj), getId(obj), { sequence: newSequence }));
        }
    });
};

export const renumberObjects = (collection, changedObjId = null) => dispatch => {
    if (changedObjId) {
        collection = collection.sort((obj1, obj2) => sortObjectsWithEqualSequence(obj1, obj2, changedObjId));
    }
    dispatch(rebuildSequence(collection));
};

export const renumberLocations = (parentProjectOrId, changedLocationId = null) => (dispatch, getState) => {
    const state = getState();
    const projectSites = filterCollectionByParentSorted(state.data.sites, parentProjectOrId);
    let locations = projectSites
        .reduce((prev, site) => {
            const visualAssets = filterCollectionByParentSorted(state.data.visualAssets, site);
            const locs = visualAssets.reduce(
                (prev, vi) => prev.concat(filterCollectionByParentSorted(state.data.locations, getId(vi))),
                []
            );
            return prev.concat(locs);
        }, []);
    dispatch(renumberObjects(locations, changedLocationId));
};

export const renumberObservationFilesOrInlineMeasurements = (parentProject, metaType, changedObjOrId = null) =>
    (dispatch, getState) => {
        const sites = filterCollectionByParentSorted(getState().data.sites, parentProject);
        const visualAssets = sites.reduce((viArray, site) =>
            viArray.concat(filterCollectionByParentSorted(getState().data.visualAssets, site)), []);
        const locations = visualAssets.reduce((locArray, vi) =>
            locArray.concat(filterCollectionByParentSorted(getState().data.locations, vi)), []);
        const observations = locations.reduce((obsArray, loc) =>
            obsArray.concat(filterCollectionByParentSorted(getState().data.observations, loc)), []);
        const measurements = locations.reduce((msArray, loc) =>
            msArray.concat(filterCollectionByParentSorted(getState().data.measurements, loc)), []);
        const obsAndMs = [...observations, ...measurements];
        const collection = obsAndMs.reduce((obsArray, obsOrMs) =>
            obsArray.concat(filterCollectionByParentSorted(getState().data[metaType + 's'], obsOrMs)), []);
        dispatch(renumberObjects(collection, changedObjOrId));
    };

export const renumberObservationsOrMeasurements = (parentLocation, metaType, changedObjId = null) =>
    (dispatch, getState) => {
        const collection = filterCollectionByParentSorted(getState().data[metaType + 's'], parentLocation);
        dispatch(renumberObjects(collection, changedObjId));
        // renumbering children observation files
        const parentProject = getParent(getParent(getParent(parentLocation)));
        dispatch(renumberObservationFilesOrInlineMeasurements(parentProject, 'image'));
    };

export const renumberSites = (parentProjectOrId, changedSiteId = null) => (dispatch, getState) => {
    const parentProjectSites = filterCollectionByParentSorted(
        getState().data.sites, parentProjectOrId);
    dispatch(renumberObjects(parentProjectSites, changedSiteId));
    // renumbering locations after insert
    dispatch(renumberLocations(parentProjectOrId));
    // renumbering observations images
    dispatch(renumberObservationFilesOrInlineMeasurements(parentProjectOrId, 'image'));
};

export const addSite = (parentId, props = {}) => (dispatch, getState) => {
    const projectSites = filterCollectionByParent(getState().data.sites, parentId);
    let maxSequence = getMaxFieldValue(projectSites, 'sequence');
    if (!maxSequence) maxSequence = 0;
    props.sequence = (maxSequence + 1).toString();
    const newSite = dispatch(_addNewElement('site', parentId, props));
    dispatch(renumberSites(parentId, getId(newSite)));
};

export const addObservationImage = (parentId, props = {}) => (dispatch/*, getState*/) => {
    const sequence = dispatch(getNextImageNumber(parentId)).toString();
    const newObservationImage = dispatch(_addNewElement('image', parentId,
        {
            ...props,
            name: `Observation file`,
            // name: file.name.replace(/\.[^/.]+$/, ""),
            sequence
        })
    );
    const parentProject = getParent(getParent(getParent(getParent(getParent(newObservationImage)))));
    dispatch(renumberObservationFilesOrInlineMeasurements(parentProject, 'image', getId(newObservationImage)));
    return newObservationImage;
};

export const addObservationOrMeasurement = (metaType, parentId, props = {}) => (dispatch, getState) => {
    const siblings = filterCollectionByParentSorted(getState().data[metaType + 's'], parentId);
    const maxSequence = getMaxFieldValue(siblings, 'sequence');
    props.sequence = maxSequence + 1;
    return dispatch(_addNewElement(metaType, parentId, props));
    // we don't call renumbering observations here as a new one is always the last in the current locations
    // and observations are sequenced within location only, not project wide
};

export const addLocation = (parentId, props = {}) => (dispatch/*, getState*/) => {
    props.sequence = dispatch(getNextLocationNumber(parentId));
    props.name = 'Location';
    const newLocation = dispatch(_addNewElement('location', parentId, props));
    dispatch(renumberLocations(getParent(getParent(getParent(newLocation)))));
    return newLocation;
};

export const addLayout = (parentId, props = {}) => (dispatch/*, getState*/) => {
    const { type } = props;
    switch (type) {
        case "0":
            return dispatch(_addNewElement('layout', parentId, props));
        case "1":
            return ["0", "1"].map(position => {
                props['position'] = position;
                return dispatch(_addNewElement('layout', parentId, { ...props }));
            });
    }
};

export const addBlock = (layoutId, type, sequence = 0) => (dispatch/*, getState*/) => {
    switch (type) {
        case 'Block 1':
            return [
                { type: "2", val: 'Name' },
                { type: "3", val: '' },
                { type: "4", val: 'Comment' },
                { type: "5", val: '' }
            ]
                .map(props => dispatch(_addNewElement('block', layoutId, {
                    sequence: (Number(sequence) + Number(props.type) - 2).toString(),
                    ...props
                })));
        case 'Block 2':
            return dispatch(_addNewElement('block', layoutId, {
                sequence,
                type: "6",
                val: ''
            }));
        case 'Block 3':
            return dispatch(_addNewElement('block', layoutId, {
                sequence,
                type: "7",
                val: ''
            }));

        case 'Block 4':
            const block = dispatch(_addNewElement('block', layoutId, {
                sequence,
                type: "8",
                val: ''
            }));
            dispatch(_addNewElement('measurementimage', getId(block)));
            return block;

        case 'Placeholder':
            return dispatch(_addNewElement('block', layoutId, {
                sequence,
                type: "9",
                val: ''
            }));
    }
};

export const addNewElement = (typeName, parentId, props) => (dispatch/*, getState*/) => {
    typeName = typeName[0].toLowerCase() + typeName.slice(1);
    switch (typeName) {
        case 'site':
            return dispatch(addSite(parentId, props));
        case 'location':
            return dispatch(addLocation(parentId, props));
        case 'image':
            return dispatch(addObservationImage(parentId, props));
        case 'observation':
            return dispatch(addObservationOrMeasurement('observation', parentId, props));
        case 'measurement':
            return dispatch(addObservationOrMeasurement('measurement', parentId, props));
        case 'layout':
            return dispatch(addLayout(parentId, props));
        case 'block':
            const { type, sequence } = props;
            return dispatch(addBlock(parentId, type, sequence));
        default:
            return dispatch(_addNewElement(typeName, parentId, props));
    }
};

export const deleteInlineMeasurement = id => (dispatch/*, getState*/) => {
    const inlineMeasurement = getObjectById(id);
    const parentProject = getParent(getParent(getParent(getParent(inlineMeasurement))));
    dispatch(_deleteElement('inlineMeasurement', id));
    dispatch(renumberObservationFilesOrInlineMeasurements(parentProject, 'inlineMeasurement'));
};

export const deleteObservation = id => (dispatch/*, getState*/) => {
    const parentLocation = getParent(getObjectById(id));
    dispatch(_deleteElement('observation', id));
    dispatch(renumberObservationsOrMeasurements(parentLocation, 'observation'));
};

export const deleteMeasurement = id => (dispatch/*, getState*/) => {
    const parentLocation = getParent(getObjectById(id));
    dispatch(_deleteElement('measurement', id));
    dispatch(renumberObservationsOrMeasurements(parentLocation, 'observation'));
};

export const deleteLocation = id => (dispatch/*, getState*/) => {
    const location = getObjectById(id);
    const parentProject = getParent(getParent(getParent(location)));
    dispatch(_deleteElement('location', id));
    dispatch(renumberLocations(parentProject));
};

export const deleteVisualAsset = (visualAssetId) => (dispatch/*, getState*/) => {
    const parentProject = getParent(getParent(getObjectById(visualAssetId)));
    dispatch(_deleteElement('visualAsset', visualAssetId));
    dispatch(renumberLocations(parentProject));
};

export const deleteSite = pk => (dispatch/*, getState*/) => {
    const parentProject = getParent(getObjectById(pk));
    dispatch(_deleteElement('site', pk));
    dispatch(renumberSites(parentProject));
};

export const deleteObservationImage = id => (dispatch/*, getState*/) => {
    const observationImage = getObjectById(id);
    dispatch(_deleteElement('image', id));
    const parentProject = getParent(getParent(getParent(getParent(getParent(observationImage)))));
    dispatch(renumberObservationFilesOrInlineMeasurements(parentProject, 'image'));
};

export const deleteElement = (metaType, pk) => (dispatch/*, getState*/) => {
    switch (metaType) {
        case 'location':
            dispatch(deleteLocation(pk));
            break;
        case 'map':
            dispatch(deleteVisualAsset(pk));
            break;
        case 'plan':
            dispatch(deleteVisualAsset(pk));
            break;
        case 'drawing':
            dispatch(deleteVisualAsset(pk));
            break;
        case 'photo':
            dispatch(deleteVisualAsset(pk));
            break;
        case 'observation':
            dispatch(deleteObservation(pk));
            break;
        case 'measurement':
            dispatch(deleteMeasurement(pk));
            break;
        case 'image':
            dispatch(deleteObservationImage(pk));
            break;
        case 'site':
            dispatch(deleteSite(pk));
            break;
        case 'inlineMeasurement':
            dispatch(deleteInlineMeasurement(pk));
            break;
        default:
            dispatch(_deleteElement(metaType, pk));
    }
};

export const fetchImage = fileName => (dispatch, getState) => new Promise((resolve/*, reject*/) => {
    const url = `//${getState().auth.host}:3001/${fileName}`;
    axios.get(url, {
        responseType: 'blob' /* this is very important in order to read the file later as base64 */
    })
        .then(result => saveImageToIndexedDB(result.data, fileName))
        .then(() => {
            console.log(`Loaded image ${fileName} from ${url}`);
            dispatch({
                type: DATA_IMAGE_PROCESSED,
                payload: {
                    fileName
                }
            });
            // parseAnnotations(image.children);
            resolve();
        })
        .catch(e => {
            console.log(`Can't download image ${fileName}`);
            console.log('' + e);
            // rej();
            // parseAnnotations(image.children);
            resolve();
        });
});

export const parseDataToState = downloadedData => async (dispatch, getState) => {
    const data = {
        projects: [],
        sites: [],
        // visual assets (plans, photos, drawings, maps) are children to sites;
        visualAssets: [],
        // plans: [],
        // photos: [],
        // drawings: [],
        // maps: [],
        // end of visual assets :)
        locations: [],
        observations: [],
        images: [],
        annotations: [],
        measurements: [],
        layouts: [],
        blocks: [],
        columnmetas: [],
        columndatas: [],
        measurementimages: [],
        measurementannotations: []
    };

    const addDefaultColumns = obj => {
        const columns = getFieldsByMetaTypeId(obj.entity.metaTableKey, getState().data.dataStructure);
        columns.forEach(column => {
            const existingColumn = obj.entity.record.find(record => record.name === column);
            if (!existingColumn) {
                obj.entity.record.push({ id: generateTempId(), name: column, value: '' });
            }
        });
    };
    const pushNoDuplicates = (type, obj) => {
        type = type[0].toLowerCase() + type.slice(1);
        // filter out duplicates to resolve server problem
        const checkExists = data[`${type}s`]
            .find(el => getId(el) === getId(obj));
        if (!checkExists) {
            data[`${type}s`].push(obj);
        }
    };

    const parseAnnotations = annotations => {
        annotations.forEach(annotation => {
            annotations = copyObject(annotation);
            addDefaultColumns(annotation);
            pushNoDuplicates('annotation', annotation);
        });
    };

    const parseImages = images => new Promise((resolve/*, reject*/) => {
        const promises = images.map(image => {
            image = copyObject(image);
            if (image.children) parseAnnotations(image.children);
            // Reflect.deleteProperty(measurementOrObservation, 'children');
            addDefaultColumns(image);
            // todo: save content to localStorage, remove field content not to be saved to redux
            pushNoDuplicates('image', image);
            return new Promise((res/*, rej*/) => {
                const fileName = getValue(image, 'filename');
                openDB()
                    .then(db => {
                        const uploadRequestsFilenames = [];
                        const tx = db.transaction('images_upload');
                        tx.objectStore('images_upload').iterateCursor(cursor => {
                            if (!cursor) return;
                            uploadRequestsFilenames.push(cursor.value.filename);
                            cursor.continue();
                        });
                        tx.complete.then(() => {
                            const idx = uploadRequestsFilenames.indexOf(fileName);
                            if (idx < 0) {
                                dispatch(fetchImage(fileName))
                                    .then(() => {
                                        parseAnnotations(image.children);
                                        res();
                                    })
                                    .catch(e => {
                                        console.error(`Couldn't download image for image 
                                        object with pk ${getId(image)}`, '' + e);
                                        parseAnnotations(image.children);
                                        res();
                                    });
                            } else {
                                res();
                            }
                        });
                    });
            });
        });
        Promise.all(promises)
            .then(() => {
                console.log('All images processed');
                resolve();
            })
            .catch(err => {
                console.log(err);
                resolve();
            });
    });

    const parseColumnDatas = columndatas => {
        return new Promise(resolve => {
            columndatas.forEach(columndata => {
                pushNoDuplicates('columndata', columndata);
                columndata.children && parseColumnDatas(columndata.children);
            });
            resolve();
        });
    };

    const parseColumnMetas = columnmeta => {
        return new Promise(resolve => {
            pushNoDuplicates('columnmeta', columnmeta);
            columnmeta.children && parseColumnDatas(columnmeta.children);
            resolve();
        });
    };

    const parseMeasurementAnnotations = measurementAnnotations => {
        return new Promise(resolve => {
            measurementAnnotations.forEach(annotations => {
                pushNoDuplicates('measurementannotation', annotations);
            });
            resolve();
        });
    };

    const parseMeasurementImage = measurementImage => {
        return new Promise(resolve => {
            pushNoDuplicates('measurementimage', measurementImage);
            measurementImage.children && parseMeasurementAnnotations(measurementImage.children);
            resolve();
        });
    };

    const parseBlockChildren = children => {
        children.forEach(child => {
            if (getTypeNameByMetaKey(child.entity.metaTableKey, getState().data.dataStructure) === 'columnmeta') {
                return parseColumnMetas(child);
            } else {
                return parseMeasurementImage(child);
            }
        });
    };

    const parseBlocks = blocks => {
        return new Promise(resolve => {
            blocks.forEach(block => {
                pushNoDuplicates('block', block);
                if (block.children) {
                    return parseBlockChildren(block.children);
                }
            });
            resolve();
        });
    };

    const parseLayout = layouts => {
        return new Promise(resolve => {
            layouts.forEach(layout => {
                pushNoDuplicates('layout', layout);
                // eslint-disable-next-line no-unused-expressions
                layout.children && parseBlocks(layout.children);
            });
            resolve();
        });
    };

    const parseMeasurementsAndObservations = measurementsAndObservations => new Promise((resolve/*, reject*/) => {
        const promises = measurementsAndObservations.map(measurementOrObservation => {
            measurementOrObservation = copyObject(measurementOrObservation);
            const type = getTypeNameByMetaKey(
                measurementOrObservation.entity.metaTableKey,
                getState().data.dataStructure
            );
            addDefaultColumns(measurementOrObservation);
            pushNoDuplicates(type, measurementOrObservation);
            if (measurementOrObservation.children) {
                if (measurementOrObservation.entity.metaTableKey ===
                    getMetaKeyByName('observation', getState().data.dataStructure)) {
                    // parsing images for observations
                    return parseImages(measurementOrObservation.children.filter(child =>
                        child.entity.metaTableKey === getMetaKeyByName('image', getState().data.dataStructure)));
                }
                if (measurementOrObservation.entity.metaTableKey ===
                    getMetaKeyByName('measurement', getState().data.dataStructure)) {
                    // parsing inline measurements for measurements
                    return parseLayout(measurementOrObservation.children);
                }
                // Reflect.deleteProperty(measurementOrObservation, 'children');
            } else {
                return true;
            }
        });
        Promise.all(promises)
            .then(() => {
                resolve();
            })
            .catch(err => {
                console.log(err);
                resolve();
            });
    });

    const parseLocations = (locations) => new Promise((resolve/*, reject*/) => {
        const locPromises = locations.map(location => {
            if (location) {
                location = copyObject(location);
                // Reflect.deleteProperty(location, 'children');
                addDefaultColumns(location);
                data['locations'].push(location);
                if (location.children) {
                    return parseMeasurementsAndObservations(location.children);
                } else {
                    return true;
                }
            } else {
                return true;
            }
        });
        Promise.all(locPromises)
            .then(() => {
                console.log('Locations parsed');
                resolve();
            })
            .catch((err) => resolve(err));
    });

    const parseVisualAssets = assets => {
        return new Promise((resolve/*, reject*/) => {
            const assetsPromises = assets.map(asset => {
                asset = copyObject(asset);
                // Reflect.deleteProperty(asset, 'children');
                addDefaultColumns(asset);
                data.visualAssets.push(asset);
                // data[getValue(asset, 'assetType').toLowerCase() + 's'].push(asset);
                // downloading image by filename to indexedDb
                const fileName = getValue(asset, 'filename');
                if (fileName) {
                    return new Promise((res/*, rej*/) => {
                        // const url = `http://${getState().auth.host}:3001/${fileName}`;
                        // return axios.get(url, {
                        //     responseType: 'blob' /* this is very important in order to read the file later as base64 */
                        // })
                        openDB()
                            .then(db => {
                                const uploadRequestsFilenames = [];
                                const tx = db.transaction('images_upload');
                                tx.objectStore('images_upload').iterateCursor(cursor => {
                                    if (!cursor) return;
                                    uploadRequestsFilenames.push(cursor.value.filename);
                                    cursor.continue();
                                });
                                tx.complete.then(async () => {
                                    const idx = uploadRequestsFilenames.indexOf(fileName);
                                    if (idx < 0) {
                                        dispatch(fetchImage(fileName))
                                            .then(() => {
                                                if (asset.children && asset.children.length) {
                                                    parseLocations(asset.children)
                                                        .then(() => res());
                                                } else {
                                                    res();
                                                }
                                            })
                                            .catch(e => {
                                                console.log(`Can't download image ${fileName} for 
                                ${getValue(asset, 'assetType').toLowerCase()} with pk ${getId(asset)}`);
                                                console.log('' + e);
                                                // rej();
                                                if (asset.children && asset.children.length) {
                                                    parseLocations(asset.children)
                                                        .then(() => res());
                                                } else {
                                                    res();
                                                }
                                            });
                                    } else {
                                        res();
                                    }
                                });
                            })
                            .catch(e => {
                                console.error("Can't open db", e);
                                res();
                            });
                    });
                } else {
                    return parseLocations(asset.children)
                        .then(() => resolve());
                }
            });
            Promise.all(assetsPromises)
                .then(() => {
                    console.log('Assets parsed');
                    resolve();
                })
                .catch(err => resolve(err));
        });
    };

    const parseSites = sites => new Promise((resolve, reject) => {
        const promises = sites.map(site => {
            if (getMetaTypeName(site) === 'site') {
                // need to check the type due to backend bug returning project as a child of itself
                // site.parent = projectId;
                site = copyObject(site);
                addDefaultColumns(site);
                // Reflect.deleteProperty(site, 'children');
                data['sites'].push(site);
            }
            return parseVisualAssets(site.children);
        });
        Promise.all(promises)
            .then(() => resolve())
            .catch(err => reject(err));
    });

    return new Promise(resolve => {
        const projects = ['current', 'archived'].map(projectCategory => {
            return downloadedData[projectCategory].map(project => {
                return new Promise((resolve, reject) => {
                    project = copyObject(project);
                    if (project.children) {
                        parseSites(project.children)
                            .then(() => {
                                // Reflect.deleteProperty(copyObject(project), 'children');
                                addDefaultColumns(project);
                                resolve(project);
                            })
                            .catch(err => reject(err));
                    }
                });
            });
        });
        Promise.all(projects).then(async projectsPromises => {
            const projects = projectsPromises.reduce((projects, part) => projects.concat(part));
            await Promise.all(projects).then(async projects => {
                data['projects'] = projects;
                ['projects', 'sites', 'visualAssets', 'locations', 'observations',
                    'measurements', 'images', 'annotations', 'layouts', 'blocks', 'columnmetas', 'columndatas',
                    'measurementimages', 'measurementannotations']
                    .forEach(listName => {
                        dispatch({
                            type: DATA_OBJECTS_UPDATE,
                            payload: {
                                type: listName,
                                elements: data[listName]
                            }
                        });
                    });
                console.log('Data parsed to redux state');
                if (getState().auth.loggedInInThisSession) {
                    await dispatch(updateDataFromLocalCache());
                    // .then(() => {
                    //     resolve();
                    //     dispatch({ type: DATA_PARSED });
                    //     window.idsUpdateInterval = setInterval(() => {
                    //         dispatch(updateObjectKeys());
                    //     }, 10000);
                    //     if (global.registration) {
                    //         global.registration.active.postMessage({
                    //             action: 'sync'
                    //         });
                    //     }
                    // });
                }
                resolve();
                dispatch({ type: DATA_PARSED });
                window.idsUpdateInterval = setInterval(() => {
                    dispatch(updateObjectKeys());
                }, 10000);
                if (global.registration) {
                    global.registration.active.postMessage({
                        action: 'sync'
                    });
                }
            });
        });

        // if (!data.images.length) {
        //     dispatch(addNewElement('image', getId(data.photos[0])));
        // }
    });
};

const loadConsultantsSuccess = consultants => dispatch => {
    console.log('Consultants loaded');
    consultants = consultants.map(consultant => {
        const consultantSplit = consultant.split(",");
        const value = consultantSplit.pop();
        const name = consultantSplit.join(' ');
        return ({ value, name });
    });
    dispatch({
        type: DATA_CONSULTANTS_SUCCESS,
        payload: {
            consultants
        }
    });
};

const loadConsultantsFailure = error => dispatch => {
    console.log('Consultants loading failed');
    dispatch({
        type: DATA_CONSULTANTS_FAILURE,
        payload: {
            error
        }
    });
};

const loadConsultants = session => dispatch => {
    dispatch({ type: DATA_CONSULTANTS_REQUEST });
    return new Promise((resolve, reject) => {
        session.ask('getConsultants')
            .then((consultants, err) => {
                if (err) {
                    if (Array.isArray(err)) {
                        if (err._typ) err = 'Error loading consultants: ' + err._typ;
                    } else {
                        err = 'Error loading consultants';
                    }
                    dispatch(loadConsultantsFailure("" + err));
                    reject(err);
                } else if (consultants) {
                    dispatch(loadConsultantsSuccess(consultants));
                    resolve();
                } else {
                    dispatch(loadConsultantsSuccess([]));
                    console.log("Consultants array from server is empty.");
                    resolve();
                }
            });
    });
};

const loadCsvImportMapsSuccess = csvimportmaps => ({
    type: DATA_CSVIMPORTMAPS_SUCCESS,
    payload: {
        csvimportmaps
    }
});


const loadCsvImportMapsFailure = error => ({
    type: DATA_CSVIMPORTMAPS_FAILURE,
    payload: {
        error
    }
});

const loadCsvImportMaps = () => dispatch => {
    dispatch({ type: DATA_CSVIMPORTMAPS_REQUEST });
    return new Promise((resolve, reject) => {
        const session = global.session;
        session.ask('getCsvImportMaps')
            .then((csvImportMaps, err) => {
                console.info(csvImportMaps);
                if (err) {
                    if (Array.isArray(err)) {
                        if (err._typ) err = 'Error loading csv import maps: ' + err._typ;
                    } else {
                        err = 'Error loading csv import maps';
                    }
                    dispatch(loadCsvImportMapsFailure("" + err));
                    reject(err);
                } else if (csvImportMaps) {
                    dispatch(loadCsvImportMapsSuccess(clearLoadedEntitiesFromUnnecessaryNumbers(csvImportMaps)));
                    console.log("Csv import maps loaded.");
                    resolve();
                } else {
                    dispatch(loadCsvImportMapsSuccess([]));
                    console.log("Csv import maps array from server is empty.");
                    resolve();
                }
            });
    });
};

export const dataLoad = () => dispatch => {
    dispatch({ type: DATA_LOAD_REQUESTED });
    const session = global.session;
    return new Promise((resolve, reject) => {
        Promise.resolve(dispatch(loadDataStructure()))
            .then(() => {
                if (session) {
                    try {
                        session.ask('getInspectItData')
                            .then((data, err) => {
                                if (err) {
                                    if (Array.isArray(err)) {
                                        if (err._typ) err = 'Error loading data: ' + err._typ;
                                    } else {
                                        err = 'Error loading data';
                                    }
                                    dispatch(dataLoadFailure("" + err));
                                    reject("" + err);
                                } else if (data) {
                                    console.log("loaded data");
                                    dispatch(dataLoadSuccess(data));
                                    dispatch(loadConsultants(session))
                                        .then(() => {
                                            dispatch(loadAnnotationTypes());
                                            dispatch(loadCsvImportMaps());
                                            dispatch(parseDataToState(data)).then(() => {
                                                console.log('All data parsing actions finished. Data is fully loaded.');
                                                resolve();
                                            });
                                        })
                                        .catch(err => {
                                            console.log(err);
                                            dispatch(parseDataToState(data)).then(() => {
                                                console.log('All data parsing actions finished. Data is fully loaded.');
                                                resolve();
                                            });
                                        });
                                } else {
                                    dispatch(dataLoadFailure("Data object from server is empty."));
                                    reject();
                                }
                            });
                    } catch (e) {
                        if (e) dispatch(dataLoadFailure('Error loading data: ' + e));
                        dispatch(loggedOut());
                        reject();
                    }
                } else {
                    dispatch(dataLoadFailure('No session with backend'));
                    dispatch(loggedOut());
                    reject();
                }
            });
    });
};

export const moveSite = (siteId, sequence) => (dispatch/*, getState*/) => {
    dispatch(updateElement('site', siteId, { sequence }));
    // reloading site changes
    const site = getObjectById(siteId);
    dispatch(renumberSites(getParent(site), siteId));
};

export const moveLocation = (locationId, sequence) => (dispatch/*, getState*/) => {
    dispatch(updateElement(
        'location',
        locationId,
        {
            sequence
        }
    ));
    // renumbering location
    const location = getObjectById(locationId);
    dispatch(renumberLocations(getParent(getParent(getParent(location))), locationId));
};

export const moveObservationOrMeasurement = (id, sequence) => (dispatch/*, getState*/) => {
    let obj = getObjectById(id);
    obj = dispatch(updateElement(getMetaTypeName(obj), id, { sequence }));
    // renumber observations after insertion
    const parentLocation = getParent(obj);
    dispatch(renumberObservationsOrMeasurements(parentLocation, getMetaTypeName(obj), id));
};

export const moveImage = (id, sequence) => (dispatch/*, getState*/) => {
    let changedImage = getObjectById(id);
    changedImage = dispatch(updateElement(getMetaTypeName(changedImage), id, { sequence }));
    const parentProject = getParent(getParent(getParent(getParent(getParent(changedImage)))));
    dispatch(renumberObservationFilesOrInlineMeasurements(parentProject, 'image', id));
};

export const exportProjectToWord = projectId => (dispatch, getState) => {
    dispatch({ type: EXPORT_PROJECT_TO_WORD_REQUESTED });
    return new Promise((resolve, reject) => {
        if (global.session) {
            try {
                global.session.ask('exportToWord', Number(projectId))
                    .then((fileName, error) => {
                        if (error) {
                            dispatch({ type: EXPORT_PROJECT_TO_WORD_FAILURE, payload: { error } });
                            reject(error);
                        } else if (fileName === null) {
                            error = "server returned empty annotations types data";
                            dispatch({ type: EXPORT_PROJECT_TO_WORD_FAILURE, payload: { error } });
                            reject(error);
                        } else {
                            console.log("Successful export of project " + projectId + "to Word: " + fileName);
                            dispatch({
                                type: EXPORT_PROJECT_TO_WORD_SUCCESS
                            });
                            const host = getState().auth.host;
                            resolve(`//${host}:3001/docs/${fileName}`);
                        }
                    });
            } catch (e) {
                dispatch({ type: EXPORT_PROJECT_TO_WORD_FAILURE, payload: { e } });
                dispatch(loggedOut());
                reject(e);
            }
        } else {
            const error = 'No session with backend';
            dispatch({ type: EXPORT_PROJECT_TO_WORD_FAILURE, payload: { error } });
            dispatch(loggedOut());
            reject(error);
        }
    });
};


export const updateObjectKeys = () => (dispatch, getState) => {
    if (getState().data.runningIdsUpdate) {
        console.log('Not running ids update as previous call is still running.');
        return;
    }
    console.log('App is going to update local state with new ids.');
    openDB().then(db => {
        const tx = db.transaction('ids_update', "readwrite");
        const tasks = [];
        tx.objectStore('ids_update').iterateCursor(cursor => {
            if (cursor) {
                tasks.push(cursor.value);
                cursor.delete();
                // cursor.continue();
            }
        });
        tx.complete.then(async () => {
            if (!tasks || !tasks.length) {
                console.log('No ids changed, nothing to update');
            } else {
                dispatch({ type: LOCAL_IDS_UPDATE_STARTED });
            }
            tasks.forEach(task => {
                // if (['update', 'delete'].includes(task.taskType) && task.parameters.primaryKey === oldKey.toString()) {
                const { oldKey, newKey, typeName } = task;
                // ['projects', 'sites', 'plans', 'drawings', 'maps', 'photos', 'locations', 'observations', 'measurements',
                //     'images', 'annotations', 'inlineMeasurements',
                //     'inlineMeasurementsRows', 'inlineMeasurementRowColumns'].forEach(dataType => {
                //         getState().data[dataType].forEach(obj => {
                //
                //         });
                let updated = false;
                const elements = getState().data[typeName + 's'].map(obj => {
                    const currentId = getId(obj);
                    if (currentId === oldKey) {
                        updated = true;
                        // updating all the children with the new parentId while id isn't updated yet
                        const children = getChildren(obj);
                        children.forEach(child => {
                            child.entity.record = child.entity.record.map(rec => {
                                if (rec.name === 'parentId') rec.value = newKey.toString();
                                return rec;
                            });
                            const childType = getMetaTypeName(child);
                            const childId = getId(child);
                            // getting all the objs of the child type and substituting the updated one
                            const childElements = getState().data[childType + 's'].map(childObj => {
                                const currentChildId = getId(childObj);
                                return currentChildId === childId ? child : childObj;
                            });
                            dispatch({
                                type: DATA_OBJECTS_UPDATE, payload: {
                                    childType, childElements
                                }
                            });
                        });
                        obj.entity.primaryKey = newKey;
                        obj.entity.record = obj.entity.record.map(rec => {
                            if (rec.name === 'id') rec.value = newKey.toString();
                            return rec;
                        });
                    }
                    return obj;
                });
                if (updated) {
                    if (getState().view.elementType.toLowerCase() === typeName.toLowerCase() &&
                        getState().view.elementPrimaryKey === oldKey) {
                        dispatch({
                            type: ELEMENT_VIEW_SELECTED,
                            payload: {
                                elementType: typeName,
                                elementPrimaryKey: newKey
                            }
                        });
                    }
                    dispatch({
                        type: DATA_OBJECTS_UPDATE, payload: {
                            typeName, elements
                        }
                    });
                }
            });
            tasks && tasks.length && dispatch({ type: LOCAL_IDS_UPDATE_ENDED });
        });
    });
};
