import React from 'react';
import PropTypes from 'prop-types';
import { getValue } from "../../../../../utils/data";
import Block6 from './Block6';
import Block2345 from "./Block2_3_4_5";
import Block7 from './Block7';
import Block8 from './Block8';
import Block9 from './Block9';

const Block = ({ block, ...props }) => {
    const blockType = getValue(block, 'type');
    switch (blockType) {
        case '2':
            // field and description with labels
            return (
                <Block2345
                    block={block}
                    locked={props.locked}
                    allBlocksInLayout={props.allBlocksInLayout}
                    updateCallback={props.updateCallback}/>
            );
        case '6':
            // one field
            return <Block6 block={block} locked={props.locked} updateCallback={props.updateCallback}/>;
        case '7':
            // data table
            return <Block7 block={block} locked={props.locked} updateCallback={props.updateCallback}/>;
        case '8':
            // annotated object
            return <Block8 block={block} locked={props.locked} />;
        case '9':
            //placeholder
            return <Block9 block={block} locked={props.locked}/>;
        default:
            return !['3', '4', '5'].includes(blockType) ? (
                <div>Block of type {blockType}</div>
            ) : null;
    }
};

Block.propTypes = {
    block: PropTypes.shape().isRequired,
    locked: PropTypes.bool.isRequired,
    allBlocksInLayout: PropTypes.array.isRequired,
    updateCallback: PropTypes.func.isRequired
};


export default Block;
