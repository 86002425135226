import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from "react-dnd";
import { Paper, Path } from "react-raphael";

const MeasurementAnnotationLegendItem = ({ connectDragSource, style, label }) => {
    return connectDragSource(
        <div className={'nonSelectable'}>
            <div>{label}</div>
            <Paper width={100} height={30}>
                <Path
                    ref={el => {
                        if (el && style.fill) {
                            el.getElement().node.setAttribute('fill', style.fill);
                        }
                    }}
                    id={`li_${label}`}
                    d="M0,0L100,0L100,30L0,30Z"
                    attr={{
                        "stroke": style.stroke,
                        "stroke-width": style.strokeWidth,
                        "fill-opacity": style.fillOpacity
                    }}
                    label={'drag zone'}
                />
            </Paper>
            <div style={{
                position: 'relative',
                top: '-35px'
            }}>area</div>
        </div>
    );
};

MeasurementAnnotationLegendItem.propTypes = {
    annotationType: PropTypes.string,
    connectDragSource: PropTypes.func.isRequired,
    style: PropTypes.shape().isRequired
};
MeasurementAnnotationLegendItem.defaultProps = {
    annotationType: 'area'
};

// dnd props
// This will return the label of the item as a type
const getType = props => props.annotationType ? props.annotationType : 'area';

const dndSpecs = {
    // begin Drag sets the information transferred to the target
    beginDrag (props) {
        return { dragType: props.annotationType ? props.annotationType : 'area' };
    }
};

const collect = connect => ({
    connectDragSource: connect.dragSource()
    // connectDragPreview: connect.dragPreview()
});

// eslint-disable-next-line new-cap
export default DragSource(getType, dndSpecs, collect)(MeasurementAnnotationLegendItem);
