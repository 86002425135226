import { combineReducers } from 'redux';
import view from './view';
import auth from './auth';
import data from './data';
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['auth', 'data']
};

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['error', 'loggedInInThisSession']
};

const dataPersistConfig = {
    key: 'data',
    storage: storage,
    blacklist: ['error', 'runningIdsUpdate']
};


const rootReducer = persistReducer(rootPersistConfig, combineReducers({
    view,
    auth: persistReducer(authPersistConfig, auth),
    data: persistReducer(dataPersistConfig, data)
}));

export default rootReducer;
