import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { getId, getMaxFieldValue } from "../../../../../utils/data";
import Block from "../Blocks";
import AddTableBlockModalDialog from "./addTableBlockDialog";

class Column extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            addTableBlockModalOpen: false
        };
    }

    addTableBlockModalOpen = () => {
        this.setState({ addTableBlockModalOpen: true });
    };

    closeTableBlockModal = () => {
        this.setState({ addTableBlockModalOpen: false });
    };

    addTableBlock = (rows, columnsMeta) => {
        const sequence = getMaxFieldValue(this.props.blocks, 'sequence') + 1;
        const block = this.props.addNewElement('block', this.props.id, { type: 'Block 3', sequence });
        columnsMeta.forEach((column, i) => {
            const headerColValues = {
                header_text: column.title,
                type: column.type,
                col_no: i,
                width: column.width
            };
            if (column.type === 'DBL') {
                headerColValues.precision = column.precision;
                headerColValues.scale = column.scale;
            }
            const headerRowCol = this.props.addNewElement('columnmeta', getId(block), headerColValues);
            for (let r = 0; r < rows; r++) {
                let val;
                switch (column.type) {
                    case 'INT':
                        val = 0;
                        break;
                    case 'DBL':
                        val = '0'.repeat(column.precision - column.scale) + '.' +
                            '0'.repeat(column.scale);
                        break;
                    case 'LNG':
                        val = 0;
                        break;
                    case 'STR':
                        val = ``;
                        break;
                    default:
                        val = '';
                }
                this.props.addNewElement('columndata', getId(headerRowCol), { val });
            }
        });
    };

    render () {
        const style = this.props.isOver ? {
            backgroundColor: 'rgb(232, 245, 233)'
        } : {};
        return this.props.connectDropTarget(
            <div
                className={this.props.type === 'left' ? 'columnLeft' : 'columnRight'}
                style={style}
            >
                <AddTableBlockModalDialog
                    open={this.state.addTableBlockModalOpen}
                    onCloseCallback={this.closeTableBlockModal}
                    addCallback={this.addTableBlock}
                />
                {this.props.blocks && this.props.blocks.length ?
                    this.props.blocks.map(block => (
                        <Block
                            block={block}
                            locked={this.props.locked}
                            key={getId(block)}
                            allBlocksInLayout={this.props.blocks}
                            updateCallback={this.props.updateCallback}
                        />)) :
                    <div className={'dropHere'}>{this.props.locked ? 'No blocks' : 'Drop blocks here'}</div>}
            </div>
        );
    }
}

Column.propTypes = {
    updateCallback: PropTypes.func.isRequired,
    blocks: PropTypes.array.isRequired,
    locked: PropTypes.bool.isRequired,
    isOver: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    addNewElement: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['left', 'right']),
    id: PropTypes.string.isRequired
};

//below are the settings for dnd target
const targetSpecs = {
    drop (props, monitor, component) {
        const item = monitor.getItem();
        const sequence = getMaxFieldValue(props.blocks, 'sequence') + 1;
        if (item.dragType === 'Block 3') {
            component.addTableBlockModalOpen();
        } else {
            props.addNewElement('block', props.id, { type: item.dragType, sequence });
        }
        console.info('Dropped a ' + item.dragType + 'to layout id ' + props.id);
        return {};
    }
};

const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});

// eslint-disable-next-line new-cap
export default DropTarget(['Block 1', 'Block 2', 'Block 3', 'Block 4', 'Placeholder'], targetSpecs, collect)(Column);
