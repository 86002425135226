import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

const DnDBlock = props => props.connectDragSource(
    <div>
        {React.cloneElement(props.icon,
            {
                style: {
                    fontSize: 36
                }
            }
        )}
    </div>
);

DnDBlock.propTypes = {
    icon: PropTypes.shape().isRequired,
    dndType: PropTypes.string.isRequired,
    // dnd
    connectDragSource: PropTypes.func.isRequired
};

// dnd source
const dndSpecs = {
    // begin Drag sets the information transferred to the target
    beginDrag (props) {
        return {
            dragType: props.dndType
        };
    }
};

const collect = connect => ({ connectDragSource: connect.dragSource() });

// eslint-disable-next-line new-cap
export default DragSource(props => props.dndType, dndSpecs, collect)(DnDBlock);
