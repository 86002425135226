import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import './addTableBlockDialog.css';

class addTableBlockDialog extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            rows: 1,
            columns: 1,
            numbersDialogOpen: false,
            metaDialogOpen: false,
            columnsMeta: []
        };
        this.onColumnTitleEdit = this.onColumnTitleEdit.bind(this);
        this.onColumnTypeEdit = this.onColumnTypeEdit.bind(this);
        this.onColumnPrecisionEdit = this.onColumnPrecisionEdit.bind(this);
        this.onColumnScaleEdit = this.onColumnScaleEdit.bind(this);
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (nextProps.open !== this.props.open) {
            this.setState({ numbersDialogOpen: nextProps.open });
        }
        if (!nextProps.open && this.state.metaDialogOpen) {
            this.setState({ metaDialogOpen: false });
        }
    }

    onNumbersDialogClose = () => {
        const columnsMeta = [];
        for (let i = 0; i < this.state.columns; i++) {
            columnsMeta.push(
                {
                    title: '',
                    type: 'STR',
                    width: ''
                }
            );
        }
        this.setState({ numbersDialogOpen: false, metaDialogOpen: true, columnsMeta });
    };

    onClose = () => {
        this.setState({ rows: 1, columns: 1, columnsMeta: [] });
        this.props.onCloseCallback();
    };

    onAdd = () => {
        this.setState({ rows: 1, columns: 1 });
        this.props.onCloseCallback();
        this.props.addCallback(this.state.rows, this.state.columnsMeta);
    };

    onColumnTitleEdit = event => {
        const idx = Number(event.target.id.replace('columnTitle', ''));
        const column = this.state.columnsMeta[idx];
        column.title = event.target.value;
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    onColumnWidthEdit = event => {
        const idx = Number(event.target.id.replace('columnWidth', ''));
        const column = this.state.columnsMeta[idx];
        column.width = event.target.value;
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    onColumnTypeEdit = event => {
        const idx = Number(event.target.name.replace('columnType', ''));
        const column = this.state.columnsMeta[idx];
        const columnType = event.target.value;
        column.type = columnType;
        if (columnType === 'DBL') {
            column.precision = 7;
            column.scale = 2;
        } else {
            Reflect.deleteProperty(column, 'precision');
            Reflect.deleteProperty(column, 'scale');
        }
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    onColumnPrecisionEdit = event => {
        const idx = Number(event.target.id.replace('columnPrecision', ''));
        const column = this.state.columnsMeta[idx];
        column.precision = event.target.value;
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    onColumnScaleEdit = event => {
        const idx = Number(event.target.id.replace('columnScale', ''));
        const column = this.state.columnsMeta[idx];
        column.scale = event.target.value;
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    metasCanBeSaved = () => {
        let canBeSaved = true;
        this.state.columnsMeta.forEach(column => {
            if (canBeSaved) {
                if (!column.title || !column.type) canBeSaved = false;
            }
        });
        return canBeSaved;
    };

    render () {
        const numbersDialog = this.state.numbersDialogOpen ? (
            <Dialog
                open={this.state.numbersDialogOpen}
            >
                <DialogTitle>Add table block</DialogTitle>
                <DialogContent>
                    <div className={'addTableBlockModalRow'}>
                        <div>Rows</div>
                        <Input
                            onChange={event => {
                                this.setState({ rows: event.target.value });
                            }}
                            value={this.state.rows}
                            type={'number'}
                            fullWidth
                        />
                    </div>
                    <div className={'addTableBlockModalRow'}>
                        <div>Columns</div>
                        <Input
                            onChange={event => {
                                this.setState({
                                    columns: event.target.value
                                });
                            }}
                            value={this.state.columns}
                            type={'number'}
                            fullWidth
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.onNumbersDialogClose}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        ) : null;

        const cellTypes = [];

        if (this.state.metaDialogOpen) {
            for (let i = 0; i < this.state.columns; i++) {
                let doubleTypeFields = this.state.columnsMeta[i].type === 'DBL' ? (
                    <div>
                        <div>
                            <TextField
                                id={'columnPrecision' + i}
                                type={'number'}
                                onChange={this.onColumnPrecisionEdit}
                                value={this.state.columnsMeta[i] && this.state.columnsMeta[i].precision ?
                                    this.state.columnsMeta[i].precision : 0}
                                placeholder={'column precision'}
                                fullWidth
                                label={'Precision'}
                            />
                        </div>
                        <div>
                            <TextField
                                id={'columnScale' + i}
                                type={'number'}
                                onChange={this.onColumnScaleEdit}
                                value={this.state.columnsMeta[i] && this.state.columnsMeta[i].scale ?
                                    this.state.columnsMeta[i].scale : 0}
                                placeholder={'column scale'}
                                fullWidth
                                label={'Scale'}
                            />
                        </div>
                    </div>
                ) : null;

                cellTypes.push(
                    <div className={'columnMetaFields'} key={'columnMeta_' + i}>
                        <div style={{ margin: '5px 0' }}>Column {i + 1}</div>
                        <div>
                            <TextField
                                id={'columnTitle' + i}
                                onChange={this.onColumnTitleEdit}
                                value={this.state.columnsMeta[i] && this.state.columnsMeta[i].title ?
                                    this.state.columnsMeta[i].title : ''}
                                placeholder={'column title'}
                                fullWidth
                                label={'Field title'}
                            />
                        </div>
                        <div>
                            <TextField
                                id={'columnWidth' + i}
                                onChange={this.onColumnWidthEdit}
                                value={this.state.columnsMeta[i] && this.state.columnsMeta[i].width ?
                                    this.state.columnsMeta[i].width : ''}
                                placeholder={'column width'}
                                fullWidth
                                label={'Field width'}
                            />
                        </div>
                        <div>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel>Column type</InputLabel>
                                <Select
                                    value={this.state.columnsMeta[i] && this.state.columnsMeta[i].type ?
                                        this.state.columnsMeta[i].type : ''}
                                    onChange={this.onColumnTypeEdit}
                                    inputProps={{
                                        name: 'columnType' + i
                                    }}
                                >
                                    <MenuItem value={'STR'}>Text</MenuItem>
                                    <MenuItem value={'INT'}>Integer</MenuItem>
                                    <MenuItem value={'LNG'}>Long</MenuItem>
                                    <MenuItem value={'DBL'}>Double</MenuItem>
                                    <MenuItem value={'BOL'}>Boolean</MenuItem>
                                    <MenuItem value={'DAT'}>Date</MenuItem>
                                    <MenuItem value={'TIM'}>Time</MenuItem>
                                    <MenuItem value={'TMS'}>Timestamp</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {doubleTypeFields}
                    </div>
                );
            }
        }

        const metaDialog = this.state.metaDialogOpen ? (
            <Dialog
                open={this.state.metaDialogOpen}
            >
                <DialogTitle>Add table block</DialogTitle>
                <DialogContent>
                    {cellTypes}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.onClose}
                    >Cancel</Button>
                    <Button
                        onClick={this.onAdd}
                        disabled={!this.metasCanBeSaved()}
                    >Ok</Button>
                </DialogActions>
            </Dialog>
        ) : null;

        return (
            <div>
                {numbersDialog}
                {metaDialog}
            </div>
        );
    }
}

addTableBlockDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    addCallback: PropTypes.func.isRequired,
    onCloseCallback: PropTypes.func.isRequired
};

export default addTableBlockDialog;
