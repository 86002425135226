import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SnackBar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { connect } from "react-redux";
import FlexView from 'react-flexview';
import { bindActionCreators } from "redux";
import { KClient, KClientListener } from "kontraktor-client";
import { Fader, HCenter } from "./util";
import { hostUpdate, loggedOut, login, registerSW } from "./actions/auth";
import intervals from './composition/intervals';
import globalKontraktor from "./globalKontraktor";

class InspectItListener extends KClientListener {
    constructor (maincomp) {
        super();
        this.listener = new KClientListener();
        this.maincomp = maincomp;
    }

    // session timeout or resurrection fail
    onInvalidResponse (response) {
        console.error("Invalid response", response);
        this.maincomp.setState({ relogin: true }); // session expired
    }

    onResurrection () {
        console.log("Session resurrected. Should update client data + resubscribe streams in case");
        this.maincomp.setState({ snackText: "Session Resurrected !", snackOpen: true });
    }
}

class Login extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            usernameFieldValue: '',
            passwordFieldValue: '',
            snackText: '',
            relogin: true
        };
        const self = this;
        globalKontraktor.kclient.listener = new InspectItListener(self);
        this.listener = false;
        // this.height = 500;
    }

    componentWillMount () {
        this._parseErrors(this.props);
        // this.height = window.innerHeight ||
        //     document.documentElement.clientHeight ||
        //     document.body.clientHeight;
    }

    componentDidMount () {
        // the host should be passed from here but port is not correct in dev
        this.props.hostUpdate(window.location.hostname);
        // setting timer to check login hasn't expired
        this.props.setInterval(() => {
            this.forceUpdate();
        }, this.sessionExpirationCheckFrequency);
        if (!this.props.username && !this.listener) {
            document.addEventListener('keypress', this.onKeyPressed.bind(this));
            this.listener = true;
        }

        if (this.props.username && !global.registration) {
            registerSW();
        }
    }

    componentWillReceiveProps (nextProps, nextContext) {
        this._parseErrors(nextProps);
        if (nextProps.authError || nextProps.dataError) {
            const self = this;
            globalKontraktor.kclient = new KClient().useProxies(false);
            globalKontraktor.kclient.listener = new InspectItListener(self);
        }
        if (nextProps.username && this.listener) {
            document.removeEventListener('keypress', this.onKeyPressed.bind(this));
            this.listener = false;

            if (nextProps.username && !global.registration && !this.state.username) {
                registerSW();
            }
        }
        if (!nextProps.username && !this.listener) {
            document.addEventListener('keypress', this.onKeyPressed.bind(this));
            this.listener = true;
        }
    }

    componentWillUnmount () {
        this.props.clearIntervals();
        document.removeEventListener('keypress', this.onKeyPressed.bind(this));
        this.listener = false;
    }

    onKeyPressed (target) {
        if (target.charCode === 13 && !this.props.username) {
            console.log(target.charCode);
            console.log(target.key);
            this.login();
        }
    }


    _parseErrors = props => {
        const { authError, dataError } = props;
        if (authError) {
            this.setState({
                snackText: authError
            });
        } else if (dataError) {
            this.setState({
                snackText: dataError
            });
        }
    };

    sessionExpirationTime = 60 * 1000 * 60; // 1 h
    sessionExpirationCheckFrequency = 60 * 1000; /// 1 min

    handleUChange = (ev, field) => {
        this.setState({ [`${field}FieldValue`]: ev.target.value });
    };

    validate = () => this.state.usernameFieldValue.trim().length > 0 && this.state.passwordFieldValue.trim().length > 0;

    login = () => {
        if (this.state.usernameFieldValue && this.state.passwordFieldValue) {
            this.props.login(this.state.usernameFieldValue, this.state.passwordFieldValue);
        } else {
            this.setState({ snackText: 'Fill in all the fields' });
        }
    };

    render () {
        return (
            <FlexView
                className="view"
                style={{
                    justifyContent: "center",
                    alignContent: 'stretch',
                    flex: 1,
                    display: 'flex'
                    // height: this.height
                    // maxHeight: this.height
                }}
            >
                <SnackBar
                    open={!!this.state.snackText}
                    message={this.state.snackText}
                    autoHideDuration={2000}
                    onClose={() => this.setState({ snackText: "" })}
                />
                <Dialog
                    style={{ justifyContent: "center", padding: "20%" }}
                    maxWidth="md"
                    fullWidth
                    open={this.props.loginTimeStamp > 0 &&
                    this.props.loginTimeStamp + this.sessionExpirationTime <= Date.now()}
                    onClose={() => {
                        this.props.loggedOut();
                    }}
                >
                    <DialogTitle>Session expired</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Session timed out. Please login.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: '#F56260', color: '#FFFFFF' }}
                            onClick={() => {
                                this.props.loggedOut();
                            }}
                            autoFocus
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.props.username ? this.props.children : (
                    <Fader style={{ justifyContent: "center" }}>
                        <HCenter style={{ justifyContent: "center" }}>
                            <FlexView
                                className="container"
                                style={{ width: '100%', height: '100%', justifyContent: 'center' }}
                            >
                                <Paper style={{ width: '45%', margin: '25%' }} elevation={5}>
                                    <FlexView className="obsRowThin"/>
                                    <FlexView className="obsRowColour">
                                        Log In
                                    </FlexView>
                                    <FlexView className="rowTall">
                                        <FlexView className="obsCell-90AutoCenter">
                                            <TextField
                                                onChange={ev => this.handleUChange(ev, 'username')}
                                                placeholder="Username"
                                                fullWidth
                                            />
                                        </FlexView>
                                    </FlexView>
                                    <FlexView className="rowThin"/>
                                    <FlexView className="rowTall">
                                        <FlexView className="obsCell-90AutoCenter">
                                            <TextField
                                                onChange={ev => this.handleUChange(ev, 'password')}
                                                placeholder="Password"
                                                fullWidth
                                                type="password"
                                            />
                                        </FlexView>
                                    </FlexView>
                                    <FlexView className="rowThin"/>
                                    <FlexView className="rowTall">
                                        <FlexView className="obsCell-90AutoCenter">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                style={{ backgroundColor: '#F56260', color: '#FFFFFF', width: '200px' }}
                                                disabled={!this.validate()}
                                                onClick={() => this.login()}
                                            >
                                                Login
                                            </Button>
                                        </FlexView>
                                    </FlexView>
                                </Paper>
                            </FlexView>
                        </HCenter>
                    </Fader>
                )}
            </FlexView>
        );
    }
}

Login.propTypes = {
    children: PropTypes.shape().isRequired,
    //redux props
    username: PropTypes.string.isRequired,
    authError: PropTypes.string.isRequired,
    dataError: PropTypes.string.isRequired,
    loginTimeStamp: PropTypes.number.isRequired,
    // composition
    setInterval: PropTypes.func.isRequired,
    clearIntervals: PropTypes.func.isRequired,
    //redux actions
    login: PropTypes.func.isRequired,
    loggedOut: PropTypes.func.isRequired,
    hostUpdate: PropTypes.func.isRequired
};
Login.defaultProps = {};

// redux settings
const mapStateToProps = state => ({
    username: state.auth.username,
    authError: state.auth.error,
    dataError: state.data.error,
    loginTimeStamp: state.auth.loginTimeStamp
});

const mapDispatchToProps = dispatch => bindActionCreators({
    hostUpdate,
    login,
    loggedOut
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(intervals(Login));
