import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Location from './Location/Location';
import ImagePhoto from '@material-ui/icons/Photo';
import ImageImage from '@material-ui/icons/Image';
import MapsMap from '@material-ui/icons/Map';
import EditorInsertDriveFile from '@material-ui/icons/InsertDriveFile';
import { DropTarget } from 'react-dnd';
import { green } from "@material-ui/core/colors";
import { bindActionCreators } from "redux";
import { viewElement } from "../../../../actions/view";
import { connect } from 'react-redux';
import {
    getValue,
    getId,
    getName,
    getParentsIdArray,
    filterCollectionByParentSorted
} from '../../../../utils/data';
import { addLocation, moveLocation } from "../../../../actions/data";

const green50 = green[50];
const green200 = green[200];

const icons = {
    photo: <ImagePhoto/>,
    map: <MapsMap/>,
    drawing: <ImageImage/>,
    plan: <EditorInsertDriveFile/>
};

class VisualAsset extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            locations: [],
            allowDropStyle: {},
            unfolded: false
        };
    }

    componentWillMount () {
        if (this.props.viewId) {
            const parentIds = getParentsIdArray(this.props.viewId);
            if (parentIds.includes(getId(this.props.asset))) {
                this.setState({ unfolded: true });
            }
        }
    }

    componentWillReceiveProps (nextProps, nextContext) {
        // here we check if if an object is hovered over current component
        // if it is, the component is highlighted in green
        if (!this.props.isOver && nextProps.isOver) {
            // hover enter handler
            this.setState({ allowDropStyle: { backgroundColor: green200 } });
        }

        // highlighting is cancelled when an object stops hovering over the component
        if (this.props.isOver && !nextProps.isOver) {
            // hover leave handler
            this.setState({ allowDropStyle: {} });
        }
    }

    handleCollapse = () => {
        this.setState({ unfolded: !this.state.unfolded });
    };

    render () {
        let selected = {};
        if (this.props.viewId === getId(this.props.asset) &&
            this.props.viewType.toLowerCase() === getValue(this.props.asset, 'assetType')) {
            selected = {
                backgroundColor: green50
            };
        }
        const nestedItems = filterCollectionByParentSorted(this.props.locations, this.props.asset)
            .map(
                location => (
                    <Location
                        key={getId(location)}
                        location={location}
                    />
                )
            );
        let unfoldButton = null;
        let collapse = null;
        if (nestedItems.length) {
            unfoldButton = (
                <ListItemSecondaryAction>
                    <IconButton onClick={this.handleCollapse}>
                        {this.state.unfolded ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </ListItemSecondaryAction>
            );
            collapse = (
                <Collapse in={this.state.unfolded}>
                    <List>
                        {nestedItems}
                    </List>
                </Collapse>
            );
        }
        const viType = getValue(this.props.asset, 'assetType').toLowerCase();
        let icon = Object.keys(icons).includes(viType) ? icons[viType] : <EditorInsertDriveFile/>;


        return this.props.connectDropTarget(
            <div>
                <ListItem
                    button
                    dense
                    onClick={() => this.props.viewElement('visualAsset',
                        getId(this.props.asset))}
                    style={{ ...selected, ...this.state.allowDropStyle, paddingLeft: 24 + 10 * 4 }}
                >
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText>
                        {getName(this.props.asset)}
                    </ListItemText>
                    {unfoldButton}
                </ListItem>
                {collapse}
            </div>
        );
    }
}

VisualAsset.propTypes = {
    asset: PropTypes.shape({
        assetName: PropTypes.shape.isRequired,
        assetType: PropTypes.shape.isRequired
        // locations: PropTypes.array.isRequired
    }).isRequired,
    // dnd props below
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    // redux props
    locations: PropTypes.array.isRequired,
    viewType: PropTypes.string,
    viewId: PropTypes.string,
    // redux actions
    viewElement: PropTypes.func,
    addLocation: PropTypes.func.isRequired
};
VisualAsset.defaultProps = {};


//dnd settings
const targetSpecs = {
    drop (props/*, monitor, component*/) {
        props.addLocation(getId(props.asset), { x_offset: '100', y_offset: '100' });
        return {};
    }
};

const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});

// redux settings
const mapStateToProps = (state) => ({
    viewedElement: state.view.element,
    locations: state.data.locations,
    viewId: state.view.elementPrimaryKey,
    viewType: state.view.elementType
});

const mapDispatchToProps = dispatch => bindActionCreators({
    viewElement,
    addLocation,
    moveLocation
}, dispatch);

// eslint-disable-next-line new-cap
export default connect(mapStateToProps, mapDispatchToProps)(DropTarget('Location', targetSpecs, collect)(VisualAsset));
