import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import './toolbar.css';
import LeftArrow from '@material-ui/icons/KeyboardArrowLeft';
import TwoColumns from 'mdi-material-ui/FormatColumns';
import OneColumn from 'mdi-material-ui/FormatAlignJustify';
import Block1 from 'mdi-material-ui/FormatFloatLeft';
import Menu from '@material-ui/icons/Menu';
import Table from 'mdi-material-ui/Table';
import TextBox from 'mdi-material-ui/Textbox';
import SquareOutline from 'mdi-material-ui/SquareOutline';
import Image from '@material-ui/icons/Image';
import DnDBlock from './DnDBlock';
import ReactDOM from "react-dom";


class Toolbar extends Component {
    toolbarRef = null;

    getBBox = () => {
        if (this.toolbarRef) {
            return ReactDOM.findDOMNode(this.toolbarRef).getBoundingClientRect();
        }
    };

    render () {
        return !this.props.locked ? (
            <div className={'toolbarWrap'}>
                <div
                    className={'toolbar'}
                    ref={ref => {
                        this.toolbarRef = ref;
                    }}
                >
                    <DnDBlock
                        icon={<OneColumn/>}
                        dndType={'Layout 1'}
                    />
                    <DnDBlock
                        icon={<TwoColumns/>}
                        dndType={'Layout 2'}
                    />
                    <div className={'separator'}/>
                    <DnDBlock
                        icon={<Block1/>}
                        dndType={'Block 1'}
                    />
                    <DnDBlock
                        icon={<TextBox/>}
                        dndType={'Block 2'}
                    />
                    <DnDBlock
                        icon={<Table/>}
                        dndType={'Block 3'}
                    />
                    <DnDBlock
                        icon={<Image/>}
                        dndType={'Block 4'}
                    />
                    <div className={'separator'}/>
                    <DnDBlock
                        icon={<SquareOutline/>}
                        dndType={'Placeholder'}
                    />
                    <div className={'separator'}/>
                    <div>
                        <IconButton
                            onClick={this.props.lockCallback}
                        >
                            <LeftArrow/>
                        </IconButton>
                    </div>
                </div>
            </div>
        ) : (
            <div className={'toolbarCollapsed'}>
                <IconButton
                    onClick={this.props.lockCallback}
                >
                    <Menu/>
                </IconButton>
            </div>
        );
    }
}

Toolbar.propTypes = {
    locked: PropTypes.bool.isRequired,
    lockCallback: PropTypes.func.isRequired
};

export default Toolbar;
