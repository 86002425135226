import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import DeleteButton from './DeleteModal';
import { green } from "@material-ui/core/colors";
import { DropTarget, DragSource } from 'react-dnd';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { addNewElement, deleteElement, moveImage, updateElement } from "../../../actions/data";
import {
    copyObject,
    filterCollectionByParentSorted,
    getId, getName,
    getParentId,
    getSequence, getValue
} from "../../../utils/data";
import flow from 'lodash/flow';
import AnnotatedObject from "../../AnnotatedObject/AnnotatedObject";

const green200 = green[200];

class ObservationImage extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            allowDropStyle: {},
            collapsed: false
        };
    }

    componentWillReceiveProps (nextProps, nextContext) {
        // here we check if if an object is hovered over current component
        // if it is, the component is highlighted in green
        if (!this.props.isOver && nextProps.isOver && nextProps.canDrop) {
            // hover enter handler
            this.setState({ allowDropStyle: { backgroundColor: green200 } });
        }

        // highlighting is cancelled when an object stops hovering over the component
        if (this.props.isOver && !nextProps.isOver) {
            // hover leave handler
            this.setState({
                allowDropStyle: {},
                collapsed: true
            });
        }
    }

    render () {
        const { image, allAnnotations, connectDragSource, connectDropTarget } = this.props;
        const annotations = filterCollectionByParentSorted(allAnnotations, this.props.image, 'no');
        const annotationTypes = this.props.annotationTypes
            .filter(annotationType => getValue(annotationType, 'type') === this.props.material)
            .map(annotationType => getValue(annotationType, 'annotation'));
        return (
            <Card key={getId(image)} style={{ ...this.state.allowDropStyle, width: '100%' }}>
                {connectDropTarget(connectDragSource(
                    <div>
                        <CardHeader
                            title={<div style={{ display: 'flex', flexDirection: 'row',
                                justifyContent: 'flexStart', alignItems: 'flex-start', marginBottom: 5 }}>
                                <div style={{ width: 30, marginTop: 4, fontSize: 16 }}>{getSequence(image)}.&nbsp;</div>
                                <div style={{ flex: 1 }}>
                                    <TextField
                                        defaultValue={getName(image)}
                                        onBlur={event =>
                                            this.props.updateElement('image', getId(image),
                                                { name: event.target.value })}
                                        style={{ width: '90%' }}
                                        inputProps={{
                                            style: {
                                                fontSize: 16,
                                                fontWeight: 400,
                                                width: '100%'
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ marginRight: 20, marginTop: 5 }}>
                                    <DeleteButton
                                        pk={getId(this.props.image)}
                                        metaType={'image'}
                                        deleteCallback={this.props.deleteElement}
                                        objectType={'image'}
                                    />
                                </div>
                            </div>}
                            // subheader={getValue(image, 'filename')}
                            action={
                                <IconButton
                                    onClick={() => {
                                        this.setState({ collapsed: !this.state.collapsed });
                                    }}
                                >
                                    {!this.state.collapsed ? (<ExpandMore/>) : (<ExpandLess/>)}
                                </IconButton>
                            }
                        />
                    </div>
                ))}
                <Collapse in={this.state.collapsed}>
                    <CardContent>
                        <AnnotatedObject
                            image={image}
                            nodeType="observation"
                            nodeId={getParentId(image)}
                            annotations={copyObject(annotations)}
                            updateElement={this.props.updateElement}
                            deleteElement={this.props.deleteElement}
                            addNewElement={this.props.addNewElement}
                            dataStructure={this.props.dataStructure}
                            imageUnfolded={this.state.collapsed}
                            annotationTypes={annotationTypes}
                        />
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}

ObservationImage.propTypes = {
    // object props from parent
    image: PropTypes.shape().isRequired,
    material: PropTypes.string.isRequired,
    // redux props
    allAnnotations: PropTypes.array.isRequired,
    dataStructure: PropTypes.array.isRequired,
    annotationTypes: PropTypes.array.isRequired,
    // redux actions
    addNewElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    moveImage: PropTypes.func.isRequired,
    // dnd props below
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired
};
ObservationImage.defaultProps = {};

// redux settings
const mapStateToProps = (state) => ({
    allAnnotations: state.data.annotations,
    dataStructure: state.data.dataStructure,
    annotationTypes: state.data.annotationTypes
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addNewElement,
    updateElement,
    deleteElement,
    moveImage
}, dispatch);


//dnd settings
const targetSpecs = {
    drop (props, monitor/*, component*/) {
        let item = monitor.getItem();
        console.log('Attempt to move an observation image');
        props.moveImage(item.id, getSequence(props.image));
    },
    canDrop (props, monitor) {
        let item = monitor.getItem();
        return item.id !== getId(props.image) && item.itemType === 'image';
    }
};

const targetCollect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});

const sourceSpecs = {
    beginDrag (props/*, monitor, component*/) {
        return {
            itemType: 'image',
            id: getId(props.image)
        };
    }
};

const sourceCollect = (connect/*, monitor*/) => ({ connectDragSource: connect.dragSource() });


const getDropTypes = props =>
    ['Image_Observation_' + getParentId(props.image)];
const getItemType = props =>
    'Image_Observation_' + getParentId(props.image);

export default flow(
    // eslint-disable-next-line new-cap
    DragSource(getItemType, sourceSpecs, sourceCollect),
    // eslint-disable-next-line new-cap
    DropTarget(getDropTypes, targetSpecs, targetCollect),
    connect(mapStateToProps, mapDispatchToProps)
)(ObservationImage);
