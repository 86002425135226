import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SelectField from '../../../../composition/selectField';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
});

const SelectFieldObsMaterial = props => (
    <SelectField
        id="material"
        {...props}
    />
);

SelectFieldObsMaterial.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    options: PropTypes.array.isRequired
};
SelectFieldObsMaterial.defaultProps = {};

export default withStyles(styles)(SelectFieldObsMaterial);
