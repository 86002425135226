import { createStore, applyMiddleware } from 'redux';
// import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import rootReducer from './reducers';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createHistory();

const initialState = {};
const enhancers = [];
const middleware = [
    thunk
];

const composeEnhancers = composeWithDevTools({ trace: true });

const composedEnhancers = composeEnhancers(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
);
export const persistor = persistStore(store);
export default store;
