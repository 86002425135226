import React, { Component } from 'react';
import PropTypes from 'prop-types';
import timeout from './composition/timeout';

export class HCenter extends Component {
    render () {
        const style = {
            alignContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            ...this.props.style
        };
        return (<div style={style}>{this.props.children}</div>);
    }
}

HCenter.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    style: PropTypes.shape()
};


class FaderClass extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = { op: 0 };
    }

    componentDidMount () {
        this.props.setTimeout(() => this.setState({ op: 1 }), 100);
    }

    componentWillUnmount () {
        this.props.clearTimeouts();
    }

    render () {
        const style = {
            opacity: this.state.op,
            transition: "opacity .5s"
        };
        return (<div style={style}>{this.props.children}</div>);
    }
}

FaderClass.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    style: PropTypes.shape(),
    // composition
    setTimeout: PropTypes.func.isRequired,
    clearTimeouts: PropTypes.func.isRequired
};

export const Fader = timeout(FaderClass);
