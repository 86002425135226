import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { DropTarget } from "react-dnd";
import Button from '@material-ui/core/Button';
// import Dialog, { DialogContent, DialogActions } from "material-ui/Dialog";
// import Button from 'material-ui/Button';
// import { NativeTypes } from 'react-dnd-html5-backend';

// let FILE = '__NATIVE_FILE__';

class ImportDnDModal extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            errors: [],
            isOverStyle: {}
        };
    }

    _processFiles = (files = []) => {
        const filesCheckResult = this._checkFiles(files);
        if (filesCheckResult.accept) {
            this.props.importFilesCallback(this.props.multiple ? files : files[0]);
            this.props.onClose();
        } else {
            const errors = [];
            if (filesCheckResult.wrongFileType.length) {
                errors.push('The following files have wrong type: ' +
                    filesCheckResult.wrongFileType.map(file => file.name).join(', '));
            }
            if (filesCheckResult.wrongFileSize.length) {
                errors.push(`The following files are too large (max size is ${this.props.maxSizeMB}MB): ` +
                    filesCheckResult.wrongFileSize.map(file => file.name).join(', '));
            }
            this.setState({
                errors
            });
            console.log('Errors while importing files:', errors);
        }
    };

    _selectFiles = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const files = Array.from(event.target.files);
        if (files && files.length) {
            this._processFiles(files);
        } else {
            this.props.onClose();
        }
        this.fileInput.value = "";
    };

    _checkFiles (files) {
        // filtering wrong files and return false if there are any
        const accept = !files.filter(file =>
            !(this.props.acceptMimeTypes.includes(file.type) && file.size < this.props.maxSizeMB * 1024 * 1024)
        ).length;
        const result = { accept };
        // collecting errors if there are
        if (!accept) {
            result.wrongFileType = files.filter(file => !(this.props.acceptMimeTypes.includes(file.type)));
            result.wrongFileSize = files.filter(file => !(file.size < this.props.maxSizeMB * 1024 * 1024));
        }
        return result;
    }

    render () {
        return (
            <div>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={el => {
                        this.fileInput = el;
                    }}
                    onChange={this._selectFiles}
                    multiple={this.props.multiple}
                />
                <Button
                    variant="contained"
                    size="small"
                    height="45px"
                    style={{ backgroundColor: '#F56260', color: '#ffffff', margin: '10px' }}
                    onClick={() => this.fileInput.click()}
                >
                    {this.props.buttonText}
                </Button>
            </div>
        );

        /* const border = this.props.isOver ? '2px dashed orange' : '2px dashed grey';
                const dropZone = this.props.connectDropTarget(
                    <div
                        style={{
                            border,
                            display: "flex",
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            height: 200,
                            zIndex: 100,
                            ...this.state.isOverStyle
                        }}
                    >
                        Drag files here
                    </div>
                );
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <DialogContent>
                    {dropZone}
                    {this.state.errors ?
                        this.state.errors.map((error, index) => <p key={`error${index}`}>{error}</p>) : null}
                </DialogContent>
                <DialogActions>

                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={el => {
                            this.fileInput = el;
                        }}
                        onChange={this._selectFiles}
                        multiple
                    />
                           <Button
                                style={{
                                    backgroundColor: '#F56260',
                                    color: '#ffffff',
                                    margin: '5px'
                                }}
                                variant="contained"
                                size="medium"
                                onClick={this.props.onClose}
                            >Cancel</Button>
                            <Button
                                style={{
                                    backgroundColor: '#F56260',
                                    color: '#ffffff',
                                    margin: '5px'
                                }}
                                variant="contained"
                                size="medium"
                                onClick={() => {
                                    this.fileInput.click();
                                }}
                            >Select Files{/!* Manually*!/}</Button>
                        </DialogActions>
                    </Dialog>
        );*/
    }
}

ImportDnDModal.propTypes = {
    // open: PropTypes.bool.isRequired,
    importFilesCallback: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    acceptMimeTypes: PropTypes.array,
    maxSizeMB: PropTypes.number,
    multiple: PropTypes.bool,
    buttonText: PropTypes.string
};

ImportDnDModal.defaultProps = {
    acceptMimeTypes: ['image/png', 'image/jpg', 'image/jpeg'],
    maxSizeMB: 32,
    multiple: false,
    buttonText: 'Import Files',
    onClose: () => {}
};

//dnd settings
const targetSpecs = {
    drop (props, monitor, component) {
        const droppedFiles = monitor.getItem().files;
        component._processFiles(droppedFiles);
    }/*,
    hover (props, monitor, component) {
        console.log(monitor.getItemType());
    }*/
};

const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});

// const allowTypes = (/*props*/) => FILE;

// eslint-disable-next-line new-cap
export default DropTarget('', targetSpecs, collect)(ImportDnDModal);
