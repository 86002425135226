import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// const styles = theme => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap'
//     },
//     formControl: {
//         margin: theme.spacing(),
//         minWidth: 120
//     },
//     selectEmpty: {
//         marginTop: theme.spacing(2)
//     }
// });

class SelectField extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            [this.props.id]: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount () {
        this.setState({ [this.props.id]: this.props.value ? this.props.value : '' });
    }

    componentWillReceiveProps (nextProps, nextContext) {
        this.setState({ [nextProps.id]: nextProps.value ? nextProps.value : '' });
    }

    handleChange = event => {
        if (this.props.onChange) {
            // passive state
            this.props.onChange(event);
        } else {
            this.setState({ [this.props.id]: event.target.value });
        }
    };

    render () {
        const { classes } = this.props;
        return (
            <form className={classes.root} autoComplete="off">
                <FormControl className={classes.formControl}>
                    <Select
                        value={this.state[this.props.id]}
                        onChange={this.handleChange}
                        inputProps={{
                            name: this.props.id,
                            id: this.props.id
                        }}
                    >
                        <MenuItem value={null}/>
                        {
                            this.props.options.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </form>
        );
    }
}

SelectField.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string
};
SelectField.defaultProps = {};

export default SelectField;
