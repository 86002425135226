import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { Paper, Path } from 'react-raphael';

class LegendItem extends Component {
    render () {
        const sampleStyle = Object.assign(
            { stroke: "#CCC", strokeWidth: 1, fill: "none", fillOpacity: "0.5" },
            this.props.sampleStyles
        );
        return this.props.connectDragSource(
            <div className={'nonSelectable'}>
                <div>{this.props.label}</div>
                <Paper width={100} height={30}>
                    <Path
                        ref={el => {
                            this.pathRef = el;
                            if (el && sampleStyle.fill) {
                                el.getElement().node.setAttribute('fill', sampleStyle.fill);
                            }
                        }}
                        id={`li_${this.props.label}`}
                        d="M0,0L100,0L100,30L0,30Z"
                        attr={{
                            "stroke": sampleStyle.stroke,
                            "stroke-width": sampleStyle.strokeWidth,
                            "fill-opacity": sampleStyle.fillOpacity
                        }}
                    />
                </Paper>
            </div>
        );
    }
}

LegendItem.propTypes = {
    label: PropTypes.string.isRequired,
    sampleStyles: PropTypes.shape().isRequired,
    // dnd props
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired
};
LegendItem.defaultProps = {};
// This will return the label of the item as a type
const getType = props => props.label;

const dndSpecs = {
    // begin Drag sets the information transferred to the target
    beginDrag (props) {
        return { dragType: props.label };
    }
};

const collect = connect => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview()
});

// eslint-disable-next-line new-cap
export default DragSource(getType, dndSpecs, collect)(LegendItem);
