import React from 'react';
import * as PropTypes from 'prop-types';
import Column from './Column';

const TwoColumns = (props) => {
    return (
        <div className={props.locked ? 'layout2locked' : 'layout2'}>
            <Column
                type={'left'} id={props.leftColId}
                addNewElement={props.addNewElement}
                blocks={props.leftBlocks}
                locked={props.locked}
                updateCallback={props.updateCallback}
            />
            <Column
                type={'right'} id={props.rightColId}
                addNewElement={props.addNewElement}
                blocks={props.rightBlocks}
                locked={props.locked}
                updateCallback={props.updateCallback}
            />
        </div>
    );
};

TwoColumns.propTypes = {
    leftColId: PropTypes.string.isRequired,
    rightColId: PropTypes.string.isRequired,
    leftBlocks: PropTypes.array,
    rightBlocks: PropTypes.array,
    addNewElement: PropTypes.func.isRequired,
    locked: PropTypes.bool.isRequired,
    updateCallback: PropTypes.func.isRequired
};
TwoColumns.defaultProps = {
    leftBlocks: [],
    rightBlocks: []
};

// eslint-disable-next-line new-cap
export default TwoColumns;
