import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SelectField from '../../../../composition/selectField';

const styles = (/*theme */) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    formControl: {
        width: '97%'
    }
});

const SelectFieldConsultant = props => (
    <SelectField
        id="consultant"
        {...props}
    />
);

SelectFieldConsultant.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired
};
SelectFieldConsultant.defaultProps = {
    options: []
};

export default withStyles(styles)(SelectFieldConsultant);
