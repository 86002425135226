import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CommunicationLocationOn from '@material-ui/icons/LocationOn';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LocationElement from './LocationElement';
import { green } from "@material-ui/core/colors";
import { DropTarget, DragSource } from 'react-dnd';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { moveLocation, addNewElement } from "../../../../../actions/data";
import {
    filterCollectionByParentSorted,
    getId,
    getName, getParentId,
    getParentsIdArray, getSequence
} from '../../../../../utils/data';
import flow from 'lodash/flow';

const green200 = green[200];

class Location extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            measurements: [],
            observations: [],
            allowDropStyle: {},
            unfolded: false
        };
    }

    componentWillMount () {
        if (this.props.viewId) {
            const parentIds = getParentsIdArray(this.props.viewId);
            if (parentIds.includes(getId(this.props.location))) {
                this.setState({ unfolded: true });
            }
        }
    }

    componentWillReceiveProps (nextProps, nextContext) {
        // here we check if if an object is hovered over current component
        // if it is, the component is highlighted in green
        if (!this.props.isOver && nextProps.isOver && nextProps.canDrop) {
            // hover enter handler
            this.setState({ allowDropStyle: { backgroundColor: green200 } });
        }

        // highlighting is cancelled when an object stops hovering over the component
        if (this.props.isOver && !nextProps.isOver) {
            // hover leave handler
            this.setState({ allowDropStyle: {} });
        }
    }

    handleCollapse = () => {
        this.setState({ unfolded: !this.state.unfolded });
    };

    render () {
        // parsing observations
        const observationObjects = filterCollectionByParentSorted(this.props.observations, getId(this.props.location));
        let observations = observationObjects ? observationObjects.map(el => (
            <LocationElement
                key={getId(el)}
                element={el}
            />
        ), this) : null;
        // parsing measurements
        const measurementObjects = filterCollectionByParentSorted(this.props.measurements, getId(this.props.location));
        let measurements = measurementObjects ? measurementObjects.map(el => (
            <LocationElement
                key={getId(el)}
                element={el}
            />
        ), this) : null;
        // combining visual assets with observations and measurements
        const nestedItems = [].concat(observations, measurements);
        let unfoldButton = null;
        let collapse = null;
        if (nestedItems.length) {
            unfoldButton = (
                <ListItemSecondaryAction>
                    <IconButton onClick={this.handleCollapse}>
                        {this.state.unfolded ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </ListItemSecondaryAction>
            );
            collapse = (
                <Collapse in={this.state.unfolded}>
                    <List>
                        {nestedItems}
                    </List>
                </Collapse>
            );
        }
        return (
            <div>
                {this.props.connectDropTarget(this.props.connectDragSource(
                    <div>
                        <ListItem
                            dense
                            button
                            onClick={this.handleCollapse}
                            style={{ ...this.state.allowDropStyle, paddingLeft: 24 + 10 * 5 }}
                        >
                            <ListItemIcon>
                                <CommunicationLocationOn/>
                            </ListItemIcon>
                            <ListItemText>
                                {getName(this.props.location)}
                            </ListItemText>
                            {unfoldButton}
                        </ListItem>
                    </div>
                ))}
                {collapse}
            </div>
        );
    }
}

Location.propTypes = {
    location: PropTypes.shape().isRequired,
    viewId: PropTypes.string,
    // dnd props below
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    // redux props
    observations: PropTypes.array.isRequired,
    measurements: PropTypes.array.isRequired,
    moveLocation: PropTypes.func.isRequired,
    addNewElement: PropTypes.func.isRequired
};
Location.defaultProps = {};

//dnd settings
const targetSpecs = {
    drop (props, monitor/*, component*/) {
        let item = monitor.getItem();
        let dragType = item.dragType;
        dragType = dragType.toLowerCase();
        if (dragType === 'location') {
            if (item.id !== getId(props.location) &&
                    monitor.getItemType() === 'Location_VisualAsset_' + getParentId(props.location)) {
                // console.log('Attempt to move a location');
                props.moveLocation(item.id, getSequence(props.location));
            }
        } else {
            props.addNewElement(dragType, getId(props.location));
        }
        return {};
    },
    canDrop (props, monitor) {
        let item = monitor.getItem();
        return item.id !== getId(props.location);
    }
}
;

const targetCollect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});

const sourceSpecs = {
    beginDrag (props/*, monitor, component*/) {
        return {
            dragType: 'location',
            id: getId(props.location)
        };
    }
};

const sourceCollect = (connect/*, monitor*/) => ({ connectDragSource: connect.dragSource() });

// redux settings
const mapStateToProps = state => ({
    observations: state.data.observations,
    measurements: state.data.measurements,
    viewId: state.view.elementPrimaryKey
});

const mapDispatchToProps = dispatch => bindActionCreators({
    moveLocation,
    addNewElement
}, dispatch);

const getDropTypes = props => ['Observation', 'Measurement', 'Location_VisualAsset_' + getParentId(props.location)];
const getDragType = props => 'Location_VisualAsset_' + getParentId(props.location);

export default flow(
    // eslint-disable-next-line new-cap
    DragSource(getDragType, sourceSpecs, sourceCollect),
    // eslint-disable-next-line new-cap
    DropTarget(getDropTypes, targetSpecs, targetCollect),
    connect(mapStateToProps, mapDispatchToProps)
)(Location);
