import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FlexView from 'react-flexview';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import SelectFieldObsStatus from './selects/select_field_obs_status';
import SelectFieldObsMaterial from './selects/select_field_obs_material';
import FilesImportDialog from './ImportDnDModal';
import { filterCollectionByParentSorted, getId } from "../../../utils/data";
import entity from "../../../composition/entity";
import DeleteButton from './DeleteModal';
import HTMLEditor from "../../HTMLEditor";
import ObservationImage from "./ObservationImage";

const styles = theme => ({
    root: {
        margin: theme.spacing(3),
        width: "90%"
    },
    heading: {
        fontSize: 28,
        fontVariant: 'small-caps'
    },
    subText: {
        fontSize: 12
    },
    subHeading: {
        fontSize: 13
    },
    subHeadingBold: {
        fontSize: 13
    },
    button: {
        margin: theme.spacing()
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%'
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        width: '100%'
    }
});

const materialOptions = [
    { value: "1", name: 'Steel' },
    { value: "2", name: 'Coating' },
    { value: "3", name: 'Concrete' },
    { value: "4", name: 'Timber' }
];

class Observation extends Component {
    _importFiles = (files = []) => {
        this.props.importImages(files, this.props.id);
        this.setState({ filesImportDialogOpen: false });
    };

    updateSelectField = (event, fieldName) => {
        this.props.saveField(fieldName, event.target.value);
    };

    getMaterialName = () => {
        const materialOption = materialOptions.find(option => option.value === this.props.material);
        return materialOption ? materialOption.name : null;
    };

    render () {
        let files = (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {filterCollectionByParentSorted(this.props.data.images, this.props.id)
                    .map(image => (
                        <ObservationImage
                            key={getId(image)}
                            image={image}
                            material={this.getMaterialName()}
                        />
                    ))
                }
            </div>
        );

        const { classes } = this.props;
        return (
            <FlexView className="container" column width="100%" height="100%">
                <Paper className={classNames(classes.root, "observation")} elevation={4}>
                    <FlexView className="obsRowThin"/>
                    <FlexView className="obsRowColour" style={{ justifyContent: 'space-between' }}>
                        <div>Observation Details</div>
                        <div style={{ marginRight: 20, marginTop: 10 }}>
                            <DeleteButton
                                pk={this.props.id}
                                metaType={'observation'}
                                deleteCallback={this.props.deleteElement}
                                objectType={'observation'}
                            />
                        </div>
                    </FlexView>
                    <FlexView className="obsRowThinInvis"/>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-21", "subHeading")}>
                            Observation Name
                        </FlexView>
                        <FlexView className="obsCell-2"/>
                        <FlexView className="obsCell-67">
                            <form
                                className={classNames(classes.container, "observation")}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    id="oName"
                                    className={classes.textField}
                                    value={this.props.name}
                                    onChange={ev => {
                                        this.props.onChangeFieldValue({ name: ev.target.value });
                                    }}
                                    onBlur={() => {
                                        this.props.saveField('name');
                                    }}
                                    margin="normal"
                                    fullWidth
                                />
                            </form>
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="obsRowAuto">
                        <FlexView className="obsColCell-5"/>
                        <FlexView className="obsColCell-21">
                            <FlexView className={classNames("obsCell-100", "subHeading")}>
                                Status
                            </FlexView>
                        </FlexView>
                        <FlexView className="obsColCell-2"/>
                        <FlexView className="obsColCell-21">
                            <FlexView className="obsCell-100">
                                <SelectFieldObsStatus
                                    value={this.props.status}
                                    onChange={event => this.updateSelectField(event, 'status')}
                                />
                            </FlexView>
                        </FlexView>
                        <FlexView className="obsColCell-2"/>
                        <FlexView className="obsColCell-21">
                            <FlexView
                                className={classNames("obsCell-100", "subHeading")}
                                style={{ justifyContent: 'center' }}>
                                Material
                            </FlexView>
                        </FlexView>
                        <FlexView className="obsColCell-2"/>
                        <FlexView className="obsColCell-21">
                            <FlexView className="obsCell-100">
                                <SelectFieldObsMaterial
                                    value={this.props.material}
                                    onChange={event => this.updateSelectField(event, 'material')}
                                    options={materialOptions}
                                />
                            </FlexView>
                        </FlexView>
                        <FlexView className="obsColCell-5"/>
                    </FlexView>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-21", "subHeading")}>
                            Element
                        </FlexView>
                        <FlexView className="obsCell-2"/>
                        <FlexView className="obsCell-67">
                            <form className={classes.container} noValidate autoComplete="off">
                                <TextField
                                    id="element"
                                    className={classes.textField}
                                    margin="normal"
                                    value={this.props.element_type}
                                    onChange={ev => {
                                        this.props.onChangeFieldValue({ element_type: ev.target.value });
                                    }}
                                    onBlur={() => {
                                        this.props.saveField('element_type');
                                    }}
                                />
                            </form>
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    {/*<FlexView className={classNames("obsCell-100", "subHeading")}>*/}
                    {/*Defect Type*/}
                    {/*</FlexView>*/}
                    {/*<FlexView className="obsRowAuto">*/}
                    {/*<FlexView className="obsCell-100">*/}
                    {/*<SelectFieldObsDefect*/}
                    {/*value={this.props.material_defect_type}*/}
                    {/*onChange={event => this.updateSelectField(event, 'material_defect_type')}*/}
                    {/*/>*/}
                    {/*</FlexView>*/}
                    {/*</FlexView>*/}
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-100", "subHeading")}>
                            Comment
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="editorRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className="editorCell-90">
                            <HTMLEditor
                                onBlurCallback={description => this.props.saveField('description', description)}
                                value={this.props.description}
                            />
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="obsRowThinInvis"/>
                </Paper>
                <Paper
                    className={classNames(classes.root, "observation")
                    }
                    elevation={4}>
                    <FlexView
                        className="obsRowThin"/>
                    <FlexView
                        className="obsRowColour">
                        Observation
                        files
                    </ FlexView>
                    {
                        files
                    }
                    <FlexView className="obsRow">
                        <FlexView className="obscell-5"/>
                        <FlexView className="obsCell-90Center">
                            <FilesImportDialog
                                importFilesCallback={this._importFiles}
                                multiple
                            />
                        </FlexView>
                    </FlexView>
                </Paper>
            </FlexView>
        );
    }
}

Observation.propTypes = {
    // object props from composition
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    element_type: PropTypes.string.isRequired,
    material_defect_type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    material: PropTypes.string,
    // composition
    data: PropTypes.shape().isRequired,
    dataStructure: PropTypes.array.isRequired,
    onChangeFieldValue: PropTypes.func.isRequired,
    saveField: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    importImages: PropTypes.func.isRequired,
    // composition redux actions
    addNewElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    // style
    classes: PropTypes.shape().isRequired
};
Observation.defaultProps = {};

export default entity(withStyles(styles)(Observation), 'observation');
