import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SelectField from '../../../../composition/selectField';


const styles = (/*theme*/) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    formControl: {
        width: '97%'
    }
});

const SelectFieldJobType = props => (
    <SelectField
        id="job_type"
        {...props}
    />
);

SelectFieldJobType.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    options: PropTypes.array
};
SelectFieldJobType.defaultProps = {
    options: [
        { value: "1", name: "General Inspection" },
        { value: "2", name: "Detailed Testing" },
        { value: "3", name: "Detailed Investigation" },
        { value: "4", name: "Coating Inspection" },
        { value: "5", name: "Functional Check" },
        { value: "6", name: "Performance Check and System Commissioning" }
    ]
};

export default withStyles(styles)(SelectFieldJobType);
