import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlexView from 'react-flexview';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SelectFieldJobType from './selects/select_field_job_type';
import SelectFieldConsultant from './selects/select_field_consultant';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import entity from '../../../composition/entity';
import DeleteButton from './DeleteModal';

const styles = theme => ({
    root: {
        margin: theme.spacing(3),
        width: "90%"
    },
    heading: {
        fontSize: 28,
        fontVariant: 'small-caps'
    },
    subText: {
        fontSize: 12
    },
    subHeading: {
        fontSize: 16
    },
    subHeadingBold: {
        fontSize: 13
    },
    switchBase: {
        color: '#94e1e8'
    },
    checked: {
        color: '#02a1af'
    },
    bar: {
        backgroundColor: '#94e1e8'
    },
    button: {
        margin: theme.spacing()
    },
    textField: {
        marginRight: theme.spacing(),
        width: '100%'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        margin: '0px'
    },
    rightColumnField: {
        marginRight: theme.spacing(),
        width: '100%',
        marginTop: 0
    },
    rightColumnFieldCityState: {
        marginRight: theme.spacing(),
        width: '100%',
        marginTop: -8
    },
    rightColumnFieldPostcode: {
        marginRight: theme.spacing(),
        width: '100%',
        marginTop: -7
    }
});

class Project extends Component {
    constructor (props, context) {
        super(props, context);
        this.updateConsultant = this.updateConsultant.bind(this);
        this._toggleFetched = this._toggleFetched.bind(this);
    }

    _toggleFetched = () => {
        const fetched = this.props.fetched === 'false' ? 'true' : 'false';
        this.props.saveField('fetched', fetched);
        this.props.dataLoad();
    };

    _archive = () => {
        this.props.saveField('archived', 'true');
    };

    updateConsultant = event => {
        this.props.saveField('consultant', event.target.value);
    };

    updateJobType = event => {
        this.props.saveField('job_type', event.target.value);
    };

    export = () => {
        this.props.exportProjectToWord(this.props.id)
            .then(url => {
                window.location = url;
            })
            .catch(error => {
                console.error(error);
            });
    };

    render () {
        const { classes } = this.props;
        return (
            <div className="container">
                <Paper className={classes.root} elevation={4} style={{ alignSelf: 'center' }}>
                    <FlexView className="obsRowThin"/>
                    <FlexView className="obsRowColour" style={{ justifyContent: 'space-between' }}>
                        <div>Project Details</div>
                        <div style={{ marginRight: 20, marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: 10 }}>
                                <Button
                                    onClick={this.export}
                                    className="button"
                                    variant="contained"
                                    size={'small'}
                                    color={'primary'}
                                >
                                    Export to Word
                                </Button>
                            </div>
                            <DeleteButton
                                pk={this.props.id}
                                metaType={'project'}
                                deleteCallback={this.props.deleteElement}
                                objectType={'project'}
                            />
                        </div>
                    </FlexView>
                    <FlexView className="obsRowThinInvis"/>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-15", "subHeading")}>
                            Project Name
                        </FlexView>
                        <FlexView className="obsCell-70">
                            <TextField
                                fullWidth
                                label="Project Name"
                                value={this.props.name}
                                onChange={ev => {
                                    this.props.onChangeFieldValue({ name: ev.target.value });
                                }}
                                onBlur={() => {
                                    this.props.saveField('name');
                                }}
                            />
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-15", "subHeading")}>
                            Client
                        </FlexView>
                        <FlexView className="obsCell-70">
                            <TextField
                                fullWidth
                                label="Client Name"
                                value={this.props.clientname}
                                onChange={ev => {
                                    this.props.onChangeFieldValue({ clientname: ev.target.value });
                                }}
                                onBlur={() => {
                                    this.props.saveField('clientname');
                                }}
                            />
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-15", "subHeading")}>
                            Job Type
                        </FlexView>
                        <FlexView className="obsCell-35">
                            <SelectFieldJobType
                                value={this.props.job_type}
                                onChange={this.updateJobType}
                            />
                        </FlexView>
                        <FlexView
                            className={classNames("obsCell-35", "subHeading")}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            Address
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-15", "subHeading")}>
                            Job Number
                        </FlexView>
                        <FlexView className="obsCell-35">
                            <TextField
                                id="jobNo"
                                className={classes.textField}
                                margin="normal"
                                style={{ marginLeft: '0px' }}
                                value={this.props.job_no}
                                onChange={ev => {
                                    this.props.onChangeFieldValue({ job_no: ev.target.value });
                                }}
                                onBlur={() => {
                                    this.props.saveField('job_no');
                                }}
                            />
                        </FlexView>
                        <FlexView className="obsCell-35">
                            <TextField
                                label="Street Name"
                                margin="normal"
                                className={classes.rightColumnField}
                                value={this.props.address1}
                                onChange={ev => {
                                    this.props.onChangeFieldValue({ address1: ev.target.value });
                                }}
                                onBlur={() => {
                                    this.props.saveField('address1');
                                }}
                            />
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="obsRowAuto">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-15", "subHeading")}>
                            Job Dates
                        </FlexView>
                        <FlexView className="obsCell-35">
                            <TextField
                                id="date1"
                                // label="Job Start Date"
                                type="date"
                                className={classes.textField}
                                value={this.props.start_job}
                                onChange={ev => {
                                    if (ev.target.value) {
                                        this.props.onChangeFieldValue({ start_job: ev.target.value });
                                    }
                                }}
                                onBlur={() => this.props.saveField('start_job')}
                            />
                        </FlexView>
                        <FlexView className="obsCell-35">
                            <TextField
                                label="City"
                                margin="normal"
                                className={classes.rightColumnFieldCityState}
                                value={this.props.address2}
                                onChange={ev => {
                                    this.props.onChangeFieldValue({ address2: ev.target.value });
                                }}
                                onBlur={() => {
                                    this.props.saveField('address2');
                                }}
                            />
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="obsRowAuto">
                        <FlexView className="obsCell-5"/>
                        <FlexView className="obsCell-15"/>
                        <FlexView className="obsCell-35">
                            <TextField
                                id="date2"
                                // label="Job End Date"
                                type="date"
                                className={classes.textField}
                                value={this.props.end_job}
                                onChange={ev => {
                                    if (ev.target.value) {
                                        this.props.onChangeFieldValue({ end_job: ev.target.value });
                                    }
                                }}
                                onBlur={() => this.props.saveField('end_job')}
                            />
                        </FlexView>
                        <FlexView className="obsCell-35">
                            <TextField
                                label="State/District"
                                margin="normal"
                                className={classes.rightColumnFieldCityState}
                                value={this.props.address3}
                                onChange={ev => {
                                    this.props.onChangeFieldValue({ address3: ev.target.value });
                                }}
                                onBlur={() => {
                                    this.props.saveField('address3');
                                }}
                            />
                        </FlexView>
                    </FlexView>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-15", "subHeading")}>
                            Consultant
                        </FlexView>
                        <FlexView className="obsCell-35">
                            <SelectFieldConsultant
                                value={this.props.consultant}
                                onChange={this.updateConsultant}
                                options={this.props.consultants}
                            />
                        </FlexView>
                        <FlexView className="obsCell-35">
                            <TextField
                                label="Postcode"
                                margin="normal"
                                className={classes.rightColumnFieldPostcode}
                                value={this.props.postcode}
                                onChange={ev => {
                                    this.props.onChangeFieldValue({ postcode: ev.target.value });
                                }}
                                onBlur={() => {
                                    this.props.saveField('postcode');
                                }}
                            />
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className="obsCell-15"/>
                        <FlexView className="obsCell-35" style={{ justifyContent: 'space-around' }}>
                            <Button
                                variant="contained"
                                size="small"
                                style={{
                                    backgroundColor: '#F56260',
                                    color: '#ffffff',
                                    marginRight: "2px"
                                }}
                            >
                                Lock
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                style={{
                                    backgroundColor: '#F56260',
                                    color: '#ffffff',
                                    marginLeft: "2px",
                                    marginRight: "2px"
                                }}
                            >
                                Clone
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                style={{
                                    backgroundColor: '#F56260',
                                    color: '#ffffff',
                                    marginLeft: "2px",
                                    marginRight: "2px"
                                }}
                                onClick={this._archive}
                            >
                                Archive
                            </Button>
                        </FlexView>
                    </FlexView>
                    <FlexView className="obsRow" style={{ justifyContent: 'flex-start' }}>
                        <FlexView className="obsCell-15"/>
                        <FlexView className="obsCell-35">
                            <FormControlLabel
                                control={
                                    <Switch
                                        // TODO: check real value type being string or boolean for fetched field
                                        checked={this.props.fetched === 'true' || this.props.fetched === true}
                                        onChange={this._toggleFetched}
                                    />
                                }
                                label={this.props.fetched === 'true' ?
                                    'Fetch the Site details' : 'Do not fetch the Site details'}
                            />
                        </FlexView>
                    </FlexView>
                </Paper>
                {/*
                <Paper className={classes.root} elevation={4} style={{ alignSelf: 'center' }}>
                    <FlexView className="obsRowThin"/>
                    <FlexView className="obsRowColour">
                        Uploads
                    </FlexView>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                </Paper>
                */}
            </div>
        );
    }
}

Project.propTypes = {
    // object props from composition
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    consultant: PropTypes.string.isRequired,
    job_no: PropTypes.string.isRequired,
    job_type: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    address3: PropTypes.string.isRequired,
    address4: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    start_job: PropTypes.string.isRequired,
    end_job: PropTypes.string.isRequired,
    clientname: PropTypes.string.isRequired,
    fetched: PropTypes.string.isRequired,
    consultants: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    // composition
    onChangeFieldValue: PropTypes.func.isRequired,
    saveField: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    dataLoad: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    exportProjectToWord: PropTypes.func.isRequired,
    // style
    classes: PropTypes.shape().isRequired
};
Project.defaultProps = {};

export default entity(withStyles(styles)(Project), 'project');
