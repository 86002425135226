import React from 'react';

const Timeout = Composition => class _Timeout extends React.Component {
    constructor (props) {
        super(props);
    }

    componentWillMount () {
        this.intervals = [];
    }

    componentWillUnmount () {
        this.clearIntervals();
    }

    setInterval = (callback, freq) => {
        this.intervals.push(setInterval(callback, freq));
    };

    clearIntervals = () => {
        this.intervals.forEach(clearInterval);
    };

    render () {
        const { intervals, setInterval, clearIntervals } = this;

        return (<Composition
            intervals={intervals}
            setInterval={setInterval}
            clearIntervals={clearIntervals}
            {...this.props} />);
    }
};

export default Timeout;
