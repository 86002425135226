import { visualAssets } from "../actions/data";
import { isObject } from "./types";
import store from '../store';
import idb from 'idb';

const getState = store.getState;

export const generateTempId = () => '7777' + Math.floor(Math.random() * 100000);

export const getValue = (obj, valueName, returnUndefined = false) => {
    if (!obj) return null;
    let entity;
    if (Object.keys(obj).includes('entity')) {
        entity = obj.entity;
    } else if (Object.keys(obj).includes('record')) {
        entity = obj;
    } else {
        console.log("Can't get value from an object without entity or record field");
        return returnUndefined ? undefined : '';
    }
    const prop = entity.record.find(prop => prop.name === valueName);
    if (prop) {
        return prop.value;
    } else {
        const fields = obj.entity.record.map(field => field.name);
        console.log(`Field ${valueName} not found in object. Fields are: ${fields.join(', ')}`);
        return returnUndefined ? undefined : '';
    }
};

// export const getId = (obj, asString) => asString ? obj.entity.primaryKey.toString() : obj.entity.primaryKey;
export const getId = obj => getValue(obj, 'id');
export const getName = obj => getValue(obj, 'name');

export const getObjectById = id => {
    const allObjs =
        ['projects', 'sites', /*'photos', 'maps', 'drawings', 'plans', */'visualAssets', 'locations', 'measurements',
            'observations', 'images', 'inlineMeasurements']
            .reduce((objects, category) => objects.concat(store.getState().data[category]), []);
    return allObjs.find(obj => getId(obj) === id);
};

export const getParentId = objOrId => {
    const obj = isObject(objOrId) ? objOrId : getObjectById(objOrId);
    return getValue(obj, 'parentId');
};

export const copyObject = obj => obj ? JSON.parse(JSON.stringify(obj)) : null;

export const getRealMetaType = typeName => {
    let realType = visualAssets.includes(typeName) ? 'visual_asset' : typeName;
    switch (realType) {
        case 'visualAsset':
            realType = 'visual_asset';
            break;
        case 'image':
            realType = 'observationimage';
            break;
    }
    return realType;
};

export const getFieldsByMetaTypeId = (metaTypeId, structure) => {
    const metaType = structure.find(metaType => metaType.id === metaTypeId);
    return metaType.columns;
};

export const getFieldsByTypeName = (typeName, structure) => {
    typeName = getRealMetaType(typeName);
    const metaType = structure.find(metaType => metaType.name === typeName.toLowerCase());
    return metaType.columns;
};

export const getFieldsIdsByTypeName = (typeName, structure) => {
    typeName = getRealMetaType(typeName);
    const metaType = structure.find(metaType => metaType.name === typeName.toLowerCase());
    return metaType.columnIds;
};

export const getFieldMetaIdByFieldNameAndTypeName = (fieldName, typeName, structure) => {
    typeName = typeName[0].toLowerCase() + typeName.slice(1); // no first capital letter
    typeName = getRealMetaType(typeName);
    fieldName = fieldName[0].toLowerCase() + fieldName.slice(1); // no first capital letter
    const fields = getFieldsByTypeName(typeName, structure);
    const fieldsIds = getFieldsIdsByTypeName(typeName, structure);
    return fieldsIds[fields.indexOf(fieldName)];
};

export const getMetaKeyByName = (typeName, structure) => {
    typeName = typeName[0].toLowerCase() + typeName.slice(1); // no first capital letter
    typeName = getRealMetaType(typeName);
    return structure.find(metaType => metaType.name === typeName).id;
};

export const getTypeNameByMetaKey = (metaKey, structure) => {
    return structure.find(metaType => metaType.id === metaKey).name;
};

export const getMetaTypeName = objOrId => {
    let obj = isObject(objOrId) ? objOrId : getObjectById(objOrId);
    return getTypeNameByMetaKey(obj.entity.metaTableKey, getState().data.dataStructure);
};

// export const pad = n => n < 10 ? '0' + n : n;

// export const convertDate = date => '' + Date.parse(date) / 1000 * 1000;
export const filterCollectionByParent = (collection, parent) => {
    let parentId = isObject(parent) ? getId(parent) : parent;
    return collection.filter(item => getParentId(item) === parentId);
};

export const getParent = obj => getObjectById(getParentId(obj));

export const getParentsIdArray = obj => {
    let id;
    if (typeof obj === 'string') {
        id = obj;
        obj = getObjectById(obj);
    } else {
        id = getId(obj);
    }
    const ids = [];
    if (obj) {
        const parentField = obj.entity.record.find(field => field.name === "parentId");
        if (parentField) {
            const parentId = getParentId(obj);
            ids.push(parentId);
            if (parentId !== id) {
                const childrenIds = getParentsIdArray(parentId);
                ids.push(...childrenIds);
            }
        }
        const archivedField = obj.entity.record.find(field => field.name === "archived");
        if (archivedField) {
            const archived = getValue(obj, 'archived') === 'true' ? 'archived' : 'current';
            ids.push(archived);
        }
        const assetTypeField = obj.entity.record.find(field => field.name === "assetType");
        if (assetTypeField) {
            const assetType = getValue(obj, 'assetType');
            ids.push(assetType);
        }
    }
    return ids;
};

export const getMaxFieldValue = (collection, field) => {
    let maxValue = 0;
    collection.forEach(item => {
        const value = Number(getValue(item, field));
        if (value > maxValue) maxValue = value;
    });
    return maxValue;
};

export const getMaxFieldValueObj = (collection, field) => {
    let maxFieldValueObj = null;
    let maxValue = 0;
    collection.forEach(item => {
        const value = Number(getValue(item, field));
        if (value > maxValue) {
            maxValue = value;
            maxFieldValueObj = item;
        }
    });
    return maxFieldValueObj;
};

export const getMinFieldValueObj = (collection, field) => {
    let minFieldValueObj = collection[0];
    let minValue = Number(getValue(collection[0], field));
    collection.slice(1).forEach(item => {
        const value = Number(getValue(item, field));
        if (value < minValue) {
            minValue = value;
            minFieldValueObj = item;
        }
    });
    return minFieldValueObj;
};

export const getMinFieldValue = (collection, field) => {
    let minValue = Number(getValue(collection[0], field));
    collection.slice(1).forEach(item => {
        const value = Number(getValue(item, field));
        if (value < minValue) {
            minValue = value;
        }
    });
    return minValue;
};

export const getSequenceFieldName = metaTypeNameOrObject => {
    const structure = store.getState().data.dataStructure;
    const metaTypeName = isObject(metaTypeNameOrObject) ?
        getMetaTypeName(metaTypeNameOrObject, structure) : metaTypeNameOrObject;
    return metaTypeName === 'annotation' ? 'no' : 'sequence';
};

export const getSequence = obj => {
    const sequenceField = getSequenceFieldName(obj);
    return getValue(obj, sequenceField);
};

export const sortCollection = (collection = [], sortField = 'sequence') => {
    if (collection && collection.length) {
        return collection.sort((a, b) => {
            const sequenceA = getValue(a, sortField);
            const sequenceB = getValue(b, sortField);
            if (sequenceA > sequenceB) return 1;
            if (sequenceA < sequenceB) return -1;
            // in case sequence can't give us result
            const idA = getId(a);
            const idB = getId(b);
            if (idA > idB) return 1;
            if (idA < idB) return -1;
            // can't compare:
            return 0;
        });
    } else {
        return collection;
    }
};

export const filterCollectionByParentSorted = (collection, parent, sortField = 'sequence') =>
    sortCollection(filterCollectionByParent(collection, parent), sortField);

export const getSiteLocations = siteIdOrObj => {
    const data = getState().data;
    return filterCollectionByParentSorted(data.visualAssets, siteIdOrObj)
        .reduce(
            (prev, vi) => prev.concat(filterCollectionByParentSorted(data.locations, getId(vi))),
            []
        );
};

export const getSiblings = idOrObject => {
    const parentId = getParentId(idOrObject);
    const metaType = getMetaTypeName(idOrObject);
    return filterCollectionByParentSorted(getState().data[metaType + 's'], parentId);
};

export const findByField = (collection, field, value) => {
    return collection.find(item => item[field] === value);
};

export const findBySequence = (collection, sequence) =>
    findByField(collection, getSequenceFieldName(collection[0]), sequence);

export const clearLoadedEntitiesFromUnnecessaryNumbers = collection =>
    collection.filter(annotation => isObject(annotation));


export const getCellDefaultValueByHeaderColumn = header => {
    let val;
    switch (header.type) {
        case 'INT':
            val = 0;
            break;
        case 'DBL':
            const precision = header.precision ? header.precision : 7;
            const scale = header.scale ? header.scale : 3;
            val = '0'.repeat(precision - scale) + '.' +
                '0'.repeat(scale);
            break;
        case 'LNG':
            val = 0;
            break;
        case 'STR':
            val = ``;
            break;
        default:
            val = '';
    }
    return val;
};

export const checkAndRepairIDB = () => {
    idb.open('api', 3, upgradeDB => {
        // Note: we don't use 'break' in this switch statement,
        // the fall-through behaviour is what we want.
        switch (upgradeDB.oldVersion) {
            case 0:
                upgradeDB.createObjectStore('api', { autoIncrement: true });
            case 1:
                upgradeDB.createObjectStore('images_upload', { autoIncrement: true });
            case 2:
                upgradeDB.createObjectStore('ids_update', { autoIncrement: true });
        }
    })
        .then(db => {
            // console.log("DB opened!", db);
            return db;
        })
        .catch(e => {
            console.error("IDB problem detected, deleting db", e);
            return idb.delete('api');
        });
};

export const openDB = () => {
    checkAndRepairIDB();
    const db = idb.open('api', 3, upgradeDB => {
        // Note: we don't use 'break' in this switch statement,
        // the fall-through behaviour is what we want.
        switch (upgradeDB.oldVersion) {
            case 0:
                upgradeDB.createObjectStore('api', { autoIncrement: true });
            case 1:
                upgradeDB.createObjectStore('images_upload', { autoIncrement: true });
            case 2:
                upgradeDB.createObjectStore('ids_update', { autoIncrement: true });
        }
    })
        .then(db => {
            // console.log("DB opened!", db);
            return db;
        })
        .catch(e => {
            console.error("Can't open db", e);
            return null;
        });
    return db;
};

export const getChildren = parent => {
    const parentId = getId(parent);
    const children = [];
    ['sites', /*'photos', 'maps', 'drawings', 'plans', */'visualAssets', 'locations', 'measurements',
        'observations', 'images', 'inlineMeasurements']
        .forEach(objType => {
            const objs = getState().data[objType];
            objs.forEach(obj => {
                if (getParentId(obj).toString() === parentId.toString()) {
                    children.push(obj);
                }
            });
        });
    return children;
};
