import React, { Component } from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import FlexView from 'react-flexview';

const styles = {};

class DeleteModal extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            dialogOpen: false,
            content: ''
        };
    }

    componentWillMount () {
        if (this.props.isOpen !== undefined) this.setState({ dialogOpen: this.props.isOpen });
        const content = this.props.content ?
            this.props.content : `Are you sure you want to delete this ${this.props.objectType}?`;
        this.setState({ content });
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (nextProps.isOpen !== undefined) this.setState({ dialogOpen: nextProps.isOpen });
        const content = nextProps.content ?
            nextProps.content : `Are you sure you want to delete this ${nextProps.objectType}?`;
        this.setState({ content });
    }

    handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        } else {
            this.setState({ dialogOpen: false });
        }
    };

    handleOpen = () => {
        if (this.props.onOpen) {
            this.props.onOpen();
        } else {
            this.setState({ dialogOpen: true });
        }
    };

    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.setState({ dialogOpen: false });
        }
    };

    onConfirm = () => {
        if (this.props.onConfirm) {
            this.props.onConfirm();
        } else {
            this.props.deleteCallback(this.props.metaType, this.props.pk);
            this.setState({ dialogOpen: false });
        }
    };

    render () {
        return (
            <div>
                <Button
                    className="button"
                    variant="contained"
                    size={this.props.size}
                    onClick={this.handleOpen}
                >
                    Delete
                </Button>
                <Paper width="500px" style={{ margin: '20%', position: 'absolute' }} elevation={5}>
                    <Dialog
                        onClose={this.handleClose}
                        open={this.state.dialogOpen}
                    >
                        <FlexView className="obsRowThin" width="500px" />
                        <FlexView className="obsRowColour" width="500px" >
                        Delete Confirmation
                        </FlexView>
                        <FlexView className="obsRowThinInvis" width="500px" />
                        <FlexView className="row" width="500px" >
                            <FlexView className="cell-8"/>
                            <DialogContent
                                style={{ padding: 0 }}
                            >
                                {this.state.content}
                            </DialogContent>
                            <FlexView className="obsCell-5"/>
                        </FlexView>
                        <FlexView className="row" width="500px" >
                            <FlexView className="obsCell-5"/>
                            <FlexView className="obsCell-90Right">
                                <DialogActions
                                    style={{ padding: 0, margin: 0, justifyContent: 'flex-end' }}
                                >
                                    <Button
                                        onClick={this.onCancel}
                                    >
                                        {this.props.cancelButtonText}
                                    </Button>
                                    <Button
                                        className="button"
                                        variant="contained"
                                        onClick={this.onConfirm}
                                    >
                                        {this.props.confirmButtonText}
                                    </Button>
                                </DialogActions>
                            </FlexView>
                        </FlexView>
                        <FlexView className="obsRowThinInvis"/>
                    </Dialog>
                </Paper>
            </div>
        );
    }
}

DeleteModal.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.string,
    objectType: PropTypes.string,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    deleteCallback: requiredIf(PropTypes.func, props => !props.onConfirm),
    metaType: requiredIf(PropTypes.string, props => !props.onConfirm),
    pk: requiredIf(PropTypes.string, props => !props.onConfirm),
    primary: PropTypes.bool,
    size: PropTypes.string,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string
};
DeleteModal.defaultProps = {
    title: 'Delete confirmation',
    objectType: 'object',
    primary: false,
    size: 'small',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel'
};

export default withStyles(styles)(DeleteModal);
