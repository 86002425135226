// import axios from 'axios';
import { set, keys, get, Store } from 'idb-keyval';
import { openDB } from "./data";
// import { DATA_IMAGE_PROCESSED } from "../reducers/data";

const customStore = new Store('inspectIt', 'images');

/*
export const uploadFile = (file, fileName) => {
    const formData = new FormData();
    formData.append('myFile', file, fileName);
    // Add the users name from the session object (session.name) as a FormData variable
    // formData.append('name', session.name);
    const config = {
        onUploadProgress: progressEvent => {
            console.log(Math.round(progressEvent.loaded / (progressEvent.total + 0.00001) * 100) + '%');
        }
    };
    axios.post('http://localhost:3001/upload/', formData, config) //tod: change where to upload to
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error.response);
        });
};
*/

/*
function dataURItoBlob (dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
    } else {
        byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}
*/

/*
export const uploadImageContent = (data, fileName) => {
    const formData = new FormData();
    const blob = dataURItoBlob(data);
    formData.append('myFile', blob, fileName);
    // Add the users name from the session object (session.name) as a FormData variable
    // formData.append('name', session.name);
    const config = {
        onUploadProgress: progressEvent => {
            console.log(Math.round(progressEvent.loaded / (progressEvent.total + 0.00001) * 100) + '%');
        }
    };
    axios.post('http://localhost:3001/upload/', formData, config) // tod: update url
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error.response);
        });
};
*/

export const readImage = file => new Promise((resolve, reject) => {
    /* global window */
    /**
     * @type {FileReader} reader
     */
    const reader = new window.FileReader();
    reader.onload = event => {
        resolve(event.target.result);
    };
    reader.onerror = err => {
        reject(err);
    };
    reader.readAsDataURL(file);
});

export const saveImageToIndexedDB = (file, fileName) => new Promise((resolve, reject) => {
    readImage(file).then(image => {
        set(fileName, image, customStore)
            .then(() => resolve('xxx'))
            .catch(e => reject(e));
    });
});

const checkFileName = fileName => {
    return keys(customStore).then(
        keys => keys.includes(fileName)
    );
};

export const createFileName = async (fileName, parentId) => {
    fileName = `${parentId}_${fileName}`;
    let num = 0;
    while (await checkFileName(fileName)) {
        const replace = num ? "_" + (num - 1) + "(\.[^/.]+)$" : "(\.[^/.]+)$";
        let re = new RegExp(replace);
        fileName = fileName.replace(re, '_' + num + '$1');
        num++;
    }
    return fileName;
};

export const getImageFromIDB = filename => {
    return get(filename, customStore);
};

export const addUploadTask = parameters => {
    // dispatch(processAPITask(taskType, parameters)); // instead of this call there will be queueing the task
    openDB().then(db => {
        const tx = db.transaction('images_upload', 'readwrite');
        // noinspection JSIgnoredPromiseFromCall
        tx.objectStore('images_upload').put(parameters);
        return tx.complete;
    }).then(() => console.log('Added api task to the idb: ', parameters));
    global.registration.active.postMessage({
        action: 'sync'
    });
};
