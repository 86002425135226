export const DATA_LOAD_REQUESTED = 'DATA_LOAD_REQUESTED';
export const DATA_LOAD_SUCCESS = 'DATA_LOAD_SUCCESS';
export const DATA_LOAD_FAILURE = 'DATA_LOAD_FAILURE';
export const DATA_PARSED = 'DATA_PARSED';
export const DATA_IMAGE_PROCESSED = 'DATA_IMAGE_PROCESSED';

export const DATA_OBJECTS_UPDATE = 'DATA_OBJECTS_UPDATE';

export const DATA_STRUCTURE_LOAD_REQUESTED = 'DATA_STRUCTURE_LOAD_REQUESTED';
export const DATA_STRUCTURE_LOAD_SUCCESS = 'DATA_STRUCTURE_LOAD_SUCCESS';
export const DATA_STRUCTURE_LOAD_FAILURE = 'DATA_STRUCTURE_LOAD_FAILURE';

export const DATA_CONSULTANTS_REQUEST = 'DATA_CONSULTANTS_REQUEST';
export const DATA_CONSULTANTS_SUCCESS = 'DATA_CONSULTANTS_SUCCESS';
export const DATA_CONSULTANTS_FAILURE = 'DATA_CONSULTANTS_FAILURE';

export const DATA_CSVIMPORTMAPS_REQUEST = 'DATA_CSVIMPORTMAPS_REQUEST';
export const DATA_CSVIMPORTMAPS_SUCCESS = 'DATA_CSVIMPORTMAPS_SUCCESS';
export const DATA_CSVIMPORTMAPS_FAILURE = 'DATA_CSVIMPORTMAPS_FAILURE';

export const CREATE_CSVIMPORTMAPS_REQUEST = 'CREATE_CSVIMPORTMAPS_REQUEST';
export const CREATE_CSVIMPORTMAPS_SUCCESS = 'CREATE_CSVIMPORTMAPS_SUCCESS';
export const CREATE_CSVIMPORTMAPS_FAILURE = 'CREATE_CSVIMPORTMAPS_FAILURE';

export const LOAD_ANNOTATION_TYPES_REQUESTED = 'LOAD_ANNOTATION_TYPES_REQUESTED';
export const LOAD_ANNOTATION_TYPES_SUCCESS = 'LOAD_ANNOTATION_TYPES_SUCCESS';
export const LOAD_ANNOTATION_TYPES_FAILURE = 'LOAD_ANNOTATION_TYPES_FAILURE';

export const EXPORT_PROJECT_TO_WORD_REQUESTED = 'EXPORT_PROJECT_TO_WORD_REQUESTED';
export const EXPORT_PROJECT_TO_WORD_SUCCESS = 'EXPORT_PROJECT_TO_WORD_SUCCESS';
export const EXPORT_PROJECT_TO_WORD_FAILURE = 'EXPORT_PROJECT_TO_WORD_FAILURE';

export const LOCAL_IDS_UPDATE_STARTED = 'LOCAL_IDS_UPDATE_STARTED';
export const LOCAL_IDS_UPDATE_ENDED = 'LOCAL_IDS_UPDATE_ENDED';

const initialState = {
    data: {},
    dataStructure: [],
    dataLoadingInProcess: false,
    dataStructureLoadingInProcess: false,
    error: '',
    dataLoadedTimestamp: 0,
    projects: [],
    sites: [],
    plans: [],
    drawings: [],
    maps: [],
    photos: [],
    locations: [],
    observations: [],
    measurements: [],
    images: [],
    annotations: [],
    inlineMeasurements: [],
    inlineMeasurementRows: [],
    inlineMeasurementRowColumns: [],
    consultants: [],
    annotationTypesLoading: false,
    annotationTypes: [],
    csvimports: [],
    runningIdsUpdate: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DATA_STRUCTURE_LOAD_REQUESTED:
            return {
                ...state,
                dataStructure: [],
                dataStructureLoadingInProcess: true,
                error: ''
            };

        case DATA_STRUCTURE_LOAD_SUCCESS:
            return {
                ...state,
                dataStructureLoadingInProcess: false,
                dataStructure: action.payload.dataStructure
            };

        case DATA_STRUCTURE_LOAD_FAILURE:
            return {
                ...state,
                dataStructureLoadingInProcess: false,
                dataStructure: [],
                error: action.payload.error
            };

        case DATA_LOAD_REQUESTED:
            return {
                ...state,
                dataLoadingInProcess: true,
                data: {},
                error: '',
                dataLoadedTimestamp: 0,
                projects: [],
                sites: [],
                plans: [],
                drawings: [],
                maps: [],
                locations: [],
                observations: [],
                measurements: [],
                images: [],
                annotations: []
            };

        case DATA_LOAD_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                error: '',
                dataLoadedTimestamp: Date.now()
            };

        case DATA_LOAD_FAILURE:
            return {
                ...state,
                dataLoadingInProcess: false,
                data: {},
                error: action.payload.error,
                dataLoadedTimestamp: 0,
                projects: [],
                sites: [],
                plans: [],
                drawings: [],
                maps: [],
                locations: [],
                observations: [],
                measurements: [],
                images: [],
                annotations: []
            };

        case DATA_OBJECTS_UPDATE:
            return {
                ...state,
                [`${action.payload.type}`]: action.payload.elements
            };

        case DATA_PARSED:
            return {
                ...state,
                dataLoadingInProcess: false
            };

        case DATA_CONSULTANTS_REQUEST:
            return {
                ...state,
                consultants: []
            };

        case DATA_CONSULTANTS_SUCCESS:
            return {
                ...state,
                consultants: action.payload.consultants
            };

        case DATA_CONSULTANTS_FAILURE:
            return {
                ...state,
                consultants: [],
                error: action.payload.error
            };

        case DATA_CSVIMPORTMAPS_REQUEST:
            return {
                ...state,
                csvimports: []
            };

        case DATA_CSVIMPORTMAPS_SUCCESS:
            return {
                ...state,
                csvimports: action.payload.csvimportmaps
            };

        case DATA_CSVIMPORTMAPS_FAILURE:
            return {
                ...state,
                csvimports: [],
                error: action.payload.error
            };

        case LOAD_ANNOTATION_TYPES_REQUESTED:
            return {
                ...state,
                annotationTypes: [],
                error: '',
                annotationTypesLoading: true
            };
        case LOAD_ANNOTATION_TYPES_SUCCESS:
            return {
                ...state,
                annotationTypes: action.payload.data,
                error: '',
                annotationTypesLoading: false
            };
        case LOAD_ANNOTATION_TYPES_FAILURE:
            return {
                ...state,
                annotationTypes: [],
                error: action.payload.error,
                annotationTypesLoading: false
            };

        case EXPORT_PROJECT_TO_WORD_REQUESTED:
            return {
                ...state,
                errorExportToWord: '',
                exportToWordLoading: true
            };
        case EXPORT_PROJECT_TO_WORD_SUCCESS:
            return {
                ...state,
                exportToWordLoading: false
            };
        case EXPORT_PROJECT_TO_WORD_FAILURE:
            return {
                ...state,
                errorExportToWord: action.payload.error,
                exportToWordLoading: false
            };
        case LOCAL_IDS_UPDATE_STARTED:
            return {
                ...state,
                runningIdsUpdate: true
            };
        case LOCAL_IDS_UPDATE_ENDED:
            return {
                ...state,
                runningIdsUpdate: false
            };
        default:
            return state;
    }
};
