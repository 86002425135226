import React from 'react';
import PropTypes from 'prop-types';
import BlockTools from './blockTools';
import { getId } from "../../../../../utils/data";

const Block9 = props => {
    return !props.locked ? (
        <div className={'block9'}>
            <BlockTools id={getId(props.block)}/>
            <div>placeholder</div>
        </div>
    ) : (
        <div className={'block9'}>
            placeholder
        </div>
    );
};

Block9.propTypes = {
    block: PropTypes.shape().isRequired,
    locked: PropTypes.bool.isRequired
};
export default Block9;
