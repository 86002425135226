export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGGED_OUT = 'LOGGED_OUT';
export const HOST_UPDATE = 'HOST_UPDATE';

const initialState = {
    loggingInInProcess: false,
    username: '',
    error: '',
    host: '',
    sessionExpired: true,
    loginTimeStamp: 0,
    session: {},
    loggedInInThisSession: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUESTED:
            return {
                ...state,
                loggingInInProcess: true,
                username: '',
                error: '',
                session: {}
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                loggingInInProcess: false,
                username: action.payload.username,
                error: '',
                loginTimeStamp: Date.now(),
                session: action.payload.session,
                loggedInInThisSession: true
            };

        case LOGIN_FAILURE:
            return {
                ...state,
                loggingInInProcess: false,
                username: '',
                error: action.payload.error,
                loginTimeStamp: 0,
                session: {}
            };

        case LOGGED_OUT:
            return {
                ...state,
                loggingInInProcess: false,
                username: '',
                error: '',
                sessionExpired: false,
                loginTimeStamp: 0,
                session: {}
            };

        case HOST_UPDATE:
            return {
                ...state,
                host: action.payload.host
            };

        default:
            return state;
    }
};
