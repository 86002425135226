import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getId, getSequence, getValue } from "../../../../../utils/data";
import Input from '@material-ui/core/Input';
import HTMLEditor from "../../../../HTMLEditor";
import BlockTools from "./blockTools";

class Block2345 extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            value1: '',
            value2: ''
        };
    }

    componentWillMount () {
        this.parsePropsToState(this.props);
    }

    componentWillReceiveProps (nextProps, nextContext) {
        this.parsePropsToState(nextProps);
    }

    parsePropsToState = props => {
        const startBlock = this.props.block;
        const startSequence = getSequence(startBlock);

        const block2 =
            props.allBlocksInLayout.find(block => Number(getSequence(block)) === Number(startSequence) + 1);
        const block4 =
            props.allBlocksInLayout.find(block => Number(getSequence(block)) === Number(startSequence) + 3);

        const block20 =
            this.props.allBlocksInLayout.find(block => Number(getSequence(block)) === Number(startSequence) + 1);
        const block40 =
            this.props.allBlocksInLayout.find(block => Number(getSequence(block)) === Number(startSequence) + 3);

        const value1 = getValue(block2, 'val')
        const value2 = getValue(block4, 'val')

        const value10 = getValue(block20, 'val')
        const value20 = getValue(block40, 'val')

        value1 !== value10 && this.setState({ value1 });
        value2 !== value20 && this.setState({ value2 });
    };

    updateBlockField = (blockId, fields) => {
        this.props.updateCallback('block', blockId, fields);
    };

    render () {
        const startBlock = this.props.block;
        const startSequence = getSequence(startBlock);
        const block2 =
            this.props.allBlocksInLayout.find(block => Number(getSequence(block)) === Number(startSequence) + 1);
        const block3 =
            this.props.allBlocksInLayout.find(block => Number(getSequence(block)) === Number(startSequence) + 2);
        const block4 =
            this.props.allBlocksInLayout.find(block => Number(getSequence(block)) === Number(startSequence) + 3);
        // language=HTML
        return (
            <div className={'block2'}>
                {this.props.locked ? null :
                    <BlockTools
                        id={getId(this.props.block)}
                    />
                }
                <div className={'block2Row'}>
                    <div className={'block21'}>
                        {getValue(startBlock, 'val')}
                    </div>
                    <div className={'block22'}>
                        <Input
                            value={this.state.value1}
                            onChange={event => {
                                this.setState({ value1: event.target.value });
                            }}
                            onBlur={() => {
                                this.updateBlockField(getId(block2), { val: this.state.value1 });
                            }}
                            placeholder={'Enter data here'}
                            className={'input'}
                        />
                    </div>
                </div>
                <div className={'block23'}>
                    {getValue(block3, 'val')}
                </div>
                <div className={'block24'}>
                    <HTMLEditor
                        value={this.state.value2}
                        onBlurCallback={val => {
                            this.updateBlockField(getId(block4), { val });
                        }}
                    />
                </div>
            </div>
        );
    }
}

Block2345.propTypes = {
    block: PropTypes.shape().isRequired,
    allBlocksInLayout: PropTypes.array.isRequired,
    locked: PropTypes.bool.isRequired,
    updateCallback: PropTypes.func.isRequired
};

export default Block2345;
