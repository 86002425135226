import React from 'react';
import * as PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import AddItemLabel from './AddItemLabel';
import ProjectCategory from './TreeComponents/ProjectCategory';
import TouchBackend from 'react-dnd-touch-backend';
import DragLayer from './DragLayer';
import FlexView from 'react-flexview';
import { Fader, HCenter } from "../../util";
import { DragDropContext } from 'react-dnd';
import ComponentView from './View/ComponentView';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateDataFromLocalCache } from '../../actions/data';
import { logOut } from '../../actions/auth';
import './index.css';
// import ImportDelete from './ImportDelete';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/icons/Menu';
import MenuOpen from '@material-ui/icons/MenuOpen';
import Button from '@material-ui/core/Button';


class TreeNav extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            height: 1000,
            menuOpen: true
        };
        this.viewRef = React.createRef();
    }

    componentWillMount () {
        const height = window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;
        this.setState({ height });
        window.addEventListener("resize", this.onResize);
    }

    componentDidMount () {
        // this.props.dataLoad();
        // !global.session && this.props.updateDataFromLocalCache();
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        const height = window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;
        this.setState({ height });
    };

    height = 1000;

    toggleMenu = () => {
        this.setState(state => ({ menuOpen: !state.menuOpen }));
    };

    render () {
        const InfracorrLogo = require('../../Assets/Infracorr_logo_1080x361.svg');
        const loading = (
            <Fader style={{ justifyContent: "center" }}>
                <HCenter style={{ justifyContent: "center" }}>
                    <FlexView
                        className="container"
                        style={{ width: '100%', height: '100%', justifyContent: 'center' }}
                    >
                        <Paper
                            style={{
                                width: '45%',
                                margin: '27%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 20
                            }}
                            elevation={5}
                        >
                            <CircularProgress size={50}/>
                            <div>Data is being loaded</div>
                        </Paper>
                    </FlexView>
                </HCenter>
            </Fader>
        );

        const ready = (
            <div className="allWrap" style={{
                height: '100%',
                gridTemplateColumns: this.state.menuOpen ? '[menu] 270px [inspect] auto' : '[inspect] auto'
            }}>
                {this.state.menuOpen && (
                    <div className="leftColumn" style={{ height: this.state.height }}>
                        <div className={"dnd"}>
                            <DragLayer snapToGrid={false}/>
                            {/* objects to drag from */}
                            <div className={'leftTop'}>
                                <div className={'menuSwitchOpen'} onClick={this.toggleMenu}><MenuOpen/></div>
                                <div className="heading">Drag into the tree below to add item</div>
                            </div>
                            {['Project', 'Site', 'Location', 'Map', 'Plan', 'Drawing',
                                'Photo', 'Observation', 'Measurement'].map(
                                item => (
                                    <AddItemLabel
                                        key={item + 'AddLabel'}
                                        label={item === 'Photo' ? 'Layout Photo' : item} type={item}
                                    />)
                            )}
                        </div>
                        {/* No longer using this, but may need it?
                    <div style={{height: '30px'}}>
                        <ImportDelete/>
                    </div>
                    */}
                        {/*  here the main tree begins */}
                        <List
                            className="listItem listItems"
                            style={{
                                overflowY: 'scroll',
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                paddingRight: 30
                            }}
                        >
                            {
                                // there are 2 categories of projects
                                ['current', 'archived'].map(
                                    category => (
                                        <ProjectCategory
                                            key={`${category}Projects`}
                                            projects={this.props.data[category]}
                                            label={category[0].toUpperCase() + category.substr(1) + ' Projects'}
                                            archived={category === 'archived'}
                                        />
                                    )
                                )
                            }
                        </List>
                    </div>
                )}
                <div className="rightColumn" style={{ height: this.state.height }}>
                    {!this.state.menuOpen && (
                        <div className={'menuSwitchClosed'} onClick={this.toggleMenu}>
                            <Menu/>
                        </div>
                    )}
                    <div className={"bannerRow"}>
                        <div className="banner">
                            <InfracorrLogo
                                style={{ alignSelf: 'center' }}
                                alt={"Infracorr"}
                                width={200}
                            />
                        </div>
                        <div className={"logoutContainer"}>
                            <Button variant={"contained"} size={"medium"} onClick={this.props.logOut}>
                                Log out
                            </Button>
                        </div>
                    </div>
                    <div className="view" ref={this.viewRef}>
                        {/* the main view component, we pass clicked element and its content from the state */}
                        <ComponentView viewRef={this.viewRef}/>
                    </div>
                </div>
            </div>
        );
        return this.props.isLoading ? loading : ready;
    }
}

TreeNav.propTypes = {
    data: PropTypes.shape({
        current: PropTypes.array,
        archived: PropTypes.array
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    updateDataFromLocalCache: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    logOut: PropTypes.func.isRequired
};
TreeNav.defaultProps = {};

// redux settings
const mapStateToProps = state => ({
    data: state.data.data,
    isLoading: state.data.dataLoadingInProcess
});

const mapDispatchToProps = dispatch => bindActionCreators({ updateDataFromLocalCache, logOut }, dispatch);

// eslint-disable-next-line new-cap
export default DragDropContext(TouchBackend({ enableMouseEvents: true }))(
    connect(mapStateToProps, mapDispatchToProps)(TreeNav)
);
