import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlexView from 'react-flexview';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { AnnotatedObject } from '../../AnnotatedObject';
import entity from '../../../composition/entity';
import { getParentId } from "../../../utils/data";
import FilesImportDialog from './ImportDnDModal';
import DeleteButton from './DeleteModal';
import HTMLEditor from "../../HTMLEditor";

const styles = theme => ({
    root: {
        margin: theme.spacing(3),
        width: "90%"
    },
    heading: {
        fontSize: 28,
        fontVariant: 'small-caps'
    },
    subText: {
        fontSize: 12
    },
    subHeading: {
        fontSize: 13
    },
    subHeadingBold: {
        fontSize: 13
    },
    button: {
        margin: theme.spacing()
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    }
});

class Drawing extends Component {
    _importFile = file => {
        this.props.importImageToVisualAsset(file, this.props.id);
    };

    _toggleExport = () => {
        const ignoreExport = this.props.ignore_export === 'false' ? 'true' : 'false';
        this.props.saveField('ignore_export', ignoreExport);
    };

    render () {
        const { classes } = this.props;
        let locations = this.props.data.locations.filter(location => getParentId(location) === this.props.id);
        const image = this.props.filename;
        const importPhotoButton = image ? null : (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                <FilesImportDialog
                    importFilesCallback={this._importFile}
                    buttonText={'Import Image'}
                />
            </div>
        );
        return (
            <div>
                <FlexView className="container">
                    <Paper className={classNames(classes.root, "observation")} elevation={4}>
                        <FlexView className="obsRowThin"/>
                        <FlexView className="obsRowColour" style={{ justifyContent: 'space-between' }}>
                            <div>Drawing</div>
                            <div style={{ marginRight: 20, marginTop: 10 }}>
                                <DeleteButton
                                    pk={this.props.id}
                                    metaType={'drawing'}
                                    deleteCallback={this.props.deleteElement}
                                    objectType={'drawing'}
                                />
                            </div>
                        </FlexView>
                        <FlexView className="rowThin"/>
                        <FlexView className="row">
                            <FlexView className="obsCell-5"/>
                            <FlexView className="cell-20">
                                Name
                            </FlexView>
                            <FlexView className="cell-68">
                                <TextField
                                    id="name"
                                    fullWidth
                                    label="Name"
                                    value={this.props.name}
                                    onChange={ev => {
                                        this.props.onChangeFieldValue({ name: ev.target.value });
                                    }}
                                    onBlur={() => {
                                        this.props.saveField('name');
                                    }}
                                />
                            </FlexView>
                            <FlexView className="obsCell-5"/>
                        </FlexView>
                        <FlexView className="row">
                            <FlexView className="obsCell-5"/>
                            <FlexView className="cell-20">
                                Filename
                            </FlexView>
                            <FlexView className="cell-68">
                                <TextField
                                    id="filename"
                                    fullWidth
                                    label="Filename"
                                    value={this.props.filename}
                                    onChange={ev => {
                                        this.props.onChangeFieldValue({ filename: ev.target.value });
                                    }}
                                    onBlur={() => {
                                        this.props.saveField('filename');
                                    }}
                                />
                            </FlexView>
                            <FlexView className="obsCell-5"/>
                        </FlexView>
                        <FlexView className="editorRow">
                            <FlexView className="cell-5Auto"/>
                            <FlexView className="cell-20Auto">
                                Comment
                            </FlexView>
                            <FlexView className="editorCell-68">
                                <HTMLEditor
                                    value={this.props.description}
                                    onBlurCallback={description => this.props.saveField('description', description)}
                                />
                            </FlexView>
                        </FlexView>
                        <FlexView className="row">
                            <FlexView className="obsCell-5"/>
                            <FlexView className="obsCell-100Auto">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            // TODO: check real value type being string or boolean for fetched field
                                            checked={this.props.ignore_export === 'true' ||
                                            this.props.ignore_export === true}
                                            onChange={this._toggleExport}
                                        />
                                    }
                                    label={this.props.ignore_export === 'true' ?
                                        'Excluded from export' : 'Included in export'}
                                />
                            </FlexView>
                            <FlexView className="obsCell-5"/>
                        </FlexView>
                        <FlexView className="rowThin"/>
                    </Paper>
                    <Paper className={classNames(classes.root, "observation")} elevation={4}>
                        <FlexView className="obsRowThin"/>
                        <FlexView className="obsRowColour">
                            Drawing Locations
                        </FlexView>
                        <FlexView className="rowAuto" style={{ margin: 0 }}>
                            <div style={{ flex: 1 }}>
                                <AnnotatedObject
                                    image={image ? image : 'blank'}
                                    annotations={JSON.parse(JSON.stringify(locations))}
                                    nodeType={'drawing'}
                                    nodeId={this.props.id}
                                    updateElement={this.props.updateElement}
                                    deleteElement={this.props.deleteElement}
                                    addNewElement={this.props.addNewElement}
                                    dataStructure={this.props.dataStructure}
                                    addLocation={this.props.addLocation}
                                    nextNumber={this.props.getNextLocationNumber(this.props.id)}
                                    annotationTypes={['location']}
                                />
                            </div>
                        </FlexView>
                        <FlexView className="rowAuto" style={{ margin: 0 }}>
                            {importPhotoButton}
                        </FlexView>
                    </Paper>
                </FlexView>
            </div>
        );
    }
}


Drawing.propTypes = {
    // object props from composition
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    assetType: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    ignore_export: PropTypes.string.isRequired,
    // composition
    data: PropTypes.shape().isRequired,
    dataStructure: PropTypes.array.isRequired,
    onChangeFieldValue: PropTypes.func.isRequired,
    saveField: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    importImageToVisualAsset: PropTypes.func.isRequired,
    // composition redux actions
    addNewElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    getNextLocationNumber: PropTypes.func.isRequired,
    addLocation: PropTypes.func.isRequired,
    // style
    classes: PropTypes.shape().isRequired
};
Drawing.defaultProps = {};

export default entity(withStyles(styles)(Drawing), 'drawing');
