import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { getId, getMaxFieldValue } from "../../../../../utils/data";
import Block from '../Blocks';
import AddTableBlockModalDialog from './addTableBlockDialog';


class OneColumn extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            addTableBlockModalOpen: false
        };
    }

    addTableBlockModalOpen = () => {
        this.setState({ addTableBlockModalOpen: true });
    };

    closeTableBlockModal = () => {
        this.setState({ addTableBlockModalOpen: false });
    };

    addTableBlock = (rows, columns) => {
        for (let i = 0; i < columns; i++) {
            const headerRowCol = this.props.addNewElement('row', this.props.id, { header: 'header_text', col_no: i });
            for (let r = 0; r < rows; r++) {
                this.props.addNewElement('column', getId(headerRowCol), { val: `cell ${r} ${i}` });
            }
        }
    };

    render () {
        const style = this.props.isOver ? {
            backgroundColor: 'rgb(232, 245, 233)'
        } : {};
        const blocks = this.props.blocks.map(block => (
            <Block
                key={getId(block)} block={block} locked={this.props.locked} allBlocksInLayout={this.props.blocks}
                updateCallback={this.props.updateCallback}
            />));
        return this.props.connectDropTarget(
            <div className={this.props.locked ? 'layout1locked' : 'layout1'} style={style}>
                <AddTableBlockModalDialog
                    open={this.state.addTableBlockModalOpen}
                    onCloseCallback={this.closeTableBlockModal}
                    addCallback={this.addTableBlock}
                />
                {blocks && blocks.length ? blocks :
                    <div className={'dropHere'}>{this.props.locked ? 'No blocks' : 'Drop blocks here'}</div>}
            </div>
        );
    }
}

OneColumn.propTypes = {
    id: PropTypes.string.isRequired,
    addNewElement: PropTypes.func.isRequired,
    locked: PropTypes.bool.isRequired,
    updateCallback: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    blocks: PropTypes.array.isRequired,
    connectDropTarget: PropTypes.func.isRequired
};

//below are the settings for dnd target
const targetSpecs = {
    drop (props, monitor, component) {
        const item = monitor.getItem();
        const sequence = getMaxFieldValue(props.blocks, 'sequence') + 1;
        if (item.dragType === 'Block 3') {
            component.addTableBlockModalOpen();
        } else {
            props.addNewElement('block', props.id, { type: item.dragType, sequence });
        }
        console.info('Dropped a ' + item.dragType + 'to layout id ' + props.id);
        return {};
    }
};

const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});

// eslint-disable-next-line new-cap
export default DropTarget(['Block 1', 'Block 2', 'Block 3', 'Block 4'], targetSpecs, collect)(OneColumn);
