import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import {
    getFieldsByTypeName,
    getId,
    getValue,
    copyObject,
    getTypeNameByMetaKey,
    getMetaTypeName
    // getObjectById, getParentId
} from "../utils/data";
import {
    dataLoad,
    deleteElement,
    updateElement,
    addNewElement,
    getNextLocationNumber,
    addLocation,
    exportProjectToWord
} from "../actions/data";
import { saveImageToIndexedDB, createFileName, addUploadTask, readImage } from "../utils/image";

const Entity = (Composition, entityMetaType) => class _Entity extends Component {
    static propTypes = {
        object: PropTypes.shape(),
        //redux props
        dataStructure: PropTypes.array.isRequired,
        data: PropTypes.shape().isRequired,
        //redux actions
        dataLoad: PropTypes.func.isRequired,
        addNewElement: PropTypes.func.isRequired,
        updateElement: PropTypes.func.isRequired,
        deleteElement: PropTypes.func.isRequired
    };
    static defaultProps = {};

    constructor (props, context) {
        super(props, context);
        this.fields = getFieldsByTypeName(entityMetaType, props.dataStructure);
        // initializing state
        // const state = {};
        // this.fields.forEach(field => {
        //     state[field] = null;
        // });
        this.state = {};
        this._onChangeFieldValue = this._onChangeFieldValue.bind(this);
        this._saveField = this._saveField.bind(this);
        this._delete = this._delete.bind(this);
    }

    componentWillMount () {
        this._parseObjectToState(this.props.object, true);
    }

    componentWillReceiveProps (nextProps, nextContext) {
        this._parseObjectToState(nextProps.object);
    }

    _parseObjectToState = (obj, initial = false) => {
        // const id = getId(object);
        // const name = getName(object);
        obj = copyObject(obj);
        const self = this;
        const oldObj = copyObject(self.props.object);
        this.fields.forEach(field => {
            if (initial || getValue(oldObj, field) !== getValue(obj, field)) {
                self.setState({ [field]: getValue(obj, field) });
            }
        }, this);
        // console.log(getId(obj));
        // adding consultants field for project
        if (getMetaTypeName(obj, this.props.data.dataStructure) === 'project') {
            this.setState({ consultants: this.props.data.consultants });
        }
    };

    _onChangeFieldValue = (fieldObj) => {
        this.setState(() => fieldObj, () => {
            console.log('Entity state updated');
        });
        this.forceUpdate();
    };

    _saveField = (field, value = null) => {
        value = value ? value : this.state[field];
        this.props.updateElement(entityMetaType,
            getId(this.props.object), { [field]: value });
    };

    _delete = () => {
        this.props.deleteElement(entityMetaType, this.state.id);
    };

    _importImages = (files = [], parentId) => {
        files.forEach(file => {
            createFileName(file.name, parentId)
                .then(filename => {
                    // uploadFile(file, fileName);
                    readImage(file)
                        .then(data => {
                            addUploadTask({ filename, data });
                        });
                    saveImageToIndexedDB(file, filename)
                        .then(() => {
                            this.props.addNewElement('image', parentId, { filename });
                        });
                });
        });
    };

    _importImageToVisualAsset = (file, visualAssetId) => {
        let metaType = getTypeNameByMetaKey(
            this.props.object.entity.metaTableKey,
            this.props.dataStructure
        );
        if (metaType === 'visual_asset') metaType = getValue(this.props.object, 'assetType').toLowerCase();
        createFileName(file.name, visualAssetId)
            .then(filename => {
                readImage(file)
                    .then(data => {
                        addUploadTask({ filename, data });
                    });
                // uploadFile(file, filename);
                saveImageToIndexedDB(file, filename)
                    .then(() => {
                        this.props.updateElement(metaType, visualAssetId,
                            {
                                name: file.name.replace(/\.[^/.]+$/, ""),
                                filename: filename
                            });
                    });
            });
    };

    // _deleteElement = (typeName, pk) => {
    //     if (typeName === 'observation') {
    //         this.props.deleteObservation(pk);
    //     } else if (typeName === 'location') {
    //         this.props.deleteLocation(pk);
    //     } else if (visualAssets.includes(typeName)) {
    //         this.props.deleteVisualAsset(typeName, pk);
    //     } else {
    //         this.props.deleteElementAction(typeName, pk);
    //     }
    // };

    render () {
        return (
            <Composition
                onChangeFieldValue={this._onChangeFieldValue}
                saveField={this._saveField}
                delete={this._delete}
                importImages={this._importImages}
                importImageToVisualAsset={this._importImageToVisualAsset}
                {...this.state} // will add object fields as props to component
                {...this.props}
            />
        );
    }
};

// redux settings
const mapStateToProps = state => ({
    dataStructure: state.data.dataStructure,
    data: state.data
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addNewElement,
    updateElement,
    deleteElement,
    dataLoad,
    getNextLocationNumber,
    addLocation,
    exportProjectToWord
}, dispatch);

const composedHOC = compose(
    connect(mapStateToProps, mapDispatchToProps),
    Entity
);

export default composedHOC;
