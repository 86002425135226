import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { getId, getValue } from "../../../../../utils/data";
import BlockTools from "./blockTools";


class Block6 extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            value: 'Block 2 (type 6)'
        };
    }

    componentWillMount () {
        this.parsePropsToState(this.props);
    }

    componentWillReceiveProps (nextProps, nextContext) {
        this.parsePropsToState(nextProps);
    }

    parsePropsToState = props => {
        this.setState({ value: getValue(props.block, 'val') });
    };


    save = () => {
        this.props.updateCallback('block', getId(this.props.block), { val: this.state.value });
    };

    render () {
        return this.props.locked ? (
            <div className={'block6'}>
                {this.state.value ? this.state.value : 'Add label in Edit mode'}
            </div>
        ) : (
            <div className={'block6'}>
                {this.props.locked ? null :
                    <BlockTools
                        id={getId(this.props.block)}
                    />
                }
                <Input
                    value={this.state.value}
                    onChange={event => {
                        this.setState({ value: event.target.value });
                    }}
                    onBlur={this.save}
                    className={'block6Input'}
                    placeholder={'Enter data here'}
                />
            </div>
        );
    }
}

Block6.propTypes = {
    block: PropTypes.shape().isRequired,
    locked: PropTypes.bool.isRequired,
    updateCallback: PropTypes.func.isRequired
};
export default Block6;
