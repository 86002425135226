// Returns if a value is an object
export const isObject = value => {
    return value && typeof value === 'object' && value.constructor === Object;
};

// returns true if value is string
export const isString = value => {
    return typeof value === 'string';
};

