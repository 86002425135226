import React from 'react';
import PropTypes from 'prop-types';
import Project from './Project';
import Measurement from './Measurement';
import Observation from './Observation';
import MacroPhoto from './MacroPhoto';
import Drawing from './Drawing';
import Plan from './Plan';
import Map from './Map';
import Site from './Site';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateElement, deleteElement } from '../../../actions/data';
import { getId, getValue } from "../../../utils/data";

class ComponentView extends React.Component {
    render () {
        const componentsView = {
            project: Project,
            observation: Observation,
            measurement: Measurement,
            photo: MacroPhoto,
            drawing: Drawing,
            plan: Plan,
            map: Map,
            site: Site
        };

        // only this elements will be displayed when clicked
        const viewElements = ['project', 'observation', 'measurement', 'visualAsset', 'site'];

        let content = null;

        let entityType = this.props.elementType ?
            this.props.elementType[0].toLowerCase() + this.props.elementType.slice(1) :
            null;

        if (entityType &&
            viewElements.includes(entityType) &&
            this.props.elementPrimaryKey &&
            this.props.data[`${entityType}s`] &&
            this.props.data[`${entityType}s`].length) {
            const element = this.props.data[
                `${this.props.elementType[0].toLowerCase() + this.props.elementType.slice(1)}s`].find(el =>
                getId(el) === this.props.elementPrimaryKey);
            if (element) {
                if (entityType === 'visualAsset') entityType = getValue(element, 'assetType');
                let Component = componentsView[entityType];
                content = (
                    <Component
                        object={element}
                        viewRef={this.props.viewRef}
                    />);
            }
        }
        if (!content) content = (<div>Click on a viewable element to view it</div>);
        return (
            <div style={{ width: '100%' }}>{content}</div>
        );
    }
}

ComponentView.propTypes = {
    // redux props
    elementType: PropTypes.string,
    elementPrimaryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.shape().isRequired,
    element: PropTypes.shape(),
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired
};
ComponentView.defaultProps = {};

// redux settings
const mapStateToProps = state => ({
    elementType: state.view.elementType,
    elementPrimaryKey: state.view.elementPrimaryKey,
    data: state.data,
    element: state.view.element
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updateElement,
    deleteElement
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComponentView);
