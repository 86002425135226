import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    filterCollectionByParent,
    filterCollectionByParentSorted,
    getCellDefaultValueByHeaderColumn,
    getId,
    getValue
} from "../../../../../utils/data";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteElement, addNewElement } from "../../../../../actions/data";
import TableEdit from "../../../../table-edit/TableEdit";
import BlockTools from './blockTools';

class Block7 extends Component {
    getHeaderColumns = () => {
        return filterCollectionByParentSorted(this.props.headers, this.props.block, 'col_no');
    };

    getRowsNumber = () => {
        if (this.getHeaderColumns().length) {
            return filterCollectionByParent(this.props.cells, this.getHeaderColumns()[0]).length;
        } else {
            return 0;
        }
    };

    getHeaderColumnsForTableEdit = () => {
        return this.getHeaderColumns().map(header => {
            let type;

            switch (getValue(header, 'type')) {
                case 'BOL':
                    type = 'Toggle';
                    break;

                default:
                    type = 'TextField';
            }

            return {
                value: getValue(header, 'header_text'),
                type,
                width: getValue(header, 'width'),
                label: getValue(header, 'header_text')
            };
        });
    };

    getRowsForTableEdit = () => {
        const totalRows = this.getRowsNumber();
        const rows = [];
        for (let rowNo = 0; rowNo < totalRows; rowNo++) {
            const cells = this.getHeaderColumns().map(header => {
                const cell = filterCollectionByParent(this.props.cells, header)[rowNo];
                return {
                    value: getValue(cell, 'val')
                };
            });
            rows.push({ columns: cells });
        }
        return rows;
    };


    updateCellValue = editedRow => {
        this.getHeaderColumns().forEach((header, idx) => {
            // on add edited row is undefined, on import it contains columns but doesn't have id,
            // on update it has bot columns and id
            if (editedRow && editedRow.id !== undefined) {
                const cells = filterCollectionByParent(this.props.cells, header);
                const cell = cells[editedRow.id];
                const val = editedRow.columns[idx].value;
                if (getValue(cell, 'val') !== val) {
                    this.props.updateCallback('columndata', getId(cell), { val });
                }
            } else if (editedRow) {
                editedRow.forEach(row => {
                    const val = row.columns[idx].value;
                    this.props.addNewElement('columndata', getId(header),
                        { val });
                });
            } else {
                const val = getCellDefaultValueByHeaderColumn(header);
                this.props.addNewElement('columndata', getId(header),
                    { val });
            }
        });
    };

    deleteRow = deletedRow => {
        this.getHeaderColumns().forEach(header => {
            const cells = filterCollectionByParent(this.props.cells, header);
            const cell = cells[deletedRow.rowId];
            this.props.deleteElement('columndata', getId(cell));
        });
    };

    render () {
        // const headerColumns = filterCollectionByParent(this.props.headers, this.props.block);
        // const firstColCells = filterCollectionByParent(this.props.cells, headerColumns[0]);
        return (
            <div className={'block7'}>
                {this.props.locked ? null :
                    <BlockTools
                        id={getId(this.props.block)}
                        columns={filterCollectionByParentSorted(this.props.headers, this.props.block, 'col_no')}
                    />
                }
                <TableEdit
                    headerColumns={this.getHeaderColumnsForTableEdit()}
                    rows={this.getRowsForTableEdit()}
                    headerRowStyle={{ backgroundColor: '#CCC', padding: 12 }}
                    headerCellStyle={{ backgroundColor: '#CCC' }}
                    onChange={this.updateCellValue}
                    onDelete={this.deleteRow}
                    importAllowed
                    mappings={this.props.mappings}
                    addNewElement={this.props.addNewElement}
                />
            </div>
        );
    }
}

Block7.propTypes = {
    block: PropTypes.shape().isRequired,
    locked: PropTypes.bool.isRequired,
    headers: PropTypes.array.isRequired,
    cells: PropTypes.array.isRequired,
    updateCallback: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    addNewElement: PropTypes.func.isRequired,
    mappings: PropTypes.array
};

const mapStateToProps = state => ({
    headers: state.data.columnmetas,
    cells: state.data.columndatas,
    mappings: state.data.csvimports
});

const mapActionsToDispatch = dispatch => bindActionCreators({
    deleteElement,
    addNewElement
}, dispatch);

export default connect(mapStateToProps, mapActionsToDispatch)(Block7);
