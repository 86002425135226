import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DragSource } from "react-dnd";

const PinLegendItem = ({ number, annotationType, connectDragSource, ...props }) => {
    return connectDragSource(
        <div className={'nonSelectable'}>
            <SvgIcon
                {...props}
            >
                <path
                    d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398
                     0-4.199-3.801-7.602-8-7.602zm0 14c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z"
                />
                <text
                    x={number > 9 ? 6.5 : 9.5}
                    y={10.5}
                    style={{ fontSize: props.fontSize ? props.fontSize : 9 }}
                >
                    {number}</text>
            </SvgIcon>
        </div>
    );
};

PinLegendItem.propTypes = {
    number: PropTypes.string,
    annotationType: PropTypes.string,
    connectDragSource: PropTypes.func.isRequired,
    style: PropTypes.shape()
};
PinLegendItem.defaultProps = {
    number: 0,
    annotationType: 'Location',
    style: {}
};

// dnd props
// This will return the label of the item as a type
const getType = props => props.annotationType ? props.annotationType : 'location';

const dndSpecs = {
    // begin Drag sets the information transferred to the target
    beginDrag (props) {
        return { dragType: props.annotationType ? props.annotationType : 'location' };
    }
};

const collect = connect => ({
    connectDragSource: connect.dragSource()
    // connectDragPreview: connect.dragPreview()
});

// eslint-disable-next-line new-cap
export default DragSource(getType, dndSpecs, collect)(PinLegendItem);
