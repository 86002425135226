
export const ELEMENT_VIEW_SELECTED = 'ELEMENT_VIEW_SELECTED';

const initialState = {
    elementType: '',
    elementPrimaryKey: null
    // elementChangeCallback: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ELEMENT_VIEW_SELECTED:
            return {
                ...state,
                elementType: action.payload.elementType,
                elementPrimaryKey: action.payload.elementPrimaryKey
                // elementChangeCallback: action.payload.elementChangeCallback
            };

        default:
            return state;
    }
};
