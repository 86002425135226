/* global window */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { Image, Paper } from 'react-raphael';
import AnnotationPath from './AnnotationPath';
import { getId, getValue } from '../../utils/data';
import canvg from 'canvg';
import { addUploadTask } from "../../utils/image";
// import { set, Store } from 'idb-keyval';
import * as ReactDOMServer from "react-dom/server";

class AnnotatedImage extends Component {
    constructor (props, context) {
        super(props, context);
        this._handleResize = this._handleResize.bind(this);
        this._onImgLoad = this._onImgLoad.bind(this);
    }

    componentWillMount () {
        let annotations = this.props.annotations;
        this.setState({ annotations });
    }

    componentDidMount () {
        window.addEventListener("resize", this._handleResize);
        if (this.paper && this.paper.paper) {
            this.paper.paper.setViewBox(0, 0, this.baseAbsWidth, this.baseAbsWidth *
                (this.img.offsetHeight / this.img.offsetWidth), true);
        }
        const coef = this.baseAbsWidth / this.img.offsetWidth;
        this.setState({ coef });
    }

    //
    // componentWillReceiveProps (nextProps/*, nextContext*/) {
    //     let annotations = nextProps.annotations;
    //     this.setState({ annotations });
    // }

    state = {
        annotations: [],
        dimensions: {
            height: 0,
            width: 0
        },
        coef: 1,
        locked: this.props.locked
    };

    img = null;
    paper = null;
    baseAbsWidth = 1000;
    paths = [];

    _handleResize = () => {
        if (this.img) {
            this._onImgLoad({ target: this.img });
            const coef = this.baseAbsWidth / this.img.offsetWidth;
            this.setState({ coef });
            // this.paper.paper.setViewBox(0, 0, this.baseAbsWidth, this.baseAbsWidth *
            //     (this.img.offsetHeight / this.img.offsetWidth), true);
            if (this.svgImage) {
                this.svgImage.width = this.baseAbsWidth;
                this.svgImage.height = this.baseAbsWidth * (this.img.offsetHeight / this.img.offsetWidth);
            }
            // if (this.canvas) {
            //     this.canvas.width = this.baseAbsWidth;
            //     this.canvas.height = this.baseAbsWidth * (this.img.offsetHeight / this.img.offsetWidth);
            // }
        }
    };

    _onImgLoad = ({ target: img }) => {
        // const coef = this.img.offsetWidth / this.baseAbsWidth;
        this.setState({
            dimensions: {
                height: img.offsetHeight,
                width: img.offsetWidth
            }
        });
        if (this.paper && this.paper.paper) {
            this.paper.paper.setViewBox(0, 0, this.baseAbsWidth, this.baseAbsWidth *
                (this.img.offsetHeight / this.img.offsetWidth), true);
        }
        if (this.svgImage) {
            this.svgImage.width = this.baseAbsWidth;
            this.svgImage.height = this.baseAbsWidth * (this.img.offsetHeight / this.img.offsetWidth);
        }
        if (this.canvas) {
            this.canvas.width = this.baseAbsWidth + 500;
            this.canvas.height = this.baseAbsWidth * (this.img.offsetHeight / this.img.offsetWidth) + 500;
        }
    };

    _addAnnotation = (dragType, monitor) => {
        const position = monitor.getClientOffset();
        // console.log(this.componentPosition);

        // getting container coords
        const componentPosition = ReactDOM
            .findDOMNode(this.componentRef)
            .getBoundingClientRect(); //outputs <h3> coordinates

        // converting coords inside svg relative to the container
        const x = Math.round((position.x - componentPosition.left) * this.state.coef);
        // noinspection JSSuspiciousNameCombination
        const y = Math.round((position.y - componentPosition.top) * this.state.coef);
        if (this.props.isCircle) {
            this.props.addCallback({
                annotation_type: dragType,
                x_offset: x,
                y_offset: y
            });
        } else {
            this.props.addCallback({
                annotation_type: dragType,
                // svg: {
                svg:
                // id: generateTempId(),
                // val: {
                //     type: "polygon",
                //     id: "",
                //     points: `M${x},${y}L${x + 100},${y}L${x + 100},${y + 100}L${x},${y + 100}Z`
                    `M${x},${y}L${x + 100},${y}L${x + 100},${y + 100}L${x},${y + 100}Z`
                // }
                // }
            });
        }
        this.saveImageWAnnotations();
    };

    saveImageWAnnotations = () => {
        this.setState({ locked: true });
        // let canvas = document.createElement("canvas");
        const svg = this.paper.paper.toSVG(ReactDOMServer.renderToString(this.props.defs));
        /* global document */
        this.setState({ locked: this.props.locked });
        const canvas = document.getElementById('canvas');
        // document.appendChild(canvas);

        canvg(canvas,
            svg,
            {
                renderCallback: () => {
                    const data = canvas.toDataURL("image/png");
                    let filename = this.props.imageFileName;
                    if (filename !== 'blank') {
                        filename = filename.replace(/(\.[\w\d_-]+)$/i, '_annotated.png');
                        console.log('Uploading annotated image: ' + filename);
                        // uploadImageContent(image, filename);
                        addUploadTask({ data, filename });
                    } else {
                        console.log('Not uploading the annotated image as initial image' +
                            ' was not uploaded and a placeholder is being used');
                    }
                }
            }
        );
        // document.removeChild(canvas);
    };

    _getAnnotation = id => {
        return this.props.annotations.find(annotation => getId(annotation) === id);
    };

    _getPoints = annotationId => {
        return getValue(this._getAnnotation(annotationId), 'svg').match(/.(\d*\.?\d*,\d*\.?\d*)/g).map(
            point => {
                const x = Number(point.split(',')[0].substr(1));
                const y = Number(point.split(',')[1]);
                return { x, y };
            }
        );
    };

    _getGraphs = (annotationId) => {
        const points = this._getPoints(annotationId);
        const graphs = [];
        points.forEach((point, index) => {
            // lets omit first point by check if index is not nil
            if (index) graphs.push([points[index - 1], point]);
            // add last graph
            if (index + 1 === points.length) graphs.push([point, points[0]]);
        });
        return graphs;
    };

    _moveAnnotation = (id, monitor) => {
        const movedAnnotation = this._getAnnotation(id);
        const delta = monitor.getDifferenceFromInitialOffset();
        const xDelta = Math.round(delta.x * this.state.coef);
        const yDelta = Math.round(delta.y * this.state.coef);
        if (!this.props.isCircle) {
            const points = this._getPoints(id);
            const newPoints = points.map((point, index) => {
                const x = point.x + xDelta;
                const y = point.y + yDelta;
                return !index ? `M${x},${y}` : `L${x},${y}`;
            }).join('') + 'Z';
            // console.log(movedAnnotation.svg.val.points);
            // console.log(newPoints);
            this.props.moveCallback(id, newPoints);
        } else {
            const x = Number(getValue(movedAnnotation, 'x_offset')) + xDelta;
            const y = Number(getValue(movedAnnotation, 'y_offset')) + yDelta;
            this.props.moveCallback(id, { x, y });
        }
        this.saveImageWAnnotations();
    };

    _moveAnnotationPathNode = (node, monitor) => {
        const { annotationId, initialPoint } = node;
        // const changedAnnotation = this._getAnnotation(annotationId);
        const points = this._getPoints(annotationId);
        const delta = monitor.getDifferenceFromInitialOffset();
        if (delta) {
            const xDelta = Math.round(delta.x * this.state.coef);
            const yDelta = Math.round(delta.y * this.state.coef);
            const newPoints = points.map((point, index) => {
                const oldX = point.x;
                const oldY = point.y;
                if (oldX === initialPoint.x && oldY === initialPoint.y) {
                    const x = oldX + xDelta;
                    const y = oldY + yDelta;
                    return !index ? `M${x},${y}` : `L${x},${y}`;
                } else {
                    return !index ? `M${point.x},${point.y}` : `L${point.x},${point.y}`;
                }
            }).join('') + 'Z';
            this.props.moveCallback(annotationId, newPoints);
        }
        this.saveImageWAnnotations();
    };

    _removeNode = (node) => {
        if (!this.props.isCircle) {
            const { annotationId, initialPoint } = node;
            // const changedAnnotation = this._getAnnotation(annotationId);
            const points = this._getPoints(annotationId);
            let newPoints = points
                .filter(point => {
                    const oldX = point.x;
                    const oldY = point.y;
                    return !(oldX === initialPoint.x && oldY === initialPoint.y);
                })
                .map((point, index) => {
                    return !index ? `M${point.x},${point.y}` : `L${point.x},${point.y}`;
                }).join('') + 'Z';
            // change L to M if the first node was deleted
            if (newPoints.startsWith('L')) newPoints = 'M' + newPoints.slice(1);
            this.props.moveCallback(annotationId, newPoints);
        }
        this.saveImageWAnnotations();
    };

    _addNode = (annotationId, clickedX, clickedY) => {
        if (!this.props.isCircle) {
            const position = ReactDOM
                .findDOMNode(this.componentRef)
                .getBoundingClientRect(); //returns container coordinates

            // lets's convert coords of the click to relative to the container
            const x = Math.round((clickedX - Math.round(position.left)) * this.state.coef);
            // noinspection JSSuspiciousNameCombination
            const y = Math.round((clickedY - Math.round(position.top)) * this.state.coef);

            // console.log('X:' + x);
            // console.log('Y:' + y);
            const graphs = this._getGraphs(annotationId);
            // console.log(graphs);
            let graphIndex;
            const graph = graphs.find((graph, index) => {
                let distance = distToSegment({ x, y }, graph[0], graph[1]);
                if (distance < 5 * this.state.coef) {
                    // we add 1 to pass thru if and then subtract it back
                    graphIndex = index + 1;
                    return true;
                }
            });
            if (graph && graphIndex) {
                graphIndex -= 1;
                graphs.splice(graphIndex, 1, [graph[0], { x, y }], [{ x, y }, graph[1]]);
                const newPoints = graphs.map(graph => graph[0]);
                // console.log(newPoints);
                // console.log(newPoints);
                let path = newPoints.map((point, index) => index ? `L${point.x},${point.y}` : `M${point.x},${point.y}`)
                    .join('') + 'Z';
                this.props.moveCallback(annotationId, path);
            }
        }
        this.saveImageWAnnotations();
    };

    render () {
        const svgs = this.props.showSVGs ? this.props.annotations.map(
            annotation => {
                const attrs = {};
                ['stroke', 'stroke-width', 'fill', 'fill-opacity'].forEach(attr => {
                    if (annotation.style && annotation.style[attr]) {
                        attrs[attr] = annotation.style[attr];
                    }
                });
                if (this.props.isCircle) {
                    return (
                        <AnnotationPath
                            key={getId(annotation)}
                            id={getId(annotation)}
                            isCircle
                            locX={Number(getValue(annotation, 'x_offset'))}
                            locY={Number(getValue(annotation, 'y_offset'))}
                            number={getValue(annotation, 'sequence')}
                            attr={attrs}
                            locked={this.props.locked || this.state.locked}
                            removeNodeCallback={this._removeNode}
                            addNodeCallback={this._addNode.bind(this)}
                            ref={path => this.paths.push(path)}
                        />
                    );
                } else {
                    return (
                        <AnnotationPath
                            key={getId(annotation)}
                            d={getValue(annotation, 'svg')}
                            attr={attrs}
                            id={getId(annotation)}
                            locked={this.props.locked || this.state.locked}
                            removeNodeCallback={this._removeNode}
                            addNodeCallback={this._addNode.bind(this)}
                            ref={path => this.paths.push(path)}
                            number={getValue(annotation, 'no')}
                        />
                    );
                }
            }) : null;
        return this.props.connectDropTarget(
            <div
                style={{ display: 'flex', flex: 1, alignItems: 'flex-start', flexDirection: "column" }}
                ref={el => {
                    if (el) {
                        this.componentRef = el;
                    }
                }}
                className="react-raphael-wrapper"
            >
                <Paper
                    width={this.state.dimensions.width}
                    height={this.state.dimensions.height}
                    style={{ display: 'flex', flex: 1 }}
                    ref={paper => {
                        this.paper = paper;
                    }}
                >
                    <Image
                        src={this.props.image}
                        width={this.img ? this.baseAbsWidth : 0}
                        height={this.img ? this.baseAbsWidth *
                            (this.img.offsetHeight / this.img.offsetWidth) : 0}
                        ref={ref => {
                            this.svgImage = ref;
                        }}
                        onLoad={this._onImgLoad.bind(this)}
                    />
                    {svgs}
                </Paper>
                <img
                    src={this.props.image}
                    ref={img => {
                        this.img = img;
                    }}
                    // width={'100%'}
                    onLoad={this._onImgLoad.bind(this)}
                    alt={""}
                    style={{
                        marginTop: -1 * (this.state.dimensions.height + 8),
                        width: "100%",
                        height: "auto"
                    }}
                />
                <canvas
                    id={'canvas'}
                    ref={ref => {
                        this.canvas = ref;
                    }}
                    style={{ display: 'none' }}
                />

            </div>
        );
    }
}

AnnotatedImage.propTypes = {
    image: PropTypes.string.isRequired,
    annotations: PropTypes.array.isRequired,
    annotationTypes: PropTypes.array.isRequired,
    showSVGs: PropTypes.bool,
    addCallback: PropTypes.func.isRequired,
    moveCallback: PropTypes.func.isRequired,
    locked: PropTypes.bool,
    isCircle: PropTypes.bool,
    imageFileName: PropTypes.string.isRequired,
    defs: PropTypes.array,
    // dnd props
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired
};
AnnotatedImage.defaultProps = {
    showSVGs: true,
    locked: false,
    vectorType: 'shape',
    defs: []
};

//dnd settings
const targetSpecs = {
    drop (props, monitor, component) {
        const item = monitor.getItem();
        console.log(item);
        if (item.dragType === 'AnnotationPath') {
            component._moveAnnotation(item.id, monitor);
        } else if (item.dragType === 'AnnotationPathNode') {
            component._moveAnnotationPathNode(item, monitor);
        } else {
            component._addAnnotation(item.dragType, monitor);
        }
        return {};
    }
};

const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});

const allowTypes = props => props.annotationTypes.concat(["AnnotationPath", "AnnotationPathNode"]);


function sqr (x) {
    return x * x;
}

function dist2 (v, w) {
    return sqr(v.x - w.x) + sqr(v.y - w.y);
}

function distToSegmentSquared (p, v, w) {
    let l2 = dist2(v, w);
    if (l2 === 0) return dist2(p, v);
    let t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    t = Math.max(0, Math.min(1, t));
    return dist2(p, {
        x: v.x + t * (w.x - v.x),
        y: v.y + t * (w.y - v.y)
    });
}

function distToSegment (p, v, w) {
    return Math.sqrt(distToSegmentSquared(p, v, w));
}


// eslint-disable-next-line new-cap
export default DropTarget(allowTypes, targetSpecs, collect)(AnnotatedImage);
