/* eslint-disable no-dupe-keys */
let testHierarchy = {
    currentProjects:
        [
            {
                fetched: true,
                projectName: { id: 458890, val: "Project 1" },
                projectId: { id: 44890, val: 509825 },
                projectDesc: { id: 150895, val: "A project of some kind" },
                sites: [
                    {
                        id: { id: 34507, val: 748563 },
                        siteName: { id: 34527, val: "Site 1" },
                        lat: { id: 54507, val: 123.34 },
                        long: { id: 54547, val: 34.56 },
                        elevation: { id: 54503, val: 23.34 },
                        visualAssets: [
                            {
                                id: { id: 52507, val: 7486 },
                                assetName: { id: 52547, val: "Plan 1" },
                                assetType: { id: 52557, val: "plan" },
                                filename: { id: 52107, val: "image.jpg" },
                                locations: [
                                    {
                                        name: { id: 52567, val: "Location 1" },
                                        id: { id: 2341230, val: 2341234 },
                                        no: { id: 52504, val: 21 },
                                        offset_x: { id: 52907, val: 50 },
                                        offset_y: { id: 50507, val: 50 },
                                        style: {
                                            stroke: "#000000",
                                            strokeWidth: "1",
                                            fill: "url(#diagonal-stripe-1)"
                                        },
                                        assets: [],
                                        observations: [
                                            {
                                                id: { id: 52577, val: 748663 },
                                                observeName: { id: 52587, val: "Observation 1" },
                                                images: [
                                                    {
                                                        id: { id: 52597, val: 4365734 },
                                                        filename: { id: 52107, val: "image.jpg" },
                                                        name: { id: 52207, val: "Detail photo1" },
                                                        type: { id: 52307, val: "photo" },
                                                        m_x: { id: 52407, val: 2.3 },
                                                        m_y: { id: 52607, val: 1.5 },
                                                        m_unit: { id: 52707, val: "metre" },
                                                        annotations: [
                                                            {
                                                                id: { id: 8776, val: 57656 },
                                                                no: { id: 52807, val: 1 },
                                                                offset_x: { id: 52907, val: 1.2 },
                                                                offset_y: { id: 50507, val: 1.2 },
                                                                description: { id: 51507, val: "Rust hole 1" },
                                                                svg: {
                                                                    id: 53507,
                                                                    val: {
                                                                        "type": "polygon",
                                                                        "id": "SvgjsPolygon1013",
                                                                        "points": "M104,52L254,20L340,176L22,178L102,64Z",
                                                                        // "points": "M52,26L127,10L170,86L11,89L51,32Z",
                                                                        "stroke": "#000000",
                                                                        "stroke-width": "1",
                                                                        "fill": "none"
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                id: { id: 768768, val: 8897 },
                                                                no: { id: 54507, val: 21 },
                                                                offset_x: { id: 55507, val: 0.2 },
                                                                offset_y: { id: 56507, val: 0.2 },
                                                                description: { id: 57507, val: "Rust hole 2" },
                                                                svg: {
                                                                    id: 58507,
                                                                    val: {
                                                                        "type": "polygon",
                                                                        "id": "SvgjsPolygon1013",
                                                                        "points": "M304,102L654,220L540,376L222,378L182,264Z",
                                                                        // "points": "M152,56L327,110L270,186L111,189L91,132Z",
                                                                        "stroke": "#000000",
                                                                        "stroke-width": "1",
                                                                        "fill": "#BBB"
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        id: { id: 59507, val: 4365739 },
                                                        filename: { id: 12507, val: "image2.jpg" },
                                                        name: { id: 22507, val: "Detail drawing 1" },
                                                        type: { id: 32507, val: "drawing" },
                                                        m_x: { id: 42507, val: 2.3 },
                                                        m_y: { id: 62507, val: 1.5 },
                                                        m_unit: { id: 72507, val: "metre" },
                                                        annotations: [
                                                            {
                                                                id: { id: 2345346, val: 23456 },
                                                                no: { id: 82507, val: 1 },
                                                                offset_x: { id: 92507, val: 1.2 },
                                                                offset_y: { id: 52501, val: 1.2 },
                                                                description: { id: 52502, val: "Rust hole 2.1" },
                                                                svg: {
                                                                    id: 52503,
                                                                    val: {
                                                                        "type": "polygon",
                                                                        "id": "SvgjsPolygon1013",
                                                                        "points": "M152,56L327,110L270,186L111,189L91,132Z",
                                                                        "stroke": "#000000",
                                                                        "stroke-width": "1",
                                                                        "fill": "none"
                                                                    }
                                                                },
                                                            },
                                                            {
                                                                id: { id: 2435, val: 2345 },
                                                                no: { id: 52504, val: 21 },
                                                                offset_x: { id: 52505, val: 0.2 },
                                                                offset_y: { id: 52506, val: 0.2 },
                                                                description: { id: 52508, val: "Rust hole 2.2" },
                                                                svg: {
                                                                    id: 52509,
                                                                    val: {
                                                                        "type": "polygon",
                                                                        "id": "SvgjsPolygon1013",
                                                                        "points": "M52,26L127,10L170,86L11,89L51,32Z",
                                                                        "stroke": "#000000",
                                                                        "stroke-width": "1",
                                                                        "fill": "none"
                                                                    }
                                                                },
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                id: { id: 525071, val: 2486633 },
                                                observeName: { id: 525072, val: "Observation 2" },
                                            },
                                            {
                                                id: { id: 525073, val: 5486632 },
                                                observeName: { id: 525074, val: "Observation 3" },
                                            }
                                        ],
                                        measurements: [
                                            {
                                                id: { id: 525075, val: 7486636 },
                                                measureName: { id: 525076, val: "Measurement 1" },
                                                type: { id: 525077, val: "" },
                                            },
                                            {
                                                id: { id: 525078, val: 2486633 },
                                                measureName: { id: 525079, val: "Measurement 2" },
                                                type: { id: 525070, val: "Hybrid CP" },
                                                data: [
                                                    {
                                                        txt: { ui_id: "HyCPName", id: 585848, val: "Measurement 2" }
                                                    },
                                                    {
                                                        txt: { ui_id: "JBNo", id: 585858, val: 24 }
                                                    },
                                                    {
                                                        dt: { ui_id: "Depol24", id: 585868, val: 120602960 }
                                                    },
                                                    {
                                                        dt: { ui_id: "Depol48", id: 585878, val: 120602960 }
                                                    },
                                                    {
                                                        dt: { ui_id: "Depol72", id: 585888, val: 120602960 }
                                                    },
                                                    {
                                                        txt: {
                                                            ui_id: "JBNotes",
                                                            id: 587848,
                                                            val: "Notes about the juncton box"
                                                        }
                                                    },
                                                    {
                                                        txt: {
                                                            ui_id: "StructNotes",
                                                            id: 586848,
                                                            val: "Notes on structure"
                                                        }
                                                    },
                                                    {
                                                        txt: { ui_id: "OtherNotes", id: 586838, val: "Notes on other" }
                                                    },
                                                    {
                                                        grid: {
                                                            ui_id: "CPMonitoring",
                                                            id: 555555,
                                                            rows: [
                                                                {
                                                                    id: 435690,
                                                                    columns: [{ i: 45678, v: 1 }, {
                                                                        i: 45679,
                                                                        v: 123
                                                                    }, { i: 45670, v: 23.4 }, {
                                                                        i: 45671,
                                                                        v: 34.2
                                                                    }, { i: 45672, v: 23.5 }, {
                                                                        i: 45673,
                                                                        v: 12.4
                                                                    }, { i: 45674, v: 16.3 }, { i: 45675, v: 21.8 }]
                                                                },
                                                                {
                                                                    id: 435691,
                                                                    columns: [{ i: 45676, v: 2 }, {
                                                                        i: 45677,
                                                                        v: 187
                                                                    }, { i: 45688, v: 23.4 }, {
                                                                        i: 45698,
                                                                        v: 34.2
                                                                    }, { i: 45608, v: 23.5 }, {
                                                                        i: 45618,
                                                                        v: 12.4
                                                                    }, { i: 45628, v: 16.3 }, { i: 45638, v: 21.8 }]
                                                                },
                                                                {
                                                                    id: 435692,
                                                                    columns: [{ i: 45648, v: 3 }, {
                                                                        i: 45658,
                                                                        v: 210
                                                                    }, { i: 45668, v: 23.4 }, {
                                                                        i: 45778,
                                                                        v: 34.2
                                                                    }, { i: 45878, v: 23.5 }, {
                                                                        i: 45978,
                                                                        v: 12.4
                                                                    }, { i: 45078, v: 16.3 }, { i: 45178, v: 21.8 }]
                                                                },
                                                                {
                                                                    id: 435693,
                                                                    columns: [{ i: 45278, v: 4 }, {
                                                                        i: 45378,
                                                                        v: 204
                                                                    }, { i: 45478, v: 23.4 }, {
                                                                        i: 45578,
                                                                        v: 34.2
                                                                    }, { i: 46678, v: 23.5 }, {
                                                                        i: 47678,
                                                                        v: 12.4
                                                                    }, { i: 48678, v: 16.3 }, { i: 49678, v: 21.8 }]
                                                                }
                                                            ]

                                                        }
                                                    }
                                                ]
                                            },
                                            {
                                                id: { id: 525107, val: 5486630 },
                                                measureName: { id: 525207, val: "Measurement 3" },
                                                type: { id: 525307, val: "Concrete Exam" },
                                            }
                                        ]

                                    }
                                ]
                            },
                            {
                                id: { id: 525707, val: 74866345 },
                                assetName: { id: 525807, val: "Drawing 1" },
                                assetType: { id: 525907, val: "drawing" },
                                locations: [
                                    {
                                        name: { id: 5256734, val: "Location 2" },
                                        assets: []
                                    }
                                ]
                            },
                            {
                                id: { id: 520507, val: 748664234 },
                                assetName: { id: 521507, val: "Drawing 2" },
                                assetType: { id: 522507, val: "drawing" },
                                locations: [
                                    {
                                        name: { id: 5256734, val: "Location 3" },
                                        assets: []
                                    }
                                ]
                            },
                            {
                                id: { id: 52507, val: 74816390 },
                                assetName: { id: 523507, val: "Photo 1" },
                                assetType: { id: 524507, val: "photo" },
                                filename: { id: 52107, val: "image.jpg" },
                                locations: [
                                    {
                                        name: { id: 5256734, val: "Location 5" },
                                        id: { id: 2341230, val: 2341234 },
                                        no: { id: 52504, val: 21 },
                                        offset_x: { id: 52907, val: 50 },
                                        offset_y: { id: 50507, val: 50 },
                                        assets: []
                                    }
                                ]
                            },
                            {
                                id: { id: 525507, val: 75866382 },
                                assetName: { id: 526507, val: "Photo 2" },
                                assetType: { id: 525707, val: "photo" },
                                locations: [
                                    {
                                        name: { id: 5256734, val: "Location 6" },
                                        assets: []
                                    }
                                ]
                            },
                            {
                                id: { id: 528507, val: 74066328 },
                                assetName: { id: 529507, val: "Map 1" },
                                assetType: { id: 502507, val: "map" },
                                locations: [
                                    {
                                        name: { id: 5256734, val: "Location 7" },
                                        assets: []
                                    }
                                ]
                            },
                            {
                                id: { id: 512507, val: 74866384 },
                                assetName: { id: 522507, val: "Map 2" },
                                assetType: { id: 532507, val: "map" },
                                locations: [
                                    {
                                        name: { id: 5256734, val: "Location 8" },
                                        assets: []
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        id: { id: 5250705, val: 74836322 },
                        siteName: { id: 5250706, val: "Site 2" },
                        lat: { id: 5250707, val: 123.34 },
                        long: { id: 5250708, val: 34.57 },
                        elevation: { id: 5250709, val: 2.34 },
                        visualAssets: [],
                    }
                ]
            }
        ],
    archivedProjects:
        []
};

export default testHierarchy;
