import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from "react-dnd";
import { Rect } from 'react-raphael';

class AnnotationPathBoundingSquare extends Component {
    _addNode = event => {
        // if (event.shiftKey) {
        this.props.addNodeCallback(this.props.id, event.clientX, event.clientY);
        // }
    };

    render () {
        return (
            <Rect
                x={this.props.x}
                y={this.props.y}
                width={this.props.width}
                height={this.props.height}
                attr={{
                    fill: 'rgba(0,0,0,0)',
                    stroke: 'yellow',
                    zIndex: 100
                }}
                click={this._addNode}
                ref={ref => {
                    if (ref) this.props.connectDragSource(ref.getElement().node);
                }}
            />
        );
    }
}

AnnotationPathBoundingSquare.propTypes = {
    id: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    addNodeCallback: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired
};
AnnotationPathBoundingSquare.defaultProps = {};

const dndSpecs = {
    // begin Drag sets the information transferred to the target
    beginDrag (props) {
        return {
            dragType: "AnnotationPath",
            id: props.id
        };
    }
};

const collect = connect => ({ connectDragSource: connect.dragSource() });

// eslint-disable-next-line new-cap
export default DragSource("AnnotationPath", dndSpecs, collect)(AnnotationPathBoundingSquare);
