import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import '../Layouts/addTableBlockDialog.css';
import { updateElement } from "../../../../../actions/data";
import { getId, getValue } from "../../../../../utils/data";


class EditColumnsMetasDialog extends Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false,
            columnsMeta: []
        };
    }

    componentWillMount () {
        this.parsePropsToState(this.props);
    }

    componentWillReceiveProps (nextProps, nextContext) {
        this.parsePropsToState(nextProps);
    }

    parsePropsToState = props => {
        const columnsMeta = props.columns.map(column => ({
            id: getId(column),
            type: getValue(column, 'type'),
            title: getValue(column, 'header_text'),
            width: getValue(column, 'width')
        }));
        this.setState({ columnsMeta });
    };


    onColumnTitleEdit = event => {
        const idx = Number(event.target.id.replace('columnTitle', ''));
        const column = this.state.columnsMeta[idx];
        column.title = event.target.value;
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    onColumnWidthEdit = event => {
        const idx = Number(event.target.id.replace('columnWidth', ''));
        const column = this.state.columnsMeta[idx];
        column.width = event.target.value;
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    onColumnTypeEdit = event => {
        const idx = Number(event.target.name.replace('columnType', ''));
        const column = this.state.columnsMeta[idx];
        const columnType = event.target.value;
        column.type = columnType;
        if (columnType === 'DBL') {
            column.precision = 7;
            column.scale = 2;
        } else {
            Reflect.deleteProperty(column, 'precision');
            Reflect.deleteProperty(column, 'scale');
        }
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    onColumnPrecisionEdit = event => {
        const idx = Number(event.target.id.replace('columnPrecision', ''));
        const column = this.state.columnsMeta[idx];
        column.precision = event.target.value;
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    onColumnScaleEdit = event => {
        const idx = Number(event.target.id.replace('columnScale', ''));
        const column = this.state.columnsMeta[idx];
        column.scale = event.target.value;
        const columnsMeta = this.state.columnsMeta;
        columnsMeta[idx] = column;
        this.setState({ columnsMeta });
    };

    metasCanBeSaved = () => {
        let canBeSaved = true;
        this.state.columnsMeta.forEach(column => {
            if (canBeSaved) {
                if (!column.title || !column.type) canBeSaved = false;
            }
        });
        return canBeSaved;
    };

    save = () => {
        this.state.columnsMeta.forEach(column => {
            const updateFields = {
                header_text: column.title,
                width: column.width
            };
            if (column.scope) updateFields.scope = column.scope;
            if (column.precision) updateFields.precision = column.precision;
            this.props.updateElement('columnmeta', column.id, updateFields);
        });
        this.setState({ open: false });
    };

    render () {
        if (this.state.open) {
            const cellTypes = this.state.columnsMeta.map((column, i) => {
                let doubleTypeFields = column.type === 'DBL' ? (
                    <div>
                        <div>
                            <TextField
                                id={'columnPrecision' + i}
                                type={'number'}
                                onChange={this.onColumnPrecisionEdit}
                                value={column && column.precision ?
                                    column.precision : 0}
                                placeholder={'column precision'}
                                fullWidth
                                label={'Precision'}
                            />
                        </div>
                        <div>
                            <TextField
                                id={'columnScale' + i}
                                type={'number'}
                                onChange={this.onColumnScaleEdit}
                                value={column && column.scale ?
                                    column.scale : 0}
                                placeholder={'column scale'}
                                fullWidth
                                label={'Scale'}
                            />
                        </div>
                    </div>
                ) : null;

                return (
                    <div className={'columnMetaFields'} key={'columnMeta_' + i}>
                        <div style={{ margin: '5px 0' }}>Column {i + 1}</div>
                        <div>
                            <TextField
                                id={'columnTitle' + i}
                                onChange={this.onColumnTitleEdit}
                                value={column && column.title ?
                                    column.title : ''}
                                placeholder={'column title'}
                                fullWidth
                                label={'Field title'}
                            />
                        </div>
                        <div>
                            <TextField
                                id={'columnWidth' + i}
                                onChange={this.onColumnWidthEdit}
                                value={column && column.width ?
                                    column.width : ''}
                                placeholder={'column width'}
                                fullWidth
                                label={'Field width'}
                            />
                        </div>
                        <div>
                            <strong>Column type:</strong> {column.type}
                        </div>
                        {doubleTypeFields}
                    </div>
                );
            });

            return (
                <Dialog
                    open={this.state.open}
                >
                    <DialogTitle>Edit columns</DialogTitle>
                    <DialogContent>
                        {cellTypes}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ open: false });
                            }}
                        >Cancel</Button>
                        <Button
                            onClick={() => {
                                this.save();
                            }}
                            disabled={!this.metasCanBeSaved()}
                        >Ok</Button>
                    </DialogActions>
                </Dialog>
            );
        } else {
            return (
                <Button
                    onClick={() => {
                        this.setState({ open: true });
                    }}
                    className="button"
                    variant="contained"
                    size={'small'}
                >
                    Edit columns
                </Button>
            );
        }
    }
}

EditColumnsMetasDialog.propTypes = {
    columns: PropTypes.array.isRequired,
    updateElement: PropTypes.func.isRequired
};

const mapStateToProps = (/*state*/) => ({});

const mapActionsToDispatch = dispatch => bindActionCreators({
    updateElement
}, dispatch);

export default connect(mapStateToProps, mapActionsToDispatch)(EditColumnsMetasDialog);
