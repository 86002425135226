import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

const HTMLEditor = ({ value, onBlurCallback }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const convertPropsToEditorState = () => {
        const descriptionContentBlock = htmlToDraft(value);
        const descriptionContentState = ContentState.createFromBlockArray(descriptionContentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(descriptionContentState);

        setEditorState(editorState);
    };

    useEffect(convertPropsToEditorState, [value]);

    const getOutputHTML = () => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };


    return (
        <Editor
            id="description"
            label="Description"
            editorState={editorState}
            onEditorStateChange={editorState => {
                setEditorState(editorState);
                console.log('New editor state: ' + editorState);
            }}
            onBlur={() => {
                onBlurCallback(getOutputHTML());
            }}
            wrapperClassName={'draftjsEditorWrapper'}
            toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list',
                    /*'textAlign',*/ 'colorPicker', /*'link', 'embedded', 'emoji', 'image', 'remove', */'history'],
                inline: {
                    options: ['bold', 'italic', 'underline'
                        /*, 'strikethrough', 'monospace', 'superscript', 'subscript'*/]
                },
                blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3'/*, 'H4', 'H5', 'H6', 'Blockquote', 'Code'*/]
                }
            }}
            editorStyle={{ width: '100%' }}
        />
    );
};

HTMLEditor.propTypes = {
    onBlurCallback: PropTypes.func,
    value: PropTypes.string
};
HTMLEditor.defaultProps = {
    onBlurCallback: () => {
    }
};

export default HTMLEditor;
