import React from 'react';
import Delete from '@material-ui/icons/Delete';
import times from 'lodash.times';
import IconButton from '@material-ui/core/IconButton';
import Toggle from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import ErrorBoundary from '../errorBoundary';
import CsvImport from './CsvImport';
import { isString } from "../../utils/types";
// import ModeEdit from '@material-ui/icons/ModeEdit';
// import Check from '@material-ui/icons/Check';
/*
import injectTapEventPlugin from 'react-tap-event-plugin';
injectTapEventPlugin();
*/

const DatePicker = (type, children, ...props) => (
    <TextField
        type={'date'}
        {...props}
    >
        {children}
    </TextField>
);

class TableEdit extends React.Component {
    static propTypes = {
        headerColumns: PropTypes.array,
        rows: PropTypes.array,
        enableAdd: PropTypes.bool,
        enableDelete: PropTypes.bool,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        importAllowed: PropTypes.bool,
        hasError: PropTypes.bool,
        importType: PropTypes.string,
        importTypeLabel: PropTypes.string,
        containerStyle: PropTypes.object,
        headerRowStyle: PropTypes.object,
        headerCellStyle: PropTypes.object.isRequired,
        rowStyle: PropTypes.object,
        cellStyle: PropTypes.object,
        readOnlyCellTextStyle: PropTypes.object,
        checkboxStyle: PropTypes.object,
        buttonStyle: PropTypes.object,
        deleteButtonStyle: PropTypes.object,
        mappings: PropTypes.array,
        addNewElement: PropTypes.func
    };

    static defaultProps = {
        headerColumns: [],
        rows: [],
        enableDelete: true,
        onChange: function () {
        },
        onDelete: function () {
        },
        importAllowed: false,
        hasError: false,
        importType: '',
        importTypeLabel: 'Type',
        enableAdd: true,
        containerStyle: { width: "100%", texcolor: "#000000" },
        headerRowStyle: { width: "100%", color: "#000000" },
        headerCellStyle: { color: "#000000" },
        rowStyle: { width: "100%" },
        cellStyle: { color: "#000000" },
        readOnlyCellTextStyle: { color: "#000000" },
        checkboxStyle: {},
        buttonStyle: {},
        deleteButtonStyle: {},
        mappings: [],
        addNewElement: () => {
        }
    };

    constructor (props, context) {
        super(props, context);
        this.state = {
            rows: [],
            hoverValue: false,
            currentRow: false
        };
        this.randomId = Math.random().toString(36).substring(7);
    }

    componentWillMount () {
        this.setState({ rows: this.props.rows });
    }

    componentWillReceiveProps (nextProps, nextContext) {
        const { rows } = this.state;
        const row = rows.find(row => {
            return row.selected;
        });
        if (row) {
            nextProps.rows.map(nextRow => {
                if (nextRow.columns[0].value === row.columns[0].value) {
                    nextRow.selected = true;
                    nextRow.columns[1].value = row.columns[1].value;
                } else {
                    nextRow.selected = false;
                }
            });
        }
        this.setState({ rows: nextProps.rows });
    }


    // static contextTypes = {
    //     muiTheme: PropTypes.shape().isRequired
    // };

    update () {
        const row = this.state.rows.filter((row) => {
            return row.selected;
        });
        this.props.onChange(row[0]);
    }

    getCellValue (cell) {
        const self = this;
        const id = cell && Object.keys(cell).includes('id') ? cell.id : '';
        const type = this.props.headerColumns.map((header) => {
            return header.type;
        })[id];
        // const selected = cell && cell.selected;
        let value = cell && cell.value;
        if (value === undefined) value = cell && cell.val;
        if (value === undefined) value = cell && cell.v;
        const rowId = cell && Object.keys(cell).includes('rowId') ? cell.rowId : '';
        const header = cell && Object.keys(cell).includes('header') ? cell.header : '';
        let width = cell && Object.keys(cell).includes('width') ? cell.width : '';
        width = !width ? '100%' : width;
        if (!(isString(width) && width.includes('%'))) {
            width = Number(width);
        }
        const textFieldId = [id, rowId, header ? 1 : 0, 'text', this.randomId].join('-');
        const datePickerId = [id, rowId, header ? 1 : 0, 'date', this.randomId].join('-');

        const textFieldStyle = {
            width: width
        };

        const datePickerStyle = {
            width: width
        };

        const onFocus = (/*e*/) => {
            let rows = self.state.rows;
            rows[rowId].selected = true;
            self.setState({ rows: rows });
        };

        const onTextFieldChange = (e) => {
            const target = e.target;
            const value = target.value;
            let rows = self.state.rows;
            rows[rowId].columns[id].value = value;
            self.setState({ rows: rows });
        };

        const onTextFieldSave = (/*e*/) => {
            let rows = self.state.rows;
            rows[rowId].selected = true;
            self.setState({ rows: rows });
            self.update();
            rows[rowId].selected = false;
            self.setState({ rows: rows });
        };

        const onDatePickerChange = (e, date) => {
            let rows = self.state.rows;
            rows[rowId].columns[id].value = date;
            self.setState({ rows: rows });
        };

        const onToggle = (/*e*/) => {
            let rows = self.state.rows;
            rows[rowId].columns[id].value = rows[rowId].columns[id].value.toString() !== 'true';
            rows[rowId].selected = true;
            self.setState({ rows: rows });
            self.update();
            rows[rowId].selected = false;
            self.setState({ rows: rows });
        };

        if (header || (type && type === 'ReadOnly')) {
            return (<p style={{ color: 'rgb(0, 0, 0)', margin: 0, width: width, ...this.props.readOnlyCellTextStyle }}>
                {value}
            </p>);
        }

        if (type) {
            // if (selected) {
            if (type === 'TextField') {
                return (<TextField
                    id={textFieldId}
                    onFocus={onFocus}
                    onChange={onTextFieldChange}
                    onBlur={onTextFieldSave}
                    style={textFieldStyle}
                    value={value}
                    className="dataTableTextField"
                    inputProps={{
                        style: {
                            width: '100%'
                        }
                    }}
                />);
            }
            if (type === 'DatePicker') {
                return (<DatePicker
                    id={datePickerId}
                    onChange={onDatePickerChange}
                    mode="landscape"
                    style={datePickerStyle}
                    value={value}
                />);
            }
            if (type === 'Toggle') {
                return (
                    <Toggle
                        onChange={onToggle}
                        checked={value !== undefined ? value.toString() === 'true' : false}/>
                );
            }
        }

        return (<TextField
            id={textFieldId}
            style={textFieldStyle}
            value={value}
            inputProps={{
                style: {
                    width: '100%'
                }
            }}
        />);
    }

    renderHeader () {
        const headerColumns = this.props.headerColumns;
        const columns = headerColumns.map((column/*, id*/) => {
            return { value: column.label || column.value };
        });
        const row = { columns: columns, header: true };

        return this.renderRow(row);
    }

    checkHas100PercentColumn () {
        const percentColumn = this.props.headerColumns.find(column =>
            isString(column.width) && (column.width === '100%' || !column.width));
        return !!percentColumn;
    }

    renderRow (row) {
        const self = this;
        const columns = row.columns ? row.columns : row.cols;
        let rowStyle = {
            width: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
            padding: 12,
            border: 0,
            borderBottom: '1px solid #ccc',
            height: 'auto',
            ...this.props.rowStyle
        };
        if (row.header) {
            rowStyle = {
                ...rowStyle,
                ...this.props.headerRowStyle
            };
        }
        // const checkboxStyle = {
        //     display: 'flex',
        //     flexFlow: 'row nowrap',
        //     width: 50,
        //     height: 24,
        //     alignItems: 'center',
        //     ...this.props.checkboxStyle
        // };

        const deleteButtonStyle = {
            display: 'flex',
            flexFlow: 'row nowrap',
            width: 50,
            minWidth: 50,
            height: 24,
            alignItems: 'center',
            paddingTop: 12,
            ...this.props.deleteButtonStyle
        };

        const rowId = row.id;
        const rowKey = ['row', rowId].join('-');

        // const onRowClick = function (/*e*/) {
        //     let rows = self.state.rows;
        //     rows.forEach((row, i) => {
        //         if (rowId !== i) row.selected = false;
        //     });
        //     rows[rowId].selected = !rows[rowId].selected;
        //     self.setState({ rows: rows });
        // };

        const r = self.state.rows[rowId];
        const selected = (r && r.selected) || false;

        // const button = selected ? <Check/> : <ModeEdit/>;
        // const tooltip = selected ? 'Done' : 'Edit';

        const onDeleteRow = function (/*e*/) {
            let rows = self.state.rows;
            let deleteEvent = {};
            rows.forEach((row, i) => {
                if (rowId === i) {
                    rows.splice(i, 1);
                    deleteEvent = { rowId, row };
                }
            });
            rows.forEach((row, i) => {
                row.id = i;
            });
            self.setState({ rows: rows });
            if (deleteEvent !== {}) self.props.onDelete(deleteEvent);
        };

        // const onClick = function (e) {
        //     if (selected) {
        //         self.update();
        //     }
        //
        //     onRowClick(e);
        // };

        const deleteButton = (!this.props.enableDelete || selected || row.header) ?
            <div style={deleteButtonStyle}>&nbsp;</div> :
            (<IconButton style={deleteButtonStyle} tooltip={'Delete this row'} onClick={onDeleteRow}>
                <Delete/>
            </IconButton>);

        // const checkbox = row.header ? <div style={checkboxStyle}/> :
        //     (<IconButton style={checkboxStyle} tooltip={tooltip} onClick={onClick}>
        //         {button}
        //     </IconButton>);

        return (
            <div key={rowKey} className="row" style={rowStyle}>
                {/*{checkbox}*/}
                {columns.map((column, id) => {
                    const width = this.props.headerColumns.map((header) => {
                        return (header && header.width) || false;
                    })[id];
                    const cellStyleProp = row.header ? this.props.headerCellStyle : this.props.cellStyle;
                    const cellStyle = {
                        // display: 'flex',
                        // flexFlow: 'row nowrap',
                        // flexGrow: 0.15,
                        // flexBasis: 'content',
                        alignItems: 'center',
                        minHeight: 30,
                        width: width || '100%',
                        color: 'rgb (0, 0, 0,)',
                        // backgroundColor: 'rgb(255, 255, 255)',
                        ...cellStyleProp
                    };
                    const columnKey = ['column', id].join('-');
                    column.selected = selected;
                    column.rowId = rowId;
                    column.id = id;
                    column.header = row.header;
                    column.width = cellStyle.width;

                    return (
                        <div key={columnKey} className="cell" style={cellStyle}>
                            {this.getCellValue(column)}
                        </div>
                    );
                }, this)}
                {!this.checkHas100PercentColumn() ? <div style={{ width: '100%' }}/> : null}
                {deleteButton}
            </div>
        );
    }

    componentDidCatch (error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log(error, info);
    }

    _onImport = newRows => {
        if (this.props.onChange) {
            this.props.onChange(newRows);
        } else {
            this.setState({ rows: this.state.rows.concat(newRows) });
        }
    };

    render () {
        if (this.state.hasError) return <div>Something went wrong</div>;
        const self = this;
        const style = {
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'Roboto, sans-serif',
            marginLeft: 0,
            marginRight: 0,
            ...this.props.containerStyle
        };

        const buttonStyle = {
            display: 'flex',
            flexFlow: 'row nowrap',
            marginTop: 10,
            width: '100%',
            ...this.props.buttonStyle
        };

        const rows = this.state.rows;
        const columnTypes = this.props.headerColumns.map((header) => {
            return header.type;
        });

        const onButtonClick = (/*e*/) => {
            const newColumns = times(columnTypes.length, (index) => {
                const defaults = {
                    TextField: '',
                    Toggle: true
                };

                const value = defaults[columnTypes[index]];

                return { value: value };
            });
            const updatedRows = [...rows];
            updatedRows.push({ columns: newColumns, selected: true });
            updatedRows.forEach(row => {
                if (row.selected) {
                    self.update();
                    row.selected = false;
                }
            });
            self.setState({ rows: updatedRows });
        };

        const importModal = this.props.importAllowed ? (
            <CsvImport
                buttonLabel="Import"
                style={{ width: 65 }}
                headerColumns={this.props.headerColumns.map(column => column.value)}
                importType={this.props.importType}
                importTypeLabel={this.props.importTypeLabel}
                onImport={newRows => this._onImport(newRows)}
                mappings={this.props.mappings}
                addNewElement={this.props.addNewElement}
            />
        ) : null;
        return (
            <ErrorBoundary>
                <div className="containerWhite" style={style}>
                    {this.renderHeader()}
                    {rows.map((row, id) => {
                        row.id = id;
                        return this.renderRow(row);
                    }, this)}

                    <div className="" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        {this.props.enableAdd ? (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onButtonClick}
                                style={buttonStyle}
                            >Add Row</Button>) : null}
                        {importModal}
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}

export default TableEdit;
