import globalKontraktor from "../globalKontraktor";
import {
    LOGIN_REQUESTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGGED_OUT,
    HOST_UPDATE
} from '../reducers/auth';
import { dataLoad } from "./data";

export const hostUpdate = (/*host*/) => dispatch => {
    dispatch({
        type: HOST_UPDATE,
        payload: {
            host: 'inspectit.app'
            // host
            // uncomment the line above and comment the line before if you want to run with local backend
        }
    });
};


export const loginFailure = error => dispatch => {
    dispatch({
        type: LOGIN_FAILURE,
        payload: {
            error
        }
    });
};

const loginSuccess = username => dispatch => {
    dispatch({
        type: LOGIN_SUCCESS,
        payload: {
            username
        }
    });
};


export const login = (username, password) => (dispatch, getState) => {
    /*
        The whole login support is derived from this example, which does not use passwords, we do
        https://github.com/RuedigerMoeller/kontraktor/blob/a232599790d090b5e6701c4b2c1f3b6525120288/examples/webapp-spa/react-ui-lib-examples/react-material-ui/src/main/web/client/index.jsx

        This code will replace that below
        The connect function needs to pick up the location.href I think

        The 'var outputhtml' below is where you assign the output to the datanew structure, which will required changes to suit the
        structure delivered by the backend

    if ( ! this.server ) {
      this.kclient = new KClient().useProxies(false);
      let coder = new DecodingHelper();
      this.kclient.connect("http://127.0.0.1:8080/api")
      .then( (server,error) => { // KPromise (!, differs from ES6 promise unfortunately)
        if ( server ) {
          this.server = server;
          server.ask('login', "david.wynter@wynterassociates.com", "murphy10iscute")
            .then(function(session, err) {
              if (err) {
                console.error(err);
              } else {
                console.log(session);
                session.ask('getInspectItData').then(function(projectData, err) {
                  if(err) {
                    console.error(err);
                  } else {
                    var outputhtml = JSON.stringify(projectData, null, 2);

    */
    dispatch({ type: LOGIN_REQUESTED });
    let url, connectionType;

    const host = getState().auth.host;
    // localhost testing
    // url = 'ws://' + window.location.hostname + ':8080/ws';
    // deployed host url
    url = 'wss://' + host + '/ws';

    connectionType = "WS";
    globalKontraktor.kclient
        .connect(url, connectionType)
        .then((server, err) => {
            if (err || !server) {
                if ((err && err.type === 'error') || !server) {
                    err = 'Connection to backend can\'t be established';
                }
                dispatch(loginFailure("" + err));
            } else if (server) {
                globalKontraktor.server = server;
                server.ask('login', username, password)
                    .then((session, err) => {
                        if (err) {
                            server.tell('clientError', err);
                            dispatch(loginFailure("" + err));
                        } else {
                            global.session = session;
                            console.log("logged in");
                            dispatch(loginSuccess(username));
                            //registering service worker
                            dispatch(dataLoad()).then(() => {
                                registerSW(username, password, url, connectionType, host);
                            });
                        }
                    });
            }
        });
};

export const loggedOut = () => dispatch => {
    dispatch({
        type: LOGGED_OUT
    });
    Reflect.deleteProperty(global, 'session');
};


export const registerSW = (username, password, url, connectionType, host) => {
    if ('serviceWorker' in navigator) {
        if (global.registration) {
            serviceWorkerLogin(username, password, url, connectionType, host);
        } else {
            navigator.serviceWorker.register('serviceWorker.js')
                .then(() => navigator.serviceWorker.ready)
                .then(registration => {
                    global.registration = registration;
                    console.log('Service Worker is ready', registration);
                    serviceWorkerLogin(username, password, url, connectionType, host);
                })
                .catch(function (error) {
                    console.log('Service worker registration error: ', error);
                });
        }
    }
};

const serviceWorkerLogin = async (username, password, url, connectionType, host) => {
    if (username) {
        await global.registration.active.postMessage({
            action: 'login',
            params: { username, password, url, connectionType, host }
        });
        console.log("Service worker: Posted session message");
        await global.registration.sync.register('api_actions');
        // await global.registration.periodicSync.register('api_actions', {
        //     minInterval: 24 * 60 * 60 * 1000,
        // });
        const registration = await navigator.serviceWorker.ready;
        if ('periodicSync' in registration) {
            // Request permission
            const status = await navigator.permissions.query({
                name: 'periodic-background-sync',
            });
            if (status.state === 'granted') {
                try {
                    // Register new sync every 24 hours
                    await registration.periodicSync.register('api_actions', {
                        minInterval: 60 * 60 * 1000, // 1 h
                    });
                    console.log('Periodic background sync registered!');
                } catch (e) {
                    console.error(`Periodic background sync failed:\n${e}`);
                }
            }
        }
    }
};

export const logOut = () => (dispatch) => {
    //todo: notify BE about logout
    dispatch({ type: LOGGED_OUT });
};
