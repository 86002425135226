import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ActionViewList from '@material-ui/icons/ViewList';
import { DropTarget } from 'react-dnd';
import { green } from "@material-ui/core/colors";
import { bindActionCreators } from "redux";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { connect } from "react-redux";
import Project from './Project';
import { getValue, getId, getParentsIdArray } from '../../../utils/data';
import { addNewElement } from '../../../actions/data';

const green200 = green[200];

class ProjectCategory extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            // projects: [],
            allowDropStyle: {}, // necessary to highlight an element if drop is allowed
            unfolded: !this.props.archived
        };
        this.handleCollapse = this.handleCollapse.bind(this);
    }

    componentWillMount () {
        if (this.props.viewId) {
            const parentIds = getParentsIdArray(this.props.viewId);
            const archived = this.props.archived ? 'archived' : 'current';
            if (parentIds.includes(archived)) {
                this.setState({ unfolded: true });
            }
        }
    }


    componentWillReceiveProps (nextProps, nextContext) {
        // here we check if if an object is hovered over current component
        // if it is, the component is highlighted in green
        if (!this.props.isOver && nextProps.isOver) {
            // hover enter handler
            this.setState({ allowDropStyle: { backgroundColor: green200 } });
        }

        // highlighting is cancelled when an object stops hovering over the component
        if (this.props.isOver && !nextProps.isOver) {
            // hover leave handler
            this.setState({ allowDropStyle: {} });
        }

        this.setState({ projects: this.props.projects });
    }

    handleCollapse = () => {
        this.setState(state => ({ unfolded: !state.unfolded }));
    };

    render () {
        let nestedItems = this.props.projects
            .filter(project => getValue(project, 'archived').toString() === this.props.archived.toString())
            .map(
                (project, idx) => (
                    <Project
                        key={getId(project) + idx.toString()}
                        project={project}
                    />
                )
            );
        let unfoldButton = null;
        let collapse = null;
        if (nestedItems.length) {
            unfoldButton = (
                <ListItemSecondaryAction>
                    <IconButton onClick={this.handleCollapse}>
                        {this.state.unfolded ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </ListItemSecondaryAction>
            );
            collapse = (
                <Collapse in={this.state.unfolded}>
                    <List>
                        {nestedItems}
                    </List>
                </Collapse>
            );
        }
        return (
            <div>
                <List>
                    {this.props.connectDropTarget(<div>
                        <ListItem
                            button
                            dense
                            onClick={() => this.handleCollapse(this.props.label)}
                            className="listItem"
                            style={this.state.allowDropStyle}
                        >
                            <ListItemIcon>
                                <ActionViewList/>
                            </ListItemIcon>
                            <ListItemText>
                                {this.props.label}
                            </ListItemText>
                            {unfoldButton}
                        </ListItem></div>
                    )}
                    {collapse}
                </List>
            </div>
        );
    }
}

ProjectCategory.propTypes = {
    label: PropTypes.string,
    archived: PropTypes.bool,
    // redux props
    projects: PropTypes.array.isRequired,
    viewId: PropTypes.string,
    // dnd props
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired
};

ProjectCategory.defaultProps = {
    label: 'Projects Category',
    archived: false
};


//below are the settings for dnd target
const targetSpecs = {
    drop (props/*, monitor, component*/) {
        props.addNewElement('project', props.archived);
        return {};
    }
};

const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});


// redux settings
const mapStateToProps = state => ({
    projects: state.data.projects,
    viewId: state.view.elementPrimaryKey
});

const mapDispatchToProps = dispatch => bindActionCreators({ addNewElement }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
    // eslint-disable-next-line new-cap
    DropTarget('Project', targetSpecs, collect)(ProjectCategory)
);
