import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FileFolder from '@material-ui/icons/Folder';
import { DropTarget, DragSource } from "react-dnd";
// import VisualAssetsList from './VisualAssets/VisualAssetsList';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { green } from "@material-ui/core/colors";
import { viewElement } from "../../../actions/view";
import { moveSite, addNewElement } from "../../../actions/data";
import {
    filterCollectionByParentSorted,
    getId,
    getName,
    getParentId,
    getParentsIdArray,
    getSequence
} from '../../../utils/data';
import flow from 'lodash/flow';
import VisualAsset from "./VisualAssets/VisualAsset";

const green50 = green[50];
const green200 = green[200];

class Site extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            allowDropStyle: {}, // necessary to highlight an element if drop is allowed
            unfolded: true
        };
    }

    componentWillMount () {
        if (this.props.viewId) {
            const parentIds = getParentsIdArray(this.props.viewId);
            if (parentIds.includes(getId(this.props.site))) {
                this.setState({ unfolded: true });
            }
        }
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.isOver && nextProps.isOver) {
            // hover enter handler
            this.setState({ allowDropStyle: { backgroundColor: green200 } });
        }

        // highlighting is cancelled when an object stops hovering over the component
        if (this.props.isOver && !nextProps.isOver) {
            // hover leave handler
            this.setState({ allowDropStyle: {} });
        }
    }


    handleCollapse = () => {
        this.setState({ unfolded: !this.state.unfolded });
    };


    render () {
        let selected = {};
        if (this.props.viewId === getId(this.props.site) && this.props.viewType.toLowerCase() === 'site') {
            selected = {
                backgroundColor: green50
            };
        }
        const nestedItems = filterCollectionByParentSorted(this.props.visualAssets,
            this.props.site)
            .map(asset => (
                <VisualAsset
                    key={getId(asset)}
                    asset={asset}
                />
            ));
        let collapse = null;
        let unfoldButton = null;
        let unfoldIcon = null;
        if (nestedItems.length) {
            collapse = (
                <Collapse in={this.state.unfolded}>
                    <List>
                        {nestedItems}
                    </List>
                </Collapse>
            );
            unfoldIcon = this.state.unfolded ? <ExpandLess/> : <ExpandMore/>;
            unfoldButton = (
                <IconButton onClick={this.handleCollapse}>
                    {unfoldIcon}
                </IconButton>
            );
        }
        return (
            <div>
                {this.props.connectDropTarget(this.props.connectDragSource(
                    <div>
                        <ListItem
                            dense
                            button
                            onClick={() => {
                                this.props.viewElement('Site', getId(this.props.site));
                            }}
                            style={{ ...selected, ...this.state.allowDropStyle, paddingLeft: 24 + 10 * 2 }}
                        >
                            <ListItemIcon>
                                <FileFolder/>
                            </ListItemIcon>
                            <ListItemText>
                                {getName(this.props.site)}
                            </ListItemText>
                            <ListItemSecondaryAction>
                                {unfoldButton}
                            </ListItemSecondaryAction>
                        </ListItem>
                    </div>
                ))}
                {collapse}
            </div>
        );
    }
}

Site.propTypes = {
    site: PropTypes.shape().isRequired,
    visualAssets: PropTypes.array.isRequired,
    // redux props
    viewType: PropTypes.string,
    viewId: PropTypes.string,
    // redux actions
    viewElement: PropTypes.func.isRequired,
    addNewElement: PropTypes.func.isRequired,
    // dnd
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired
};
Site.defaultProps = {};

// redux settings
const mapStateToProps = state => ({
    sites: state.data.sites,
    viewId: state.view.elementPrimaryKey,
    viewType: state.view.elementType,
    visualAssets: state.data.visualAssets
});

const mapDispatchToProps = dispatch => bindActionCreators({
    viewElement,
    moveSite,
    addNewElement
}, dispatch);

// dnd source settings
const sourceSpecs = {
    beginDrag (props/*, monitor, component*/) {
        return {
            metaTypeName: 'site',
            id: getId(props.site)
        };
    }
};

const dndSourceCollect = (connect/*, monitor*/) => ({ connectDragSource: connect.dragSource() });

// dnd target settings
const targetSpecs = {
    drop (props, monitor/*, component*/) {
        const item = monitor.getItem();
        if (["Map", "Plan", "Drawing", "Photo"].includes(item.dragType)) {
            props.addNewElement(item.dragType.toLowerCase(), getId(props.site));
        } else if (item.id !== getId(props.site)) {
            props.moveSite(item.id, getSequence(props.site));
        }
    }
};

const dndTargetCollect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver()
});

const getSourceTypes = props => 'Site_Project_' + getParentId(props.site);
const getTargetTypes = props => ['Site_Project_' + getParentId(props.site), "Photo", "Drawing", "Map", "Plan"];

export default flow(
    // eslint-disable-next-line new-cap
    DragSource(getSourceTypes, sourceSpecs, dndSourceCollect),
    // eslint-disable-next-line new-cap
    DropTarget(getTargetTypes, targetSpecs, dndTargetCollect),
    connect(mapStateToProps, mapDispatchToProps)
)(Site);
