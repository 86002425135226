import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import FileFolder from '@material-ui/icons/Folder';
import Site from './Site';
import { DropTarget } from "react-dnd";
import { green } from "@material-ui/core/colors";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { viewElement } from '../../../actions/view';
import { addNewElement, moveSite } from '../../../actions/data';
import {
    filterCollectionByParentSorted,
    getId,
    getMinFieldValue,
    getName,
    getParentsIdArray
} from '../../../utils/data';


const green200 = green[200];
const green50 = green[50];

class Project extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            allowDropStyle: {}, // necessary to highlight an element if drop is allowed
            unfolded: false
        };
        this.handleCollapse = this.handleCollapse.bind(this);
    }

    componentWillMount () {
        if (this.props.viewId) {
            const parentIds = getParentsIdArray(this.props.viewId);
            if (parentIds.includes(getId(this.props.project))) {
                this.setState({ unfolded: true });
            }
        }
    }

    componentWillReceiveProps (nextProps, nextContext) {
        // here we check if if an object is hovered over current component
        // if it is, the component is highlighted in green
        if (!this.props.isOver && nextProps.isOver) {
            // hover enter handler
            this.setState({ allowDropStyle: { backgroundColor: green200 } });
        }

        // highlighting is cancelled when an object stops hovering over the component
        if (this.props.isOver && !nextProps.isOver) {
            // hover leave handler
            this.setState({ allowDropStyle: {} });
        }
    }

    handleCollapse = () => {
        this.setState({ unfolded: !this.state.unfolded });
    };

    render () {
        let selected = {};
        if (this.props.viewId === getId(this.props.project) && this.props.viewType.toLowerCase() === 'project') {
            selected = {
                backgroundColor: green50
            };
        }
        const nestedItems = filterCollectionByParentSorted(this.props.sites, this.props.project)
            .map(
                site => (
                    <Site
                        key={getId(site)}
                        site={site}
                    />
                )
            );
        let unfoldButton = null;
        let collapse = null;
        if (nestedItems.length) {
            unfoldButton = (
                <ListItemSecondaryAction>
                    <IconButton onClick={this.handleCollapse}>
                        {this.state.unfolded ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </ListItemSecondaryAction>
            );
            collapse = (
                <Collapse in={this.state.unfolded}>
                    <List>
                        {nestedItems}
                    </List>
                </Collapse>
            );
        }
        return (
            <div>
                {this.props.connectDropTarget(
                    <div>
                        <ListItem
                            button
                            dense
                            onClick={() => {
                                this.props.viewElement('Project', getId(this.props.project));
                            }}
                            style={{ ...selected, ...this.state.allowDropStyle, paddingLeft: 24 + 10 }}
                        >
                            <ListItemIcon>
                                <FileFolder/>
                            </ListItemIcon>
                            <ListItemText>
                                {getName(this.props.project)}
                            </ListItemText>
                            {unfoldButton}
                        </ListItem>
                    </div>
                )}
                {collapse}
            </div>
        );
    }
}

Project.propTypes = {
    project: PropTypes.shape().isRequired,
    viewType: PropTypes.string,
    viewId: PropTypes.string,
    // redux actions
    sites: PropTypes.array.isRequired,
    viewElement: PropTypes.func.isRequired,
    addNewElement: PropTypes.func.isRequired,
    moveSite: PropTypes.func.isRequired,
    // dnd props below
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired
};
Project.defaultProps = {};

//dnd settings
const targetSpecs = {
    drop (props, monitor/*, component*/) {
        const dragType = monitor.getItemType();
        if (dragType === 'Site') {
            props.addNewElement('Site', getId(props.project));
        } else {
            const item = monitor.getItem();
            // console.log(item);
            const sequence = getMinFieldValue(props.sites, 'sequence');
            props.moveSite(item.id, sequence);
        }
        return {};
    }
};

const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});


// redux settings
const mapStateToProps = state => ({
    sites: state.data.sites,
    viewId: state.view.elementPrimaryKey,
    viewType: state.view.elementType
});

const mapDispatchToProps = dispatch => bindActionCreators({
    viewElement,
    addNewElement,
    moveSite
}, dispatch);

const getDnDTargetTypes = props => ['Site', 'Site_Project_' + getId(props.project)];

export default connect(mapStateToProps, mapDispatchToProps)(
    // eslint-disable-next-line new-cap
    DropTarget(getDnDTargetTypes, targetSpecs, collect)(Project)
);
