import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import './AddItemLabel.css';

class AddItemLabel extends Component {
    render () {
        // need to wrap the return into connectDragSource for dnd to work
        return this.props.connectDragSource(
            <div className="addItem">{this.props.label}</div>
        );
    }
}

AddItemLabel.propTypes = {
    label: PropTypes.string.isRequired,
    connectDragSource: PropTypes.func
};
AddItemLabel.defaultProps = {};

// This will return the label of the item as a type
const getType = props => props.type ? props.type : props.label;

const dndSpecs = {
    // begin Drag sets the information transferred to the target
    beginDrag (props) {
        return { dragType: props.type ? props.type : props.label };
    }
};

const collect = connect => ({ connectDragSource: connect.dragSource() });

// eslint-disable-next-line new-cap
export default DragSource(getType, dndSpecs, collect)(AddItemLabel);
