import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from "react-dnd";
import { Rect } from 'react-raphael';

class AnnotationPathNode extends Component {
    render () {
        const { x, y } = this.props.point;
        const distance = 6;
        return (
            <Rect
                x={x - distance}
                y={y - distance}
                width={distance * 2}
                height={distance * 2}
                attr={{
                    border: '1px solid black',
                    fill: 'rgba(0,0,0,0.01)',
                    zIndex: 300000
                }}
                ref={ref => {
                    if (ref) {
                        ref.getElement().node.ondblclick = () => {
                            this.props.removeNodeCallback({
                                annotationId: this.props.annotationId,
                                initialPoint: this.props.point
                            });
                        };
                        this.props.connectDragSource(ref.getElement().node);
                    }
                }}
            />
        );
    }
}

AnnotationPathNode.propTypes = {
    point: PropTypes.shape({ x: PropTypes.number.isRequired, y: PropTypes.number.isRequired }).isRequired,
    annotationId: PropTypes.string.isRequired,
    removeNodeCallback: PropTypes.func.isRequired,
    //dnd
    connectDragSource: PropTypes.func.isRequired
};
AnnotationPathNode.defaultProps = {};


// dnd source
const dndSpecs = {
    // begin Drag sets the information transferred to the target
    beginDrag (props) {
        return {
            dragType: "AnnotationPathNode",
            annotationId: props.annotationId,
            initialPoint: props.point
        };
    }
};

const collect = connect => ({ connectDragSource: connect.dragSource() });

// eslint-disable-next-line new-cap
export default DragSource("AnnotationPathNode", dndSpecs, collect)(AnnotationPathNode);
