import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Stepper from '@material-ui/core/Stepper/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Papa from 'papaparse';
import times from 'lodash.times';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { getId, getName, getValue } from "../../../utils/data";

class CsvImport extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            mappings: [],
            currentMapping: '',
            currentMappingContent: [],
            importDialogOpen: false,
            importDialogStepIndex: 0,
            importedData: [],
            importFile: null,
            importFileSelectError: null,
            importPreviewRows: [],
            createMappingMode: false,
            newMappingName: '',
            newMappingColumns: {},
            importFileProcessError: null,
            importRowsToSkip: 0
        };
        this._onImportDialogClose = this._onImportDialogClose.bind(this);
        this._onMappingChange = this._onMappingChange.bind(this);
        this._onPrevHandler = this._onPrevHandler.bind(this);
        this._onNextHandler = this._onNextHandler.bind(this);
        this._onFileSelected = this._onFileSelected.bind(this);
        this._getHeaderRow = this._getHeaderRow.bind(this);
        this._createMappingMode = this._createMappingMode.bind(this);
        this._cancelMappingCreation = this._cancelMappingCreation.bind(this);
        this._saveNewMapping = this._saveNewMapping.bind(this);
        this._checkMappingCanBeSaved = this._checkMappingCanBeSaved.bind(this);
    }


    // field to save link to the file field
    _importFileUploadField;


    _onMappingChange = event => {
        const currentMapping = this.props.mappings.find(mapping => getId(mapping).toString() === event.target.value);
        const currentMappingContent = JSON.parse(getValue(currentMapping, 'csvmapjson'));
        this.setState({
            currentMapping: event.target.value,
            currentMappingContent
        });
    };

    _onImportDialogClose () {
        // clearing import data on dialog close
        this.setState({
            importDialogStepIndex: 0,
            importDialogOpen: false,
            importFile: null,
            importFileSelectError: null,
            importPreviewRows: [],
            currentMapping: '',
            createMappingMode: false,
            newMappingName: '',
            newMappingColumns: {},
            importFileProcessError: null,
            importRowsToSkip: 0
        });
    }

    /*
        _getHeaderColumns = sampleRow => {
            // if table already has its header row
            if (this.props.headerColumns && this.props.headerColumns.length) return this.props.headerColumns;
            // if no header row provided via props but there is a mapping selected
            if (this.state.currentMapping) return this.state.currentMapping.columns;
            // if there is no source for the heading, creating a dummy row
            const num = sampleRow.length;
            let columns = [];
            for (let i = 1; i <= num; ++i) {
                columns.push(`Column ${i}`);
            }
            return columns;
        };
    */

    _processImportRows () {
        Papa.parse(this.state.importFile, {
            skipEmptyLines: true,
            complete: results => {
                let resultRows = results.data;
                if (this.state.importRowsToSkip > 0) {
                    resultRows.splice(0, this.state.importRowsToSkip);
                }
                let rows = resultRows.map(row => {
                    return {
                        columns: this.props.headerColumns.map(column => {
                            let value;
                            if (this.state.currentMappingContent.indexOf(column) <= row.length + 1) {
                                value = row[this.state.currentMappingContent.indexOf(column)];
                            } else {
                                value = '';
                            }
                            return ({ value });
                        })
                    };
                });
                this.props.onImport(rows);
                this._onImportDialogClose();
            },
            error: error => this.setState({ importFileProcessError: error })
        });
        // this.setState({ importPreviewRows });
    }

    _canImport = () => this.state.currentMappingContent.length === this.props.headerColumns.length &&
        this.state.importPreviewRows[0].length === this.state.currentMappingContent.length;

    _onNextHandler () {
        switch (this.state.importDialogStepIndex) {
            case 0:
                this._onFileSelected();
                Papa.parse(this.state.importFile, {
                    skipEmptyLines: true,
                    preview: 5,
                    complete: (results) => {
                        // this._processImportRows(results.data);
                        this.setState({ importPreviewRows: results.data, importDialogStepIndex: 1 });
                    },
                    error: error => this.setState({ importFileSelectError: error })
                });
                break;
            case 1:
                this._processImportRows();
                break;
        }
    }

    _onPrevHandler () {
        switch (this.state.importDialogStepIndex) {
            case 1:
                this.setState({
                    importDialogStepIndex: 0,
                    currentMapping: '',
                    importFile: null,
                    createMappingMode: false,
                    newMappingName: '',
                    newMappingColumns: {},
                    importFileProcessError: null,
                    importRowsToSkip: 0
                });
                break;
        }
    }

    _onFileSelected () {
        this.setState({ importFile: this._importFileUploadField.files[0] });
    }

    _createMappingMode () {
        // const newMappingColumns = {};
        // times(this.props.headerColumns.length, index => {newMappingColumns[index] = null;});
        this.setState({
            createMappingMode: true,
            currentMapping: ''
            // newMappingColumns
        });
    }

    _saveNewMapping () {
        const name = this.state.newMappingName;
        const columns = times(
            this.props.headerColumns.length,
            index => this.state.newMappingColumns['mc' + index]
        );
        const csvmapjson = JSON.stringify(columns);
        const newCsvImportMap = this.props.addNewElement('csvimport', null, { name, csvmapjson });
        this.setState({
            currentMapping: getId(newCsvImportMap),
            currentMappingContent: columns,
            createMappingMode: false
        });
    }


    _containsDuplicates = array => {
        return array
            .sort()
            .some(function (item, i, items) {
                return item === items[i + 1];
            });
    };

    _checkMappingCanBeSaved () {
        // checking if all columns have a mapped value
        let canBeSaved = Object.keys(this.state.newMappingColumns).length === this.state.importPreviewRows[0].length;
        // checking if no column has been mapped twice or more time
        canBeSaved = canBeSaved && !this._containsDuplicates(Object.values(this.state.newMappingColumns));
        // checking if mapping name has been entered
        canBeSaved = canBeSaved && !!this.state.newMappingName;
        return canBeSaved;
    }

    _cancelMappingCreation () {
        this.setState({ createMappingMode: false, newMappingName: '', newMappingColumns: {} });
    }

    _getHeaderRow () {
        const mappingFieldSelector = index => (
            <SelectField
                onChange={event => {
                    const newMappingColumns = this.state.newMappingColumns;
                    newMappingColumns['mc' + index] = event.target.value;
                    this.setState({ newMappingColumns });
                }}
                value={this.state.newMappingColumns['mc' + index] ? this.state.newMappingColumns['mc' + index] : ''}
                style={{ width: "100%" }}
            >
                {this.props.headerColumns.map(
                    column => (
                        <MenuItem
                            key={column}
                            value={column}
                        >{column}</MenuItem>)
                )}
            </SelectField>
        );

        return (
            <TableHead>
                <TableRow style={{ borderBottom: "1px solid grey", paddingLeft: 0 }}>
                    {
                        times(this.state.importPreviewRows[0].length,
                            index => {
                                if (this.state.currentMapping && this.state.currentMappingContent) {
                                    return (<TableCell key={index}>
                                        {this.state.currentMappingContent[index]}</TableCell>);
                                } else {
                                    return this.state.createMappingMode ?
                                        (
                                            // if mapping creation mode is switched on
                                            <TableCell key={index}>
                                                {mappingFieldSelector(index)}
                                            </TableCell>) :
                                        // if no mappings are set
                                        <TableCell key={index}>Unmapped column</TableCell>;
                                }
                            }
                        )
                    }
                </TableRow>
            </TableHead>
        );
    }

    render () {
        // showing import type on mappings screen
        const importType = this.props.importType ? (
            <div style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-start"
            }}>
                <div>{this.props.importTypeLabel}:&nbsp;</div>
                <div>{this.props.importType}</div>
            </div>
        ) : null;

        // dialog content depending on current step
        let dialogContent;
        switch (this.state.importDialogStepIndex) {
            case 0:
                dialogContent = (
                    <div>
                        <label style={{ marginRight: 10 }}>Select a csv file:</label>
                        <input
                            type="file"
                            id="csvImportFileSelector"
                            onChange={this._onFileSelected}
                            // saving link to this field in local class property
                            // eslint-disable-next-line no-return-assign
                            ref={(ref) => this._importFileUploadField = ref}
                            accept=".csv,text/csv"
                        />
                        <div>{this.state.importFileSelectError}</div>
                    </div>
                );
                break;
            case 1:
                dialogContent = (
                    <div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                            {/* Label like "Measurement type: Measurement type 1" */}
                            {importType}
                            <div style={{ flex: 1 }}>
                                {this.state.createMappingMode ? (
                                    // if create mapping button clicked
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginRight: 10 }}>
                                            <TextField
                                                onChange={event => this.setState({
                                                    newMappingName: event.target.value
                                                })}
                                                value={this.state.newMappingName}
                                                label="New mapping name"
                                                errorText={!this.state.newMappingName && "Enter name of the mapping"}
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div>
                                                <IconButton
                                                    variant="contained"
                                                    onClick={this._saveNewMapping}
                                                    tooltip="Save new mapping"
                                                    style={{ marginRight: 10 }}
                                                    disabled={!this._checkMappingCanBeSaved()}
                                                >
                                                    <SaveIcon/>
                                                </IconButton>
                                            </div>
                                            <div>
                                                <IconButton
                                                    variant="contained"
                                                    onClick={this._cancelMappingCreation}
                                                    tooltip="Cancel mapping creation"
                                                >
                                                    <CancelIcon/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>) : (
                                    // select mapping mode (dropdown)
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginRight: 10 }}>
                                            <SelectField
                                                name="file"
                                                onChange={this._onMappingChange}
                                                value={this.state.currentMapping}
                                            >
                                                {this.props.mappings.map(
                                                    mapping => (
                                                        <MenuItem
                                                            key={getId(mapping)}
                                                            value={getId(mapping)}
                                                        >{getName(mapping)}</MenuItem>)
                                                )}
                                            </SelectField>
                                        </div>
                                        <div>
                                            <Button
                                                variant="contained"
                                                onClick={this._createMappingMode}
                                            >Create mapping</Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* Data preview table */}
                        <Table
                            style={{ width: "100%", marginTop: 10 }}
                        >
                            {/* row 1, data header */}
                            {this._getHeaderRow()}
                            {/* rows 2-6, data preview */}
                            <TableBody>
                                {this.state.importPreviewRows.map(
                                    (row, index) => (
                                        <TableRow
                                            style={{
                                                // display: "flex",
                                                // flexDirection: "row",
                                                borderBottom: "1px dashed grey"
                                            }}
                                            key={index}
                                        >
                                            {row.map(
                                                column => (
                                                    <TableCell
                                                        key={column}
                                                        // style={{ : 10 }}
                                                    >
                                                        {column}
                                                    </TableCell>
                                                )
                                            )}
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <label style={{ marginTop: 10, marginRight: 10 }}>Number of rows to skip:</label>
                            <TextField
                                type="number"
                                id="importRowsToSkip"
                                onChange={event => this.setState({ importRowsToSkip: event.target.value })}
                                value={this.state.importRowsToSkip}
                            />
                        </div>
                        {this.state.importFileSelectError ? <div>this.state.importFileSelectError</div> : null}
                    </div>
                );
        }
        return (
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <Button
                    variant="contained"
                    onClick={() => this.setState({ importDialogOpen: true })}
                >
                    {this.props.buttonLabel}
                </Button>
                <Dialog
                    open={this.state.importDialogOpen}
                    onRequestClose={this._onImportDialogClose}
                    contentStyle={{ display: "flex", flexDirection: "column", maxWidth: "none" }}
                    autoScrollBodyContent
                >
                    <div style={{ padding: 20 }}>
                        <Stepper activeStep={this.state.importDialogStepIndex}>
                            <Step>
                                <StepLabel>Select a csv file</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Map fields</StepLabel>
                            </Step>
                        </Stepper>
                        {dialogContent}
                        <div style={{ marginTop: 12 }}>
                            <Button
                                variant="flat"
                                onClick={() => this.setState({ importDialogOpen: false })}
                                style={{ marginRight: 12 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="flat"
                                disabled={this.state.importDialogStepIndex === 0}
                                onClick={this._onPrevHandler}
                                style={{ marginRight: 12 }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                disabled={
                                    (this.state.importDialogStepIndex === 0 && !this.state.importFile) ||
                                    (this.state.importDialogStepIndex === 1 && !this.state.currentMapping) ||
                                    (this.state.importDialogStepIndex === 1 && !this._canImport())
                                }
                                onClick={this._onNextHandler}
                            >
                                {this.state.importDialogStepIndex === 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

CsvImport.propTypes = {
    headerColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    onImport: PropTypes.func.isRequired,
    previewLinesNumber: PropTypes.number,
    importTypeLabel: PropTypes.string,
    importType: PropTypes.string,
    buttonLabel: PropTypes.string,
    mappings: PropTypes.array.isRequired,
    addNewElement: PropTypes.func.isRequired
};
CsvImport.defaultProps = {
    importDialogOpen: false,
    previewLinesNumber: 5,
    buttonLabel: 'Import',
    importTypeLabel: 'Type',
    importType: ''
};

export default CsvImport;
