import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import FlexView from 'react-flexview';
import { DropTarget } from 'react-dnd';
import entity from "../../../../composition/entity";
import Toolbar from './Toolbar';
import TextField from '@material-ui/core/TextField';
import DeleteButton from '../DeleteModal';
import ReactDOM from 'react-dom';
import {
    filterCollectionByParent,
    filterCollectionByParentSorted,
    getId,
    // getMaxFieldValue,
    getValue
} from "../../../../utils/data";
import OneColumn from "./Layouts/OneColumn";
import TwoColumns from "./Layouts/TwoColumns";
import './measurement.css';

const styles = theme => ({
    root: {
        width: '90%',
        margin: theme.spacing(3)
    },
    heading: {
        fontSize: 28,
        fontVariant: 'small-caps'
    },
    subText: {
        fontSize: 12
    },
    subHeading: {
        fontSize: 13
    },
    subHeadingBold: {
        fontSize: 13
    },
    button: {
        margin: theme.spacing(),
        backgroundColor: '#F56260',
        color: '#FFFFFF'
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        margin: 0
    }
});

class Measurement extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            locked: false
        };
        this.toggleLock = this.toggleLock.bind(this);
    }

    componentWillMount () {
        if (this.props.viewRef &&
        this.props.viewRef.current) {
            this.props.viewRef.current.addEventListener('scroll', this.onScroll);
        }
    }

    componentDidMount () {
        if (this.toolbar2Ref) {
            const el = ReactDOM.findDOMNode(this.toolbar2Ref);
            this.toolbar2El = el;
            el.style.position = 'absolute';
            el.style.width = 'auto';
            el.style.top = 0;
            el.style.left = this.toolbarRef.getBBox().x + 'px';
            el.style.visibility = 'hidden';
        }
    }

    componentWillUnmount () {
        if (this.props.viewRef &&
            this.props.viewRef.current) {
            this.props.viewRef.current.removeEventListener('scroll', this.onScroll);
        }
        window.toolbarRef = null;
    }

    onScroll = () => {
        if (this.toolbarRef && !this.state.locked) {
            const rect = this.toolbarRef.getBBox();
            if (rect.y < 100) {
                this.toolbar2El.style.top = 100 + 'px';
                this.toolbar2El.style.visibility = 'visible';
            }
            if (rect.y >= 100) {
                this.toolbar2El.style.top = 100 + 'px';
                this.toolbar2El.style.visibility = 'hidden';
            }
        }
    };

    toggleLock = () => {
        this.setState({ locked: !this.state.locked });
        this.toolbar2El.style.visibility = 'hidden';
    };

    toolbarRef = null;

    render () {
        const { classes } = this.props;

        let layouts = filterCollectionByParent(this.props.data.layouts, this.props.id)
            .map(layout => {
                if (getValue(layout, 'type') === '0') {
                    const blocks = filterCollectionByParentSorted(this.props.data.blocks, getId(layout));
                    return (
                        <OneColumn
                            id={getId(layout)}
                            key={getId(layout)}
                            addNewElement={this.props.addNewElement}
                            blocks={blocks}
                            locked={this.state.locked}
                            updateCallback={this.props.updateElement}
                        />
                    );
                } else if (getValue(layout, 'type') === '1') {
                    const position = getValue(layout, 'position');
                    if (position === "0") {
                        const leftIndex = this.props.data.layouts.findIndex(l => getId(l) === getId(layout));
                        const rightCol = this.props.data.layouts.find((layout2, index) =>
                            // getValue(layout2, 'order') === getValue(layout, 'order') &&
                            index > leftIndex &&
                            getValue(layout2, 'position') === "1");
                        if (rightCol) {
                            const leftBlocks = filterCollectionByParentSorted(this.props.data.blocks, getId(layout));
                            const rightBlocks = filterCollectionByParentSorted(this.props.data.blocks, getId(rightCol));
                            return (
                                <TwoColumns
                                    leftColId={getId(layout)}
                                    rightColId={getId(rightCol)}
                                    leftBlocks={leftBlocks}
                                    rightBlocks={rightBlocks}
                                    key={getId(layout)}
                                    addNewElement={this.props.addNewElement}
                                    locked={this.state.locked}
                                    updateCallback={this.props.updateElement}
                                />
                            );
                        }
                        return null;
                    }
                    return null;
                }
            });
        if (!layouts) {
            layouts = (
                <div className={'layout1'}>
                    <span>No blocks found. Drag and drop some from the toolbar</span>
                </div>
            );
        }

        return (
            <FlexView className="container">
                <Paper
                    className={classNames(classes.root, "measurement")}
                    elevation={4}
                >
                    <FlexView className="obsRowThin"/>
                    <FlexView className="obsRowColour" style={{ justifyContent: 'space-between' }}>
                        <div>Measurement Details</div>
                        <div style={{ marginRight: 20, marginTop: 10 }}>
                            <DeleteButton
                                pk={this.props.id}
                                metaType={'measurement'}
                                deleteCallback={this.props.deleteElement}
                                objectType={'measurement'}
                            />
                        </div>
                    </FlexView>
                    <FlexView className="obsRowThinInvis"/>
                    <FlexView className="obsRow">
                        <FlexView className="obsCell-5"/>
                        <FlexView className={classNames("obsCell-21", "subHeading")}>
                            Measurement Name
                        </FlexView>
                        <FlexView className="obsCell-2"/>
                        <FlexView className="obsCell-67">
                            <form
                                className={classNames(classes.container, "observation")}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    id="oName"
                                    className={classes.textField}
                                    value={this.props.name}
                                    onChange={ev => {
                                        this.props.onChangeFieldValue({ name: ev.target.value });
                                    }}
                                    onBlur={() => {
                                        this.props.saveField('name');
                                    }}
                                    margin="normal"
                                    fullWidth
                                />
                            </form>
                        </FlexView>
                        <FlexView className="obsCell-5"/>
                    </FlexView>
                    <div>
                        <Toolbar
                            lockCallback={this.toggleLock}
                            locked={this.state.locked}
                            ref={ref => {
                                this.toolbarRef = ref;
                            }}
                        />
                    </div>
                    <div>
                        <Toolbar
                            lockCallback={this.toggleLock}
                            locked={this.state.locked}
                            ref={ref => {
                                this.toolbar2Ref = ref;
                            }}
                        />
                    </div>
                    {this.props.connectDropTarget(
                        <div
                            className={'layouts'}
                            style={this.props.isOver ? { backgroundColor: 'rgb(232, 245, 233)' } : {}}
                        >
                            {layouts && layouts.length ? layouts :
                                <div
                                    className={this.state.locked ? 'dropHereLayoutsLocked' : 'dropHereLayouts'}>
                                    {this.state.locked ? 'Add layouts in Edit mode' : 'Drop layouts here'}
                                </div>}
                        </div>)}
                </Paper>
            </FlexView>
        );
    }
}

Measurement.propTypes = {
    // object props from composition
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    parentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    comments: PropTypes.string.isRequired,
    inspection_date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    viewRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    // composition
    data: PropTypes.object.isRequired,
    dataStructure: PropTypes.array.isRequired,
    onChangeFieldValue: PropTypes.func.isRequired,
    saveField: PropTypes.func.isRequired,
    object: PropTypes.object.isRequired,
    delete: PropTypes.func.isRequired,
    // composition redux actions
    addNewElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    // dnd
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    // style
    classes: PropTypes.object.isRequired
};
Measurement.defaultProps = {};

//below are the settings for dnd target
const targetSpecs = {
    drop (props, monitor/*, component*/) {
        const item = monitor.getItem();
        // const layouts = filterCollectionByParent(props.data.layouts, props.id);
        // const order = getMaxFieldValue(layouts, 'order');
        if (item.dragType === 'Layout 1') {
            props.addNewElement('layout', props.id, { type: '0' });
        } else {
            props.addNewElement('layout', props.id, { type: '1' });
        }
        return {};
    }
};

const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});


export default entity(
    // eslint-disable-next-line new-cap
    DropTarget(['Layout 1', 'Layout 2'], targetSpecs, collect)(withStyles(styles)(Measurement)), 'measurement');
