import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ActionViewList from '@material-ui/icons/ViewList';
import { bindActionCreators } from "redux";
import { DropTarget, DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import ImageRemoveRedEye from '@material-ui/icons/RemoveRedEye';
import ImageStraighten from '@material-ui/icons/Straighten';
import { addNewElement, moveObservationOrMeasurement } from "../../../../../actions/data";
import { viewElement } from "../../../../../actions/view";
import { connect } from 'react-redux';
import { getId, getName, getMetaTypeName, getParentId, getSequence } from '../../../../../utils/data';
import { green } from "@material-ui/core/colors";

const green50 = green[50];
const green200 = green[200];

class LocationElement extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            allowDropStyle: {} // necessary to highlight an element if drop is allowed
        };
    }

    componentWillReceiveProps (nextProps, nextContext) {
        // here we check if if an object is hovered over current component
        // if it is, the component is highlighted in green
        if (!this.props.isOver && nextProps.isOver && nextProps.canDrop) {
            // hover enter handler
            this.setState({ allowDropStyle: { backgroundColor: green200 } });
        }

        // highlighting is cancelled when an object stops hovering over the component
        if (this.props.isOver && !nextProps.isOver) {
            // hover leave handler
            this.setState({ allowDropStyle: {} });
        }
    }

    getIcon = () => {
        const metaType = getMetaTypeName(this.props.element);
        if (metaType === 'observation') return <ImageRemoveRedEye/>;
        return <ImageStraighten/>;
    };

    render () {
        const { element } = this.props;
        let selected = {};
        if (this.props.viewId === getId(element)) {
            selected = {
                backgroundColor: green50
            };
        }
        return this.props.connectDropTarget(this.props.connectDragSource(
            <div>
                <ListItem
                    key={getId(element)}
                    button
                    dense
                    onClick={() => this.props.viewElement(getMetaTypeName(element), getId(element))}
                    style={{ ...selected, ...this.state.allowDropStyle, paddingLeft: 24 + 10 * 6 }}
                >
                    <ListItemIcon>
                        {this.getIcon()}
                    </ListItemIcon>
                    <ListItemText>
                        {getName(element)}
                    </ListItemText>
                </ListItem>
            </div>
        ));
    }
}

LocationElement.propTypes = {
    element: PropTypes.shape().isRequired,
    icon: PropTypes.object,
    // dnd props below
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    // redux props
    viewId: PropTypes.string,
    // redux actions
    viewElement: PropTypes.func,
    moveObservationOrMeasurement: PropTypes.func
};
LocationElement.defaultProps = {
    icon: <ActionViewList/>,
    data: []
};

// redux settings
const mapStateToProps = (state) => ({
    viewId: state.view.elementPrimaryKey
});

const mapDispatchToProps = dispatch => bindActionCreators({
    viewElement,
    addNewElement,
    moveObservationOrMeasurement
}, dispatch);

//dnd settings
const targetSpecs = {
    drop (props, monitor/*, component*/) {
        let item = monitor.getItem();
        let itemType = item.itemType;
        itemType = itemType.toLowerCase();
        if (itemType === 'observation') {
            if (monitor.getItemType() === 'observation_Location_' + getParentId(props.element)) {
                console.log('Attempt to move an observation');
                props.moveObservationOrMeasurement(item.id, getSequence(props.element));
            }
        } else if (itemType === 'measurement') {
            if (monitor.getItemType() === 'measurement_Location_' + getParentId(props.element)) {
                console.log('Attempt to move a measurement');
                props.moveObservationOrMeasurement(item.id, getSequence(props.element));
            }
        }
        return {};
    },
    canDrop (props, monitor) {
        let item = monitor.getItem();
        return item.id !== getId(props.element) && item.itemType === getMetaTypeName(props.element);
    }
};

const targetCollect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
    // itemType: monitor.getItemType()
});

const sourceSpecs = {
    beginDrag (props/*, monitor, component*/) {
        return {
            itemType: getMetaTypeName(props.element),
            id: getId(props.element)
        };
    }
};

const sourceCollect = (connect/*, monitor*/) => ({ connectDragSource: connect.dragSource() });


const getDropTypes = props =>
    [`${getMetaTypeName(props.element)}_Location_` + getParentId(props.element)];
const getitemType = props =>
    `${getMetaTypeName(props.element)}_Location_` + getParentId(props.element);

export default flow(
    // eslint-disable-next-line new-cap
    DragSource(getitemType, sourceSpecs, sourceCollect),
    // eslint-disable-next-line new-cap
    DropTarget(getDropTypes, targetSpecs, targetCollect),
    connect(mapStateToProps, mapDispatchToProps)
)(LocationElement);
