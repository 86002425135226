import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SelectField from '../../../../composition/selectField';


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
});

const SelectFieldObsStatus = props => (
    <SelectField
        id="status"
        {...props}
    />
);


SelectFieldObsStatus.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    options: PropTypes.array
};
SelectFieldObsStatus.defaultProps = {
    options: [
        { value: "1", name: 'Excellent' },
        { value: "2", name: 'Good' },
        { value: "3", name: 'Average' },
        { value: "4", name: 'Poor' },
        { value: "5", name: 'Very Poor' }
    ]
};
export default withStyles(styles)(SelectFieldObsStatus);
