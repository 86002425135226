import React from 'react';

const Timeout = Composition => class _Timeout extends React.Component {
    constructor (props) {
        super(props);
    }

    componentWillMount () {
        this.timeouts = [];
    }

    componentWillUnmount () {
        this.clearTimeouts();
    }

    setTimeout = (callback, freq) => {
        this.timeouts.push(setTimeout(callback, freq));
    };

    clearTimeouts = () => {
        this.timeouts.forEach(clearTimeout);
    };

    render () {
        const { timeouts, setTimeout, clearTimeouts } = this;

        return (<Composition
            timeouts={timeouts}
            setTimeout={setTimeout}
            clearTimeouts={clearTimeouts}
            {...this.props} />);
    }
};

export default Timeout;
