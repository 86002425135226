import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteElement } from "../../../../../actions/data";
import DeleteModal from '../../DeleteModal';
import EditColumnsMetasDialog from "./editColumnsMetasDialog";

const BlockTools = props => (
    <div className="blockTools">
        {props.columns && props.columns.length ? (
            <EditColumnsMetasDialog
                columns={props.columns}
            />
        ) : null
        }
        <DeleteModal
            objectType={'block'}
            onConfirm={() => {
                props.deleteElement('block', props.id);
            }}
        />
    </div>
);

BlockTools.propTypes = {
    id: PropTypes.string.isRequired,
    deleteElement: PropTypes.func.isRequired,
    columns: PropTypes.array
};
BlockTools.defaultProps = {
    columns: []
};

const mapStateToProps = () => ({});

const mapActionsToDispatch = dispatch => bindActionCreators({
    deleteElement
}, dispatch);

export default connect(mapStateToProps, mapActionsToDispatch)(BlockTools);

