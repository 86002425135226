import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlexView from 'react-flexview';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import entity from "../../../composition/entity";
import DeleteButton from './DeleteModal';
import HTMLEditor from "../../HTMLEditor";
// import { getId, getName } from "../../../utils/data";

const styles = theme => ({
    root: {
        margin: theme.spacing(3),
        width: "90%"
    },
    heading: {
        fontSize: 28,
        fontVariant: 'small-caps'
    },
    subText: {
        fontSize: 12
    },
    subHeading: {
        fontSize: 13
    },
    subHeadingBold: {
        fontSize: 13
    },
    button: {
        margin: theme.spacing()
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    }
});

class Site extends Component {
    render () {
        const { classes } = this.props;
        return (
            <div>
                <FlexView className="container">
                    <Paper className={classNames(classes.root, "observation")} elevation={4}>
                        <FlexView className="obsRowThin"/>
                        <FlexView className="obsRowColour" style={{ justifyContent: 'space-between' }}>
                            <div>Site</div>
                            <div style={{ marginRight: 20, marginTop: 10 }}>
                                <DeleteButton
                                    pk={this.props.id}
                                    metaType={'site'}
                                    deleteCallback={this.props.deleteElement}
                                    objectType={'site'}
                                />
                            </div>
                        </FlexView>
                        <FlexView className="rowThin"/>
                        <FlexView className="row">
                            <FlexView className="obsCell-5"/>
                            <FlexView className="cell-20">
                                Site Name
                            </FlexView>
                            <FlexView className="cell-68">
                                <TextField
                                    fullWidth
                                    label="Site Name"
                                    value={this.props.name}
                                    onChange={ev => {
                                        this.props.onChangeFieldValue({ name: ev.target.value });
                                    }}
                                    onBlur={() => {
                                        this.props.saveField('name');
                                    }}
                                />
                            </FlexView>
                            <FlexView className="obsCell-5"/>
                        </FlexView>
                        {/*
                        <FlexView className="row">
                            <FlexView className="obsCell-5"/>
                            <FlexView className="cell-20">
                                    Filename
                            </FlexView>
                            <FlexView className="cell-68">
                                <TextField
                                    fullWidth
                                    label="Filename"
                                />
                            </FlexView>
                            <FlexView className="obsCell-5"/>
                        </FlexView>
*/}
                        <FlexView className="editorRow">
                            <FlexView className="cell-5Auto"/>
                            <FlexView className="cell-20Auto">
                                Comments
                            </FlexView>
                            <FlexView className="editorCell-68">
                                <HTMLEditor
                                    value={this.props.description}
                                    onBlurCallback={description => this.props.saveField('description', description)}
                                />
                            </FlexView>
                            <FlexView className="cell-5Auto"/>
                        </FlexView>
                        <FlexView className="rowThin"/>
                    </Paper>
                </FlexView>
            </div>
        );
    }
}

Site.propTypes = {
    // object props from composition
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lat: PropTypes.string.isRequired,
    long: PropTypes.string.isRequired,
    elevation: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    // composition
    data: PropTypes.shape().isRequired,
    onChangeFieldValue: PropTypes.func.isRequired,
    saveField: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    // composition redux actions
    deleteElement: PropTypes.func.isRequired,
    // style
    classes: PropTypes.shape().isRequired
};
Site.defaultProps = {};

export default entity(withStyles(styles)(Site), 'site');
