import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import { Path, Text } from 'react-raphael';
import AnnotationPathBoundingSquare from './AnnotationPathBoundingSquare';
import Node from './AnnotationPathNode';

class AnnotationPath extends React.Component {
    constructor () {
        super();
        this.state = {
            boundingRect: {},
            moveDiv: null
        };
        this._handleResize = this._handleResize.bind(this);
    }

    componentDidMount () {
        this._rebuildPath();
    }

    // componentWillReceiveProps (nextProps/*, nextContext*/) {
    //     if (!nextProps.locked) {
    //         this._rebuildPath(nextProps);
    //     }
    // }

    componentDidUpdate (prevProps, prevState, prevContext) {
        if (!this.props.isCircle) {
            if (!this.props.locked && this.props.d !== prevProps.d && this.pathRef) {
                this._rebuildPath();
            }
        } else if (!this.props.locked &&
            (
                this.props.locX !== prevProps.locX ||
                this.props.locY !== prevProps.locY ||
                this.props.circleR !== prevProps.circleR
            ) && this.pathRef) {
            this._rebuildPath();
        }
    }

    _handleResize = () => {
        if (this.pathRef) {
            this._rebuildPath();
        }
    };

    _rebuildPath = (props) => {
        if (!props) props = this.props;
        if (this.pathRef) {
            if (!this.props.isCircle) {
                this._createPathNodes(props);
            }
            this._createBoundaryRect(props);

            // this a workaround to apply svg fill to a path
            this.pathRef.getElement().node.setAttribute('fill', this.props.attr.fill);
        }
    };

    _getBoundaryRect = () => this.pathRef ? this.pathRef.getElement().getBBox() : ({ x: 0, y: 0, width: 0, height: 0 });

    _createBoundaryRect = (props) => {
        // const boundingRect = this.pathRef.getElement().node.getBoundingClientRect();
        const boundingRect = this._getBoundaryRect();
        // const paperBoundingRect = document.querySelector(`[data-id='${this.pathRef.getElement().paper.id}`)
        //     .getBoundingClientRect();
        // console.log(paperBoundingRect);
        const moveDiv = (
            <AnnotationPathBoundingSquare
                id={props.id}
                className="path-rect"
                x={boundingRect.x}
                y={boundingRect.y}
                width={boundingRect.width}
                height={boundingRect.height}
                addNodeCallback={props.addNodeCallback}
            />
        );
        this.setState({ moveDiv });
    };

    _createPathNodes = (props) => {
        const nodes = props.d.match(/.(\d*\.?\d*,\d*\.?\d*)/g)
            .map(point => {
                return {
                    x: Number(point.split(',')[0].substr(1)),
                    y: Number(point.split(',')[1])
                };
            })
            .map((point, index) => (
                <Node
                    key={`ap${props.id}.${index}`}
                    point={point}
                    annotationId={props.id}
                    removeNodeCallback={props.removeNodeCallback}
                />)
            );
        this.setState({ nodes });
    };

    render () {
        const attrs = Object.assign({}, this.props.attr, { zIndex: 100 });
        Reflect.deleteProperty(attrs, 'fill');
        const moveDiv = this.props.locked ? null : this.state.moveDiv;
        const nodes = this.props.locked ? null : this.state.nodes;
        return this.props.isCircle ?
            (
                <div>
                    {moveDiv}
                    <Path
                        d="M 20.099 0 C 9.072 0 0.1 8.884 0.1 19.805 C 0.1 22.653 0.689 25.373 1.849 27.889 C 6.849 38.724 16.436 50.163 19.256 53.41 C 19.468 53.653 19.776 53.793 20.1 53.793 C 20.424 53.793 20.732 53.653 20.943 53.41 C 23.762 50.163 33.349 38.725 38.351 27.889 C 39.512 25.373 40.1 22.653 40.1 19.805 C 40.099 8.884 31.127 0 20.099 0 Z  M 4.583 20.345 C 4.583 11.781 11.536 4.828 20.1 4.828 C 28.664 4.828 35.617 11.781 35.617 20.345 C 35.617 28.909 28.664 35.862 20.1 35.862 C 11.536 35.862 4.583 28.909 4.583 20.345 Z"
                        ref={el => {
                            this.pathRef = el;
                        }}
                        // scale={{ x: 200, y: 200 }}
                        translate={{ x: this.props.locX - 20, y: this.props.locY - 53.79 }}
                    />
                    <Text
                        text={this.props.number.toString()}
                        x={this.props.locX}
                        y={this.props.locY - 34}
                        attr={{ 'font-size': 24, 'font-weight': 'bold', 'class': 'nonSelectable' }}
                    />
                </div>
            ) : (
                <div>
                    {moveDiv}
                    {nodes}
                    <Path
                        id={this.props.id}
                        d={this.props.d}
                        attr={attrs}
                        ref={el => {
                            this.pathRef = el;
                        }}
                    />
                    <Text
                        text={this.props.number.toString()}
                        x={this._getBoundaryRect().x + this._getBoundaryRect().width / 2}
                        y={this._getBoundaryRect().y + this._getBoundaryRect().height / 2}
                        attr={{ 'font-size': 24, 'font-weight': 'bold' }}
                        className={'nonSelectable'}
                    />
                    {/*{this._createBoundaryRect(this.props)}*/}
                    {/*{this._createPathNodes(this.props)}*/}
                </div>
            );
    }
}

AnnotationPath.propTypes = {
    id: PropTypes.string.isRequired,
    attr: PropTypes.shape().isRequired,
    locked: PropTypes.bool,
    removeNodeCallback: PropTypes.func.isRequired,
    addNodeCallback: PropTypes.func.isRequired,
    isCircle: PropTypes.bool,
    d: requiredIf(PropTypes.string, props => !props.isCircle),
    locX: requiredIf(PropTypes.number, props => props.isCircle),
    locY: requiredIf(PropTypes.number, props => props.isCircle),
    circleR: requiredIf(PropTypes.number, props => props.isCircle),
    number: requiredIf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]), props => props.isCircle),
    scale: PropTypes.number
    // connectDragSource: PropTypes.func.isRequired
};
AnnotationPath.defaultProps = {
    locked: false,
    isCircle: false,
    locX: 0,
    locY: 0,
    circleR: 25,
    scale: 1
};


export default AnnotationPath;
