/* global document */
import React, { Component } from 'react';
import { render } from 'react-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import 'react-flexview/lib/flexView.css';
import TreeNav from './components/tree-nav/TreeNav';
import listItems from './datanew';
import { Provider } from 'react-redux';
import store from './store';
import 'react-flexview/lib/flexView.css';
import Login from './login';
import { createMuiTheme } from '@material-ui/core/styles';
import { DragDropContextProvider } from "react-dnd";
import createTouchBackend from "react-dnd-touch-backend";

//import InspectItMainScreen from './components/inspectit.js';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1b437b',
            dark: '#02a1af',
            //dark: '#025e88',
            light: '#02a1af',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#F56260',
            contrastText: '#ffffff'
        },
        action: {
            hoverOpacity: 0
        }
    },
    overrides: {
        MuiSwitch: {
            '&$checked': {
                color: '#02a1af',
                bar: {
                    backgroundColor: '#02a1af'
                }
            }
        },
        MuiInput: {
            underline: {
                "&::before": {
                    content: '"."',
                    color: 'transparent'
                }
            }
        }
    }
});

class InspectIt extends Component {
    render () {
        return (
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <DragDropContextProvider backend={createTouchBackend({ enableMouseEvents: true })}>
                        <Login>
                            <TreeNav data={listItems}/>
                        </Login>
                    </DragDropContextProvider>
                </Provider>
            </MuiThemeProvider>
        );
    }
}

render(<InspectIt/>, document.querySelector('#app'));
