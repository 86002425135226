/* global require, document, Image */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ActionLock from '@material-ui/icons/Lock';
import ActionLockOpen from '@material-ui/icons/LockOpen';
import TableEdit from '../table-edit';
import LegendItem from './LegendItem';
import PinLegendItem from './PinLegendItem';
import AnnotatedImage from './AnnotatedImage';
import ErrorBoundary from '../errorBoundary';
import { getId, getMaxFieldValue, getMetaTypeName, getObjectById, getValue } from "../../utils/data";
import { isObject, isString } from "../../utils/types";
import { addUploadTask, getImageFromIDB } from "../../utils/image";
import { visualAssets } from "../../actions/data";
import MeasurementAnnotationLegendItem from './MeasurementAnnotationLegendItem';
import { set, Store } from "idb-keyval";

const blankImage = require('../../images/blank.jpg');

const fills = [
    'url(#hr-red)',
    'url(#diagonal-stripe-1-blue)',
    'url(#diagonal-stripe-1-green)',
    'url(#diagonal-stripe-2-yellow)',
    'url(#hr-blue)',
    'url(#vr-green)',
    'url(#diagonal-stripe-1-yellow)',
    'url(#diagonal-stripe-2-red)',
    'url(#hr-green)',
    'url(#vr-yellow)',
    'url(#diagonal-stripe-1-red)',
    'url(#diagonal-stripe-2-blue)',
    'url(#hr-yellow)',
    'url(#vr-red)',
    'url(#vr-blue)',
    'url(#diagonal-stripe-2-green)'
];

class AnnotatedObject extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            image: null,
            annotations: [],
            locked: false,
            annotationsVisible: true,
            hasError: false,
            undoActions: []
        };
        this._addAnnotation = this._addAnnotation.bind(this);
        this._editAnnotation = this._editAnnotation.bind(this);
    }

    componentWillMount () {
        this.mounted = true;
        this._updateProps();
    }

    componentWillReceiveProps (nextProps, nextContext) {
        this._updateProps(nextProps);
    }

    componentWillUnmount () {
        this.mounted = false;
    }

    getAnnotationTypes = () => {
        return this.props.annotationTypes;
    };

    _getImageFileName = image => {
        let filename = '';
        if (isObject(image)) {
            filename = getValue(image, 'filename');
        } else if (isString(image)) {
            filename = image;
        }
        return filename;
    };

    _updateProps = props => {
        if (!props) props = this.props;
        // getting image
        if (props.image) {
            let filename = this._getImageFileName(props.image);
            if (filename) {
                // noinspection JSIgnoredPromiseFromCall
                this._getImageFromLocalStorage(filename);
            }
        }

        let annotations;
        if (props.annotations && props.annotations.length) {
            if (this._isRenderAnnotationsAsCircles()) {
                annotations = props.annotations.map(
                    (annotation, index) => ({ index: { val: index + 1 }, ...annotation }));
            } else {
                annotations = props.annotations;
            }

            // setting default styles
            annotations = annotations.map(annotation => this._getAnnotationStyle(annotation));

            this.setState({ annotations });
        }

        if (isObject(props.image) && getValue(props.image, 'locked') !== undefined) {
            this.setState({ locked: getValue(props.image, 'locked') === 'true' });
        } else if (visualAssets.includes(this.props.nodeType)) {
            const visualAsset = getObjectById(this.props.nodeId);
            this.setState({ locked: getValue(visualAsset, 'locked') === 'true' });
        }
    };

    _getImageFromLocalStorage = async filename => {
        if (filename !== 'blank') {
            const image = await getImageFromIDB(filename);
            if (image) {
                if (this.mounted) {
                    this.setState({ image });
                }
            } else {
                console.log(`Error getting image ${filename} from localStorage - showing blank`);
                this.setState({ image: blankImage });
            }
        } else if (this.mounted) {
            this.setState({ image: blankImage });
        }
    };

    _isRenderAnnotationsAsCircles = () => ['photo', 'plan', 'drawing', 'map'].includes(this.props.nodeType);

    _tableEditHeaderColumns = () => {
        let columns;
        if (this._isRenderAnnotationsAsCircles()) {
            columns = [
                // { value: 'index', type: 'TextField', width: 20 },
                { value: 'sequence', type: 'readOnly', width: 80, label: 'no' },
                { value: 'name', type: 'TextField', width: '100%' }
            ];
        } else if (this.props.nodeType === 'measurementimage') {
            columns = [
                // { value: 'index', type: 'TextField', width: 20 },
                { value: 'no', type: 'readOnly', width: 80, label: 'no' },
                { value: 'name', type: 'TextField', width: '100%' }
            ];
        } else {
            columns = [
                { value: "no", type: "readOnly", width: 20 },
                { value: "annotation_type", type: "readOnly", width: 155, label: 'annotation type' },
                { value: "description", type: "TextField", width: '100%' }
            ];
        }
        return columns;
    };

    getAnnotationTypeStyle = annotationType => {
        if (annotationType === 'location') {
            return {
                fill: 'rgba(0,161,175, 1)'
            };
        }
        let typeIndex = this.props.annotationTypes.findIndex(at => at === annotationType);
        if (typeIndex < 0) typeIndex = 0;
        typeIndex += 1;
        const fillIndex = typeIndex % fills.length;
        const fill = fills[fillIndex - 1];
        return {
            color: 'grey',
            strokeWidth: "1",
            fill: fill
        };
    };

    _getAnnotationStyle = annotation => {
        let type;
        if (this._isRenderAnnotationsAsCircles()) {
            type = 'location';
        } else if (this.props.nodeType === 'measurementimage') {
            type = 'area';
        } else {
            type = getValue(annotation, 'annotation_type');
        }
        annotation.style = this.getAnnotationTypeStyle(type);
        return annotation;
    };

    _addUndoAction = action => {
        const undoActions = this.state.undoActions;
        undoActions.push(action);
        this.setState({ undoActions });
    };

    _undo = () => {
        const action = this.state.undoActions.pop();
        const { type, params } = action;
        switch (type) {
            case 'delete':
                if (params.metaType === 'location') {
                    this.props.deleteElement('location', params.pk);
                } else {
                    this.props.deleteElement(params.metaType, params.pk);
                    this.renumberAnnotationsAfterDelete(params.no);
                }
                break;
            case 'update':
                this.props.updateElement(params.metaType, params.pk, params.fields);
                break;
            case 'create':
                if (params.metaType === 'location') {
                    this.props.addNewElement('location', params.parentId, params.fields);
                } else {
                    this.props.addNewElement(params.metaType, params.parentId, params.fields);
                    this.renumberAnnotationsAfterRestore(params.fields[this.getNoFieldName()]);
                }
        }
        if (this.annotatedImage && this.annotatedImage.decoratedRef && this.annotatedImage.decoratedRef.current) {
            this.annotatedImage.decoratedRef.current.saveImageWAnnotations();
        }
    };

    _addAnnotation = (props) => {
        let annotationMetaType = 'annotation';
        const parentId = this.props.nodeType === 'observation' ?
            getId(this.props.image) : this.props.nodeId;
        let newAnnotation;
        const params = { metaType: annotationMetaType };
        if (props.annotation_type.toLowerCase() === 'location') {
            // locations
            params.metaType = 'location';
            Reflect.deleteProperty(props, 'annotation_type'); // no annotations type for locations
            newAnnotation = this.props.addNewElement('location', parentId, props);
        } else if (props.annotation_type.toLowerCase() === 'area') {
            // locations
            params.metaType = 'measurementannotation';
            Reflect.deleteProperty(props, 'annotation_type'); // no annotations type for locations
            props.no = getMaxFieldValue(this.props.annotations, 'no') + 1;
            newAnnotation = this.props.addNewElement('measurementannotation', parentId, props);
        } else {
            // annotations
            const newNo = this.props.annotations.length + 1;
            props[this.getNoFieldName()] = newNo;
            newAnnotation = this.props.addNewElement(annotationMetaType, parentId, props);
            params.no = newNo;
        }
        params.pk = getId(newAnnotation);
        this._addUndoAction({ type: 'delete', params });
    };


    _deleteAnnotation = (deletedAnnotation) => {
        const annotation = this.props.annotations[deletedAnnotation.rowId];
        const pk = getId(annotation);
        const sequence = getValue(annotation, this.getNoFieldName());
        const typeName = getMetaTypeName(this.props.annotations[deletedAnnotation.rowId], this.props.dataStructure);
        this.props.deleteElement(typeName, pk);
        const fields = {};
        annotation.entity.record.forEach(record => {
            fields[record['name']] = record['value'];
        });
        const parentId = fields.parentId;
        Reflect.deleteProperty(fields, 'parentId');
        this._addUndoAction({ type: 'create', params: { metaType: typeName, pk, parentId, fields } });
        if (typeName !== 'location') this.renumberAnnotationsAfterDelete(sequence);
    };

    getNoFieldName = () => {
        return this._isRenderAnnotationsAsCircles() ? 'sequence' : 'no';
    };

    getAnnotationMetaTypeName = annotation => {
        return getMetaTypeName(annotation, this.props.dataStructure);
    };

    renumberAnnotationsAfterDelete = deletedNumber => {
        const noFieldName = this.getNoFieldName();
        this.props.annotations.forEach(annotation => {
            const number = getValue(annotation, noFieldName);
            if (number > deletedNumber) {
                const typeName = this.getAnnotationMetaTypeName(annotation);
                const pk = getId(annotation);
                const newColumns = {
                    [noFieldName]: number - 1
                };
                this.props.updateElement(typeName, pk, newColumns);
            }
        });
    };

    renumberAnnotationsAfterRestore = restoredNo => {
        const noFieldName = this.getNoFieldName();
        this.props.annotations.forEach(annotation => {
            const number = getValue(annotation, noFieldName);
            if (number >= restoredNo) {
                const typeName = this.getAnnotationMetaTypeName(annotation);
                const pk = getId(annotation);
                const newColumns = {
                    [noFieldName]: number + 1
                };
                this.props.updateElement(typeName, pk, newColumns);
            }
        });
    };

    _editAnnotation = (editedAnnotationRow) => {
        const annotation = this.props.annotations[editedAnnotationRow.id];
        const pk = getId(annotation);
        const typeName = this.getAnnotationMetaTypeName(this.props.annotations[editedAnnotationRow.id]);
        const newColumns = {};
        const rowColumns = editedAnnotationRow.columns;
        // if (typeName === 'location') rowColumns.shift();
        rowColumns.forEach((column, index) => {
            const columnName = this._tableEditHeaderColumns()[index].value;
            if (columnName !== 'index') newColumns[columnName] = column.value;
        });
        if (this.props.nodeType === 'measurementimage') {
            Reflect.deleteProperty(newColumns, 'no');
            Reflect.deleteProperty(newColumns, 'annotation_type');
        }
        this.props.updateElement(typeName, pk, newColumns);
        // saving for undo
        const fields = {};
        annotation.entity.record.forEach(record => {
            fields[record['name']] = record['value'];
        });
        Reflect.deleteProperty(fields, 'id');
        Reflect.deleteProperty(fields, 'parentId');
        this._addUndoAction({ type: 'update', params: { metaType: typeName, pk, fields } });
    };

    _getAnnotation = pk => {
        return this.props.annotations.find(annotation => getId(annotation) === pk);
    };

    _moveAnnotation = (id, points) => {
        let metaType, fields, oldValues;
        const annotation = this._getAnnotation(id);
        if (this._isRenderAnnotationsAsCircles()) {
            oldValues = {
                x_offset: getValue(annotation, 'x_offset'),
                y_offset: getValue(annotation, 'y_offset')
            };
            metaType = 'location';
            fields = { x_offset: points.x, y_offset: points.y };
        } else if (this.props.nodeType === 'measurementimage') {
            oldValues = {
                svg: getValue(annotation, 'svg')
            };
            metaType = 'measurementannotation';
            fields = { svg: points };
        } else {
            oldValues = {
                svg: getValue(annotation, 'svg')
            };
            metaType = 'annotation';
            fields = { svg: points };
        }
        this.props.updateElement(metaType, id, fields);
        this._addUndoAction({ type: 'update', params: { metaType, pk: id, fields: oldValues } });
    };

    _getAnnotationsForTableEdit = () => this.getAnnotations().map(
        annotation => {
            const rowColumns = [];
            // if (this._isRenderAnnotationsAsCircles()) rowColumns.push({ value: index + 1 });
            rowColumns.push(...this._tableEditHeaderColumns()
                .map(column => ({
                    value: getValue(annotation, column.value)
                }))
            );
            return { columns: rowColumns };
        });


    componentDidCatch (error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log(error, info);
    }

    getAnnotations = () => {
        const noField = this.getNoFieldName();
        return this.props.annotations
            .sort((annotation1, annotation2) => {
                const no1 = getValue(annotation1, noField);
                const no2 = getValue(annotation2, noField);
                if (no1 < no2) return -1;
                if (no1 > no2) return 1;
                return 0;
            })
            .map(annotation =>
                this._getAnnotationStyle(annotation));
    };

    rotateImage = (degrees = 90, isClockwise = true, enableURI = true) => {
        const canvas = document.createElement("canvas");
        canvas.setAttribute("id", "hidden-canvas");
        canvas.style.display = "none";
        document.body.appendChild(canvas);
        const ctx = canvas.getContext('2d');
        const filename = this._getImageFileName(this.props.image);

        // create Image
        let img = new Image();
        img.src = this.state.image;


        img.onload = () => {
            const w = img.width;
            const h = img.height;
            const rads = degrees * Math.PI / 180;
            let c = Math.cos(rads);
            let s = Math.sin(rads);
            if (s < 0) {
                s = -s;
            }
            if (c < 0) {
                c = -c;
            }
            //use translated width and height for new canvas
            canvas.width = h * s + w * c;
            canvas.height = h * c + w * s;
            //draw the rect in the center of the newly sized canvas
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(degrees * Math.PI / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            //assume plain base64 if not provided
            const image = (enableURI ? canvas.toDataURL() : canvas.toDataURL().split(",")[1]);
            this.setState({ image });
            addUploadTask({ data: image, filename });
            const customStore = new Store('inspectIt', 'images');
            set(filename, image, customStore).then(() => {
                console.log('Image was saved to the idb');
            });
            document.body.removeChild(canvas);
        };
    };

    render () {
        const tableEditRows = this._getAnnotationsForTableEdit();
        let rightLegend = null;
        let topLegend = null;
        let bottomLegend = null;
        let annotationTypes;
        if (this.props.annotationTypes && this.props.annotationTypes.length) {
            annotationTypes = this.props.annotationTypes.map(type => (
                <LegendItem
                    key={type}
                    label={type}
                    sampleStyles={this.getAnnotationTypeStyle(type)}
                />
            ));
        } else if (this.props.nodeType === 'observation') {
            annotationTypes = (
                <div>
                    No annotation types for selected material OR material is not selected
                </div>
            );
        } else {
            annotationTypes = (
                <div>
                    No annotation types available
                </div>
            );
        }
        if (this._isRenderAnnotationsAsCircles()) {
            topLegend = (
                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    <div style={{
                        width: 300,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #111',
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginBottom: 10
                    }}>
                        <PinLegendItem
                            number={this.props.nextNumber}
                            style={{ margin: 10, height: 60, width: 60 }}
                        />
                        <div>Drag location pin onto map/plan</div>
                    </div>
                </div>
            );
        } else if (this.props.nodeType === 'measurementimage') {
            bottomLegend = (
                <div className={'measurementAnnotationLegendDragArea'}>
                    <MeasurementAnnotationLegendItem style={this.getAnnotationTypeStyle('area')}/>
                </div>
            );
        } else {
            rightLegend = (
                <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: 10 }}>
                    <h3>Annotation Key</h3>
                    {annotationTypes}
                </Paper>
            );
        }
        const marginRight = this._isRenderAnnotationsAsCircles() ? 0 : 10;
        const tableEdit = this.props.annotations.length ? (
            <Paper style={{ marginTop: 5 }}>
                <TableEdit
                    rows={tableEditRows}
                    headerColumns={this._tableEditHeaderColumns()}
                    importAllowed={false}
                    enableAdd={false}
                    enableDelete
                    onDelete={this._deleteAnnotation}
                    onChange={this._editAnnotation}
                    headerRowStyle={{ background: 'grey', padding: '0 12px 0 12px' }}
                    headerCellStyle={{ background: 'grey', justifyContent: 'flex-start', alignItems: 'center' }}
                    rowStyle={{ padding: '0 12px 0 12px' }}
                />
            </Paper>
        ) : null;

        const defs = [
            <pattern id="hr-red" key="hr-red" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M0 5 L10 5" stroke="red"/>
            </pattern>,
            <pattern id="vr-blue" key="vr-blue" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M5 0 L5 10" stroke="blue"/>
            </pattern>,
            <pattern
                id="diagonal-stripe-1-green" key="diagonal-stripe-1-green" patternUnits="userSpaceOnUse" width="10"
                height="10">
                <path d="M0 0 L10 10" stroke="green"/>
            </pattern>,
            <pattern
                id="diagonal-stripe-2-yellow" key="diagonal-stripe-2-yellow" patternUnits="userSpaceOnUse"
                width="10" height="10">
                <path d="M0 10 L10 0" stroke="yellow"/>
            </pattern>,
            <pattern id="hr-blue" key="hr-blue" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M0 5 L10 5" stroke="blue"/>
            </pattern>,
            <pattern id="vr-green" key="vr-green" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M5 0 L5 10" stroke="green"/>
            </pattern>,
            <pattern
                id="diagonal-stripe-1-yellow" key="diagonal-stripe-1-yellow" patternUnits="userSpaceOnUse"
                width="10" height="10">
                <path d="M0 0 L10 10" stroke="yellow"/>
            </pattern>,
            <pattern
                id="diagonal-stripe-2-red" key="diagonal-stripe-2-red" patternUnits="userSpaceOnUse" width="10"
                height="10">
                <path d="M0 10 L10 0" stroke="red"/>
            </pattern>,
            <pattern id="hr-green" key="hr-green" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M0 5 L10 5" stroke="green"/>
            </pattern>,
            <pattern id="vr-yellow" key="vr-yellow" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M5 0 L5 10" stroke="yellow"/>
            </pattern>,
            <pattern
                id="diagonal-stripe-1-red" key="diagonal-stripe-1-red" patternUnits="userSpaceOnUse" width="10"
                height="10">
                <path d="M0 0 L10 10" stroke="red"/>
            </pattern>,
            <pattern
                id="diagonal-stripe-2-blue" key="diagonal-stripe-2-blue" patternUnits="userSpaceOnUse" width="10"
                height="10">
                <path d="M0 10 L10 0" stroke="blue"/>
            </pattern>,
            <pattern id="hr-yellow" key="hr-yellow" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M0 5 L10 5" stroke="yellow"/>
            </pattern>,
            <pattern id="vr-red" key="vr-red" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M5 0 L5 10" stroke="red"/>
            </pattern>,
            <pattern
                id="diagonal-stripe-1-blue" key="diagonal-stripe-1-blue" patternUnits="userSpaceOnUse" width="10"
                height="10">
                <path d="M0 0 L10 10" stroke="blue"/>
            </pattern>,
            <pattern
                id="diagonal-stripe-2-green" key="diagonal-stripe-2-green" patternUnits="userSpaceOnUse" width="10"
                height="10">
                <path d="M0 10 L10 0" stroke="green"/>
            </pattern>
        ];

        return this.state.image && this.props.imageUnfolded ? (
            <ErrorBoundary>
                <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        {defs}
                    </defs>
                </svg>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    {topLegend}
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                        <Paper style={{ display: 'flex', flex: 3, flexDirection: 'column', marginRight }}>
                            <AnnotatedImage
                                image={this.state.image}
                                annotations={this.getAnnotations()}
                                annotationTypes={this.getAnnotationTypes()}
                                showSVGs={this.state.annotationsVisible && this.props.imageUnfolded}
                                addCallback={this._addAnnotation}
                                moveCallback={this._moveAnnotation}
                                locked={this.state.locked}
                                isCircle={this._isRenderAnnotationsAsCircles()}
                                imageFileName={this._getImageFileName(this.props.image)}
                                defs={defs}
                                ref={ref => {
                                    this.annotatedImage = ref;
                                }}
                            />
                        </Paper>
                        {rightLegend}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={() => {
                                    if (isObject(this.props.image) &&
                                        getValue(this.props.image, 'locked') !== undefined) {
                                        this.props.updateElement(getMetaTypeName(this.props.image),
                                            getId(this.props.image),
                                            { locked: this.state.locked ? 'false' : 'true' });
                                    } else if (visualAssets.includes(this.props.nodeType)) {
                                        const visualAsset = getObjectById(this.props.nodeId);
                                        this.props.updateElement(getMetaTypeName(visualAsset), getId(visualAsset),
                                            { locked: this.state.locked ? 'false' : 'true' });
                                    } else {
                                        this.setState({ locked: !this.state.locked });
                                    }
                                }}
                            >
                                {this.state.locked ? <ActionLock/> : <ActionLockOpen/>}
                                {this.state.locked ? 'Unlock' : 'Lock'}
                            </Button>
                            <Button
                                style={{ height: 41 }}
                                variant="contained"
                                size="medium"
                                onClick={() => this.setState({ annotationsVisible: !this.state.annotationsVisible })}
                            >
                                {this.state.annotationsVisible ? 'Hide annotations' : 'Show annotations'}
                            </Button>
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={this._undo}
                                disabled={!this.state.undoActions.length}
                                style={{ height: 42 }}
                            >
                                Undo
                            </Button>
                            <Button
                                variant={"contained"}
                                size={"medium"}
                                onClick={() => {
                                    this.rotateImage();
                                }}
                                style={{ height: 42 }}
                                disabled={(!!this.props.annotations && !!this.props.annotations.length) ||
                                this._getImageFileName(this.props.image) === 'blank'}
                            >
                                Rotate image
                            </Button>
                        </div>
                        {bottomLegend}
                    </div>
                    {tableEdit}
                </div>
            </ErrorBoundary>
        ) : null;
    }
}

AnnotatedObject.propTypes = {
    nodeType: PropTypes.string,
    annotationTypes: PropTypes.array,
    image: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
    annotations: PropTypes.array,
    nodeId: PropTypes.string.isRequired,
    dataStructure: PropTypes.array.isRequired,
    imageUnfolded: PropTypes.bool,
    nextNumber: PropTypes.string,
    locked: PropTypes.bool,
    // actions from parent
    addNewElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired
};
AnnotatedObject.defaultProps = {
    nodeType: null,
    annotationTypes: [],
    annotations: [],
    imageUnfolded: true
};

export default AnnotatedObject;
