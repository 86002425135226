import React from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';

const defaultStyle = currentOffset => (
    {
        left: currentOffset.x,
        top: currentOffset.y,
        position: 'fixed',
        zIndex: 100,
        pointerEvents: 'none'
    }
);

class DragPreview extends React.Component {
    render () {
        const {
            isDragging,
            currentOffset,
            itemType
        } = this.props;
        return !isDragging || !currentOffset || !itemType ||
        ['AnnotationPath', 'AnnotationPathNode'].includes(itemType) ?
            null :
            <div style={defaultStyle(currentOffset)}>
                <div className="addItem">{itemType}</div>
            </div>;
    }
}

DragPreview.propTypes = {
    isDragging: PropTypes.bool.isRequired,
    currentOffset: PropTypes.shape({ x: PropTypes.number.isRequired, y: PropTypes.number.isRequired }),
    itemType: PropTypes.string
};

const collect = (monitor) => (
    {
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging()
    }
);


// eslint-disable-next-line new-cap
export default DragLayer(collect)(DragPreview);
