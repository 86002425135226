import { ELEMENT_VIEW_SELECTED } from '../reducers/view';

export const viewElement = (elementType, elementPrimaryKey) => {
    return dispatch => {
        dispatch({
            type: ELEMENT_VIEW_SELECTED,
            payload: {
                elementType,
                elementPrimaryKey
                // elementChangeCallback
            }
        });
    };
};

export default viewElement;
